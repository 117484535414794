import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
  Card,
  Button,
  Form,
  InputGroup,
  Modal,
  Image,
  Col,
  Row,
  Alert,
  ButtonGroup
} from 'react-bootstrap'
import Select from 'react-select'
import {
  generalSkillOptions,
  professionalSkillOptions
} from '../../features/constants/skillsData'
import { Navigate, useLocation, useNavigate } from 'react-router'
// import userPhoto from '../images/user-profile.webp';
import logoPhoto from '../images/Favicon_large-removebg-preview.png'
import userPhoto from '../images/profile-placeholder.jpeg'
import {
  GetAllApplicationsOfDrive,
  GetCandidateByID,
  UpdateUserProfile,
  deleteCandidateEducationRecord,
  deleteCandidateExperienceRecord,
  fetchAllJobRoles,
  fetchAllJobRolesSkills,
  getRemovedSkills,
  postUserImage,
  vspVerification,
  UpdateUserVspStatus,
  shortlistApplicant,
  handleSetImage
} from '../../features/constants/apis'
import AddNewEducation from './Modals/AddNewEducation'
import { FaArrowLeft, FaPen, FaPlus } from 'react-icons/fa'
import AddNewExperience from './Modals/AddNewExperience'
import axios from 'axios'
import { RiDeleteBin6Line } from 'react-icons/ri'
import UpdateEducationModal from './Modals/UpdateEducationModal'
import UpdateExperienceModal from './Modals/UpdateExperienceModal'
import { FaUpload } from 'react-icons/fa'
import FileUploadWithProgress from './FileUploadWithProgress'
import tick from '../images/tick.png'
import tickOutlined from '../images/tick-outlined.png'
import gayTick from '../images/icon/tick-image-gray.png'
import { Link, useSearchParams } from 'react-router-dom'
import GetVspModal from './Modals/GetVspModal'
import { Input } from '@mui/material'

const CandidateProfileForHr = ({ fullName, role, authToken, ID }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  // console.log(role)
  // const searchParams = new URLSearchParams(location.search);
  const candidateID = searchParams.get('candidateID')
  const vspStatus = searchParams.get('status')
  // console.log("userID:", candidateID)
  // console.log("VSP STATUS:", vspStatus)

  const fileInputRef = useRef(null)
  const [fileUploaded, setFileUploaded] = useState(false)
  const [editableUserFullName, setEditableUserFullName] = useState('')
  const [editableUserDomain, setEditableUserDomain] = useState('')
  const [editableGenSkills, setEditableGenSkills] = useState([])
  const [editableProSkills, setEditableProSkills] = useState([])
  const [editableDescription, setEditableDescription] = useState('')
  const [cvUploaded, setCvUploaded] = useState(false) // State variable to track CV upload
  const [generalSkillsList, setGeneralSkillsList] = useState([])
  const [proSkillsList, setProSkillsList] = useState([])
  const [openToSwitch, setOpenToSwitch] = useState(Boolean)
  const [expectedCTC, setExpectedCTC] = useState('')
  const [currentCTC, setCurrentCTC] = useState('')
  const [openToJoin, setOpenToJoin] = useState(Boolean)
  const [profileProgressCount, setProfileProgressCount] = useState(0)
  const [userEduction, setUserEducation] = useState([])
  const [userExperience, setUserExperience] = useState([])

  const [alertMessage, setAlertMessage] = useState(null)
  const [validated, setValidated] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)
  const [userInfo, setUserInfo] = useState([])
  const [userDomain, setUserDomain] = useState('')
  const [jobRoles, setJobRoles] = useState([])
  const [isEditingProfile, setIsEditingProfile] = useState(false)
  const [comSkill, setComSkill] = useState()
  const [language, setLanguage] = useState([])
  const [personality, setPersonality] = useState()
  const [workType, setWorkType] = useState()
  const [jobType, setJobType] = useState([])
  const [jobLocation, setJobLocation] = useState()
  const [ctc, setCtc] = useState()
  const [hasOwn, setHasOwn] = useState([])
  const [availability, setAvailability] = useState(null)
  const [otherRemarks, setOtherRemarks] = useState()
  const [inputValue, setInputValue] = useState()
  const [genSkillByHr, setGenSkillByHr] = useState([])
  const [proSkillByHr, setProSkillByHr] = useState([])

  ///////////////////////////////////////skill check uncheck logic////////////////////////////////////////////////////
  const [selectedGeneralSkills, setSelectedGeneralSkills] = useState([])
  const [selectedProfessionalSkills, setSelectedProfessionalSkills] = useState(
    []
  )

  // This function will be called when the CV is uploaded successfully
  const handleCVUploadSuccess = () => {
    setCvUploaded(prev => !prev)
  }

  const EditUserProfile = async (userData, actionType) => {
    const updateProfile = await UpdateUserProfile(ID, userData, authToken)
    if (updateProfile.status == '200' && actionType === 'doLater') {
      navigate('/candidate-dashboard')
    }
    const data = await GetCandidateByID(ID, authToken)
    if (role === 'Candidate') {
      setUserInfo(data.data)
      setUserEducation(data.data?.Education)
      setUserExperience(data.data?.Experience)
    }
  }

  // Edit user profile
  const handleProfileEdit = loadType => {
    setEditableUserFullName(userInfo?.FullName || '')
    setEditableUserDomain(userInfo?.CareerProfile?.domain || '')
    setEditableDescription(userInfo?.Description || '')

    const defaultGenSkills = generalSkillsList?.filter(option =>
      userInfo?.SkillProfile?.GeneralSkills.includes(option.value)
    )
    setEditableGenSkills(defaultGenSkills)

    const defaultProSkills = proSkillsList?.filter(option =>
      userInfo?.SkillProfile?.ProfessionalSkills.includes(option.value)
    )
    setEditableProSkills(defaultProSkills)

    if (loadType !== 'firstLoad') {
      if (role === 'Candidate') {
        setIsEditingProfile(true)
      }
    }
  }

  // Toggle skill selection
  const handleSkillToggle = (skill, category) => {
    console.log('prev states: ', skill)

    if (category === 'general') {
      setSelectedGeneralSkills(prev =>
        prev?.includes(skill)
          ? prev?.filter(item => item !== skill)
          : [...prev, skill]
      )

      setGenSkillByHr((prev = []) =>
        prev?.includes(skill)
          ? prev.filter(item => item !== skill)
          : [...prev, skill]
      )
    } else if (category === 'professional') {
      setSelectedProfessionalSkills(prev =>
        prev?.includes(skill)
          ? prev.filter(item => item !== skill)
          : [...prev, skill]
      )
      setProSkillByHr((prev = []) =>
        prev?.includes(skill)
          ? prev?.filter(item => item !== skill)
          : [...prev, skill]
      )
    }
  }
  console.log('skilsssssss: ', genSkillByHr)

  useEffect(() => {
    console.log('old general skills: ', selectedGeneralSkills)
    console.log('hr general skills: ', genSkillByHr)
    console.log('old professional skills: ', selectedProfessionalSkills)
    console.log('hr professional skills: ', proSkillByHr)
  }, [genSkillByHr, genSkillByHr, selectedProfessionalSkills, proSkillByHr])

  // console.log("SLECTED GENERAL SKILLS: ", selectedGeneralSkills);
  // console.log("SLECTED PROFESSIONAL SKILLS: ", selectedProfessionalSkills);

  // // Send selected skills to backend
  // const sendSelectedSkills = () => {
  //     const data = {
  //         generalSkills: selectedGeneralSkills,
  //         professionalSkills: selectedProfessionalSkills
  //     };
  //     onSendSelectedSkills(data); // Call parent-provided function to handle the API
  // };

  ///////////////////////////////////////skill check uncheck logic end////////////////////////////////////////////////////

  const handleImageChange = async e => {
    const file = e.target.files[0]
    if (file) {
      setPreviewImage(URL.createObjectURL(file))
      try {
        // const imageUrl = await uploadRefImage(file); // Wait for the image URL
        // await handleSetImage(imageUrl); // Call handleSetImage with the image URL
      } catch (error) {
        console.error('Error handling image change:', error)
      }
    }
  }

  useEffect(() => {
    const UserData = async () => {
      const data = await GetCandidateByID(
        candidateID ? candidateID : ID,
        authToken
      )
      // if (role === 'Candidate') {
      console.log('use id finder: ', data?.data)
      setUserInfo(data.data)
      console.log(
        'infoooooooooooooooooooooooooooooo: ',
        data.data.HrComments?.language
      )
      setAvailability({
        value: data.data.HrComments?.Availability,
        label: data.data.HrComments?.Availability
      })
      setComSkill({
        value: data.data.HrComments?.ComSkills,
        label: data.data.HrComments?.ComSkills
      })
      setPersonality({
        value: data.data.HrComments?.personality,
        label: data.data.HrComments?.personality
      })
      // setJobType({ value: data.data.HrComments.jobType, label: data.data.HrComments.jobType })
      setJobLocation({
        value: data.data.HrComments?.jobLocation,
        label: data.data.HrComments?.jobLocation
      })
      setCtc(data.data.HrComments?.ctc)
      setOtherRemarks(data.data.HrComments?.otherRemarks)
      console.log('job type', data.data.HrComments?.jobType)

      const jobType = data.data.HrComments?.jobType
      console.log('jobType: ', jobType)

      setJobType(jobType || null)

      // const mappedLanguages = data.data?.HrComments?.language?.map((lang) => ({
      //     value: lang,
      //     label: lang,
      // }));
      // setLanguage(mappedLanguages);
      const language = data.data?.HrComments?.language
      console.log(language)

      setLanguage(language)

      const mappedWorkType = data.data?.HrComments?.workType
      console.log('mappedWorkType', mappedWorkType)
      setWorkType(mappedWorkType)

      const hasOwn = data.data?.HrComments?.hasOwn
      console.log('hasOwn:', hasOwn)
      setHasOwn(hasOwn)

      console.log(data.data.HrComments?.hasOwn)
      setGenSkillByHr(data.data?.HrComments?.generalSkillsByHr)
      setProSkillByHr(data.data?.HrComments?.professionalSkillsByHr)
    }
    UserData()
  }, [ID, authToken, role, candidateID])

  useEffect(() => {
    console.log(genSkillByHr)
    console.log(proSkillByHr)
  }, [jobType, workType, genSkillByHr, proSkillByHr, selectedGeneralSkills])

  useEffect(() => {
    const fetchJobRoles = async () => {
      const data = await fetchAllJobRoles(authToken)
      setJobRoles(data.data)
      // console.log(data.data)
    }
    fetchJobRoles()
  }, [authToken])

  useEffect(() => {
    if (userInfo.CareerProfile && jobRoles.length > 0) {
      const domainValue = userInfo.CareerProfile.domain
      // console.log(domainValue);
      // console.log(jobRoles);
      const matchingJobRoles = jobRoles.find(
        jobRole => jobRole.ID == domainValue
      )
      // setUserDomain(matchingJobRoles.Title)
    }
  }, [userInfo.CareerProfile, jobRoles])

  // skills from job roles
  const [jobRolesSkills, setJobRolesSkills] = useState({
    GeneralSkills: [],
    ProfessionalSkills: []
  })

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAllJobRolesSkills(authToken)
      setJobRolesSkills(data.data)
      // console.log(authToken)
      // console.log(data?.data)
    }
    fetchData()
  }, [authToken])

  const generalSkillOptions = jobRolesSkills?.GeneralSkills.map(skill => ({
    value: skill,
    label: skill
  }))

  const professionalSkillOptions = jobRolesSkills?.ProfessionalSkills.map(
    skill => ({
      value: skill,
      label: skill
    })
  )

  const handleSaveEditedProfile = actionType => {
    let status = ''
    if (openToJoin === true) {
      status = 'join'
    } else if (openToSwitch === true) {
      status = 'switch'
    } else {
      status = 'null'
    }

    const editedUserInfo = {
      name: editableUserFullName,
      domain: editableUserDomain,
      generalSkills: editableGenSkills.map(skill => skill.value),
      professionalSkills: editableProSkills.map(skill => skill.value),
      description: editableDescription,
      JoiningStatus: status,
      CareerProfile: {
        degree: userInfo?.CareerProfile?.degree,
        domain: userInfo.CareerProfile?.domain,
        education: userInfo.CareerProfile?.education,
        experience: userInfo.CareerProfile?.experience,
        incomeCTC: currentCTC || userInfo.CareerProfile?.incomeCTC || null,
        expectedCtc: expectedCTC
      },
      ProfileProgress: profileProgressCount
    }

    if (actionType === 'doLater') {
      EditUserProfile(editedUserInfo, actionType)
    } else {
      EditUserProfile(editedUserInfo)
    }

    setIsEditingProfile(false)
  }
  // const navigate = useNavigate();
  // const handleLater = () => {
  //     navigate('/candidate-dashboard')+
  // }

  const handleLater = event => {
    const form = event.currentTarget
    if (openToSwitch) {
      if (!expectedCTC) {
        setValidated(true)
        return
      }
      if (!currentCTC) {
        setValidated(true)
        return
      } else {
        setValidated(false)
      }
    }
    if (openToJoin || openToSwitch) {
      if (!expectedCTC) {
        setValidated(true)
        return
      }
      if (!expectedCTC) {
        setValidated(true)
        return
      }
    }
    setValidated(false)

    handleSaveEditedProfile('doLater')
  }

  const submitForm = async e => {
    let message = ''
    e.preventDefault()

    if (!comSkill || comSkill.length === 0) {
      message = 'Please select a Communication Skill.'
    } else if (!language || language.length === 0) {
      message = 'Please select at least one Language of Comfort.'
    } else if (!personality || personality.length === 0) {
      message = 'Please select a Personality type.'
    } else if (!workType || workType.length === 0) {
      message = 'Please select at least one Work Type Suitability.'
    } else if (!jobType || jobType.length === 0) {
      message = 'Please select at least one Job Type Suitability.'
    } else if (!jobLocation || jobLocation.length === 0) {
      message = 'Please select a Job Location Preference.'
    } else if (!ctc) {
      message = 'Please enter the Expected CTC.'
    } else if (!hasOwn || hasOwn.length === 0) {
      message = "Please select at least one option for 'Has Own'."
    } else if (!availability || availability.length === 0) {
      message = 'Please select an Availability to Join option.'
    }

    if (message) {
      setAlertMessage(message)
      setValidated(false)
    }

    setAlertMessage(null)
    setValidated(true)
    // Handle the form submission here
    const hrComments = {
      ComSkills: comSkill?.value,
      language: language,
      personality: personality?.value,
      workType: workType,
      jobType: jobType,
      jobLocation: jobLocation?.value,
      ctc: ctc,
      hasOwn: hasOwn,
      Availability: availability?.value,
      otherRemarks: otherRemarks,
      generalSkillsByHr: genSkillByHr,
      professionalSkillsByHr: proSkillByHr
    }
    try {
      console.log(hrComments)
      await vspVerification(candidateID, hrComments, authToken)
      await UpdateUserVspStatus(authToken, candidateID) // Call the API to update the status
    } catch (error) {
      console.error('Error during job submission:', error)
    } finally {
      navigate('/vsp')
    }
  }

  const handleSelectedComkill = selectedOptions => {
    const comSkill = selectedOptions
    setComSkill(comSkill)
  }
  const handleSelectedLang = selectedOptions => {
    setLanguage(selectedOptions)
  }
  const handlePersonality = selectedOptions => {
    const personality = selectedOptions
    setPersonality(personality)
  }
  const handleWorkType = selectedOptions => {
    const workType = selectedOptions
    setWorkType(workType)
  }
  const handleJobType = selectedOptions => {
    const jobType = selectedOptions
    setJobType(jobType)
  }
  const handleJobLocation = selectedOptions => {
    const location = selectedOptions
    setJobLocation(location)
  }
  const handlehasOwn = selectedOptions => {
    const hasOwn = selectedOptions
    setHasOwn(hasOwn)
  }
  const handleJoin = selectedOptions => {
    const availability = selectedOptions
    setAvailability(availability)
  }

  return (
    <div id="appCapsule">
      <div className="container candidate-profile">
        {(role === 'Employer' || role === 'Admin') && (
          <Link to="/payments" type="submit" className="my-2 text-white">
            <FaArrowLeft
              style={{ width: '15px', height: '15px', marginRight: '7px' }}
            />
            <Button variant="primary mb-2">Go to Payments</Button>
          </Link>
        )}
        <div className="section mt-2">
          <div className="profile-head">
            <div className="avatar d-flex flex-column align-items-center gap-1">
              <img
                src={previewImage || userInfo.Image || userPhoto}
                alt="avatar"
                className="imaged w64 rounded profile-pic"
              />
              {role === 'Candidate' && (
                <>
                  <Form.Control
                    id="fileUpload"
                    type="file"
                    accept="image/*"
                    custom
                    label="Choose Image"
                    onChange={handleImageChange}
                    className="btn btn-outline-primary btn-sm cursor-pointer "
                    style={{
                      padding: '0.2rem 0.2rem',
                      lineHeight: '1.5',
                      display: 'none'
                    }}
                    ref={fileInputRef}
                  />
                  {fileUploaded ? (
                    <span className="text-nowrap">uploading...</span>
                  ) : (
                    <label
                      htmlFor="fileUpload"
                      className="cursor-pointer profile-image-upload"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Choose Image
                    </label>
                  )}
                </>
              )}
            </div>

            <div className="in w-100">
              {isEditingProfile ? (
                <>
                  <div className="d-flex name-title justify-content-between">
                    <Form.Group className="w-50">
                      <Form.Control
                        required
                        type="text"
                        onChange={e => setEditableUserFullName(e.target.value)}
                        value={editableUserFullName}
                        placeholder="Enter Your Full Name"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ fontSize: '.8rem' }}
                      >
                        Please provide a valid Title.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="head-buttons d-flex gap-2">
                      <Button
                        className=""
                        variant="primary"
                        onClick="{hadnleSaveEditedProfile}"
                      >
                        Save
                      </Button>
                      <Button
                        className=""
                        variant="outline-primary"
                        onClick="{handleProfileCancelEdit}"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                  <Card.Text className="d-flex align-items-center py-2">
                    <InputGroup.Text
                      id="inputGroup-sizing-default"
                      style={{ height: '37px' }}
                    >
                      <ion-icon
                        name="briefcase-outline"
                        style={{ color: '#45a994' }}
                      ></ion-icon>
                      {/* <IoLocationOutline style={{color: '#45a994'}}/> */}
                    </InputGroup.Text>
                    <Form.Select
                      className="w-50"
                      aria-label="Default select example"
                      onChange={e => setEditableUserDomain(e.target.value)}
                      value={editableUserDomain}
                    >
                      <option selected disabled value={userDomain}>
                        {userDomain}
                      </option>
                      {jobRoles.map((role, idx) => (
                        <option key={idx} value={role.ID}>
                          {role.Title}
                        </option>
                      ))}
                    </Form.Select>
                  </Card.Text>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="user-name">
                      <h3 className="name">{userInfo?.FullName}</h3>
                      {/* <h5 className="subtext">Male</h5> */}
                    </div>
                    {/* <span className="badge bg-primary ms-1 text-white">Edit</span> */}
                    {role === 'Candidate' && (
                      <div>
                        <Button className="me-2" onClick={handleProfileEdit}>
                          Edit Profile
                        </Button>
                        <Button
                          variant="outline-primary"
                          className="do-this-later"
                          onClick={handleLater}
                        >
                          I'll do this later
                        </Button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* User Statistics Start | Display None */}
        <div className="section full mt-2 d-none">
          <div className="row">
            <div className="col-lg-3 col-6 mb-2">
              <div className="card">
                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                  <div className="db-bg-icon order-sm-1">
                    <ion-icon size="large" name="person-outline"></ion-icon>
                  </div>
                  <div className="order-sm-0">
                    <h5 className="card-title">1.7k+</h5>
                    <h6 className="card-subtitle">Total Visitors</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6 mb-2">
              <div className="card">
                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                  <div className="db-bg-icon order-sm-1">
                    <ion-icon size="large" name="bookmarks-outline"></ion-icon>
                  </div>
                  <div className="order-sm-0">
                    <h5 className="card-title">03</h5>
                    <h6 className="card-subtitle">Shortlisted</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6 mb-2">
              <div className="card">
                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                  <div className="db-bg-icon order-sm-1">
                    <ion-icon size="large" name="eye-outline"></ion-icon>
                  </div>
                  <div className="order-sm-0">
                    <h5 className="card-title">2.1k</h5>
                    <h6 className="card-subtitle">Views</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6 mb-2">
              <div className="card">
                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                  <div className="db-bg-icon order-sm-1">
                    <ion-icon
                      size="large"
                      name="checkmark-done-outline"
                    ></ion-icon>
                  </div>
                  <div className="order-sm-0">
                    <h5 className="card-title">07</h5>
                    <h6 className="card-subtitle">Applied Jobs</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* User Statistics End */}

        <div className="section mt-1 mb-2">
          <div className="profile-info">
            <div className=" bio">
              {isEditingProfile ? (
                <>
                  <Form.Group className="my-3">
                    <Form.Label>General Skills</Form.Label>
                    <Select
                      required
                      onChange={selectedOptions =>
                        setEditableGenSkills(selectedOptions)
                      }
                      // defaultValue={defaultGeneralSkillOptions}
                      value={editableGenSkills}
                      isMulti
                      name="skills"
                      options={generalSkillOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Form.Group>
                  <Form.Group className="my-3">
                    <Form.Label>Professional Skills</Form.Label>
                    <Select
                      required
                      onChange={selectedOptions =>
                        setEditableProSkills(selectedOptions)
                      }
                      // defaultValue={defaultProfessionalSkillOptions}
                      value={editableProSkills}
                      isMulti
                      name="skills"
                      options={professionalSkillOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Form.Group>
                  <div className="pb-2">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        maxLength={150}
                        onChange={e => setEditableDescription(e.target.value)}
                        value={editableDescription}
                      />
                    </Form.Group>
                  </div>
                </>
              ) : (
                <Card.Text>
                  <div>
                    <div className="d-flex">
                      <h5>Education :</h5>
                      <p className="ms-1">{userInfo.Education?.[0]?.degree}</p>
                    </div>
                  </div>
                  {userInfo.ExperienceLevel === 'Experienced' ? (
                    <>
                      <div>
                        <div className="d-flex">
                          <h5>Experience :</h5>
                          <p className="ms-1">
                            {userInfo.CareerProfile.experience} years
                          </p>
                        </div>
                      </div>

                      <div>
                        <div className="d-flex">
                          <h5>Work Profile : </h5>
                          <p className="ms-1">
                            {userInfo?.Experience?.[0]?.designation}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div>
                    <div className="d-flex">
                      {/* <h5>Internship : </h5><p className='ms-1'>yes / No</p> */}
                    </div>
                  </div>
                  <div>
                    <div className="d-flex">
                      <h5>Domain of Interest : </h5>
                      <p className="ms-1">{userDomain}</p>
                    </div>
                  </div>

                  <div className="pb-2">
                    <h5>General Skills:</h5>
                    <div>
                      {userInfo?.SkillProfile?.GeneralSkills?.length > 0 ? (
                        userInfo?.SkillProfile?.GeneralSkills.map(
                          (skill, index) => (
                            <div
                              key={index}
                              className={`chip bg-transparent text-black ms-05 mb-1 skill-chip ${
                                selectedGeneralSkills?.includes(skill)
                                  ? 'selected'
                                  : ''
                              }`}
                              onClick={() =>
                                handleSkillToggle(skill, 'general')
                              }
                              style={{
                                cursor: 'pointer',
                                border: selectedGeneralSkills?.includes(skill)
                                  ? '2px solid #007bff'
                                  : '1px solid #ccc'
                              }}
                            >
                              {genSkillByHr?.includes(skill) ? (
                                <span className="pe-1">
                                  <img src={tick} alt="selected" />
                                </span>
                              ) : (
                                <span className="pe-1">
                                  <img src={tickOutlined} alt="unselected" />
                                </span>
                              )}
                              {skill}
                            </div>
                          )
                        )
                      ) : (
                        <span className="text-muted">
                          No General Skill Found
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="pb-2">
                    <h5>Professional Skills:</h5>
                    <div>
                      {userInfo?.SkillProfile?.ProfessionalSkills?.length ? (
                        userInfo?.SkillProfile?.ProfessionalSkills.map(
                          (skill, index) => (
                            <div
                              key={index}
                              className={`chip bg-transparent text-black ms-05 mb-1 skill-chip ${
                                selectedProfessionalSkills.includes(skill)
                                  ? 'selected'
                                  : ''
                              }`}
                              onClick={() =>
                                handleSkillToggle(skill, 'professional')
                              }
                              style={{
                                cursor: 'pointer',
                                border: selectedProfessionalSkills.includes(
                                  skill
                                )
                                  ? '2px solid #007bff'
                                  : '1px solid #ccc'
                              }}
                            >
                              {proSkillByHr?.includes(skill) ? (
                                <span className="pe-1">
                                  <img src={tick} alt="avatar" />
                                </span>
                              ) : (
                                <span className="pe-1">
                                  <img src={tickOutlined} alt="avatar" />
                                </span>
                              )}
                              <span className="chip-label">{skill}</span>
                            </div>
                          )
                        )
                      ) : (
                        <span className="text-muted">
                          No Professional Skill Found
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="pb-2">
                    <h5>Suitable job profiles:</h5>
                    <Row>
                      <Col xs={12} md={3}>
                        <ol>
                          <li>Full Stack Developer</li>
                          <li>AI/ML Engineer</li>
                          <li>Data Scientist</li>
                          <li>Cloud Solutions Architect</li>
                          <li>DevOps Engineer</li>
                        </ol>
                      </Col>
                      <Col xs={12} md={3}>
                        <ol start={6}>
                          <li>Cybersecurity Analyst</li>
                          <li>Mobile App Developer</li>
                          <li>Blockchain Developer</li>
                          <li>Software Tester</li>
                          <li>Frontend Developer</li>
                        </ol>
                      </Col>
                    </Row>

                    {/* <div>
                                            {userInfo?.SkillProfile?.ProfessionalSkills ?
                                                userInfo?.SkillProfile?.ProfessionalSkills.map((skill, index) => (
                                                    <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip">
                                                        <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={tick} />{skill}</span>
                                                    </div>
                                                )) : <span className="text-muted">No Professioanl Skill Found</span>
                                            }
                                        </div> */}
                  </div>

                  <div className="pb-2">
                    <div className="description">
                      {role === 'Candidate' && (
                        <>
                          {userInfo?.CV ? (
                            <Button
                              variant="primary"
                              href={userInfo.CV}
                              download="User_CV"
                              target="_blank"
                            >
                              Download CV
                            </Button>
                          ) : (
                            <p>You have not uploaded your CV yet.</p>
                          )}
                        </>
                      )}
                      {(role === 'Admin' || role === 'Employer') && (
                        <>
                          {userInfo?.CV ? (
                            <Button
                              variant="primary"
                              href={userInfo.CV}
                              download="User_CV"
                              target="_blank"
                            >
                              {' '}
                              Download CV{' '}
                            </Button>
                          ) : (
                            <p>No CV found.</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {/* upload CV */}
                  {role === 'Candidate' && (
                    <FileUploadWithProgress
                      onUploadSuccess={handleCVUploadSuccess}
                    />
                  )}
                </Card.Text>
              )}
            </div>
          </div>
        </div>

        {/* education and experience timeline code */}
        <div className="section full">
          <div className="wide-block transparent p-0">
            <ul
              className="nav nav-tabs lined iconed candidateProfile"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#education"
                  role="tab"
                >
                  {/* <i className="fa fa-graduation-cap me-1"></i> */}
                  HR Comments
                </a>
              </li>
            </ul>
          </div>
        </div>
        {alertMessage && (
          <Alert
            variant="danger"
            onClose={() => setAlertMessage(null)}
            dismissible
          >
            {alertMessage}
          </Alert>
        )}
        <div>
          <Form noValidate validated={validated} onSubmit={submitForm}>
            <div className=" mt-2">
              <Row>
                <Form.Group className="my-3 d-flex flex-column flex-md-row">
                  <Col xs={12} md={4}>
                    <Form.Label for="comskill" className="pe-1">
                      Communication Skills:
                    </Form.Label>
                  </Col>
                  <Col xs={12} md={8}>
                    <Select
                      id="comskill"
                      required
                      // onInputChange={(value)=>setInputValue(value)}
                      onChange={handleSelectedComkill}
                      // defaultValue={defaultProfessionalSkillOptions}
                      value={comSkill}
                      options={[
                        { value: 'Good', label: 'Good' },
                        { value: 'Average', label: 'Average' },
                        { value: 'Poor', label: 'Poor' }
                      ]}
                      // className={`basic-multi-select w-100  ${validated && !comSkill ? 'is-invalid' : ''}`}
                      // classNamePrefix="select"
                    ></Select>
                  </Col>
                </Form.Group>
              </Row>
            </div>
            <div className=" mt-2">
              <Row>
                <Form.Group className="my-3 d-flex flex-column flex-md-row">
                  <Col xs={12} md={4}>
                    <Form.Label className="me-1">
                      Language of Comfort:
                    </Form.Label>
                  </Col>
                  <Col xs={12} md={8}>
                    <Select
                      required
                      onChange={handleSelectedLang}
                      // defaultValue={defaultProfessionalSkillOptions}
                      value={language}
                      isMulti
                      name="skills"
                      options={[
                        { value: 'English', label: 'English' },
                        { value: 'Hindi', label: 'Hindi' },
                        { value: 'Native', label: 'Native' }
                      ]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    ></Select>
                  </Col>
                </Form.Group>
              </Row>
            </div>
            <div className=" mt-2">
              <Row>
                <Form.Group className="my-3 d-flex flex-column flex-md-row">
                  <Col xs={12} md={4}>
                    <Form.Label className="me-5">Personality:</Form.Label>
                  </Col>
                  <Col xs={12} md={8}>
                    <Select
                      required
                      onChange={handlePersonality}
                      // defaultValue={defaultProfessionalSkillOptions}
                      value={personality}
                      name="skills"
                      options={[
                        { value: 'Talkative', label: 'Talkative' },
                        { value: 'non-talkative', label: 'non-talkative' }
                      ]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Col>
                </Form.Group>
              </Row>
            </div>
            <div className=" mt-2">
              <Row>
                <Form.Group className="my-3 d-flex flex-column flex-md-row">
                  <Col xs={12} md={4}>
                    <Form.Label className="me-1">
                      Work type Suitability:
                    </Form.Label>
                  </Col>
                  <Col xs={12} md={8}>
                    <Select
                      required
                      onChange={handleWorkType}
                      // defaultValue={defaultProfessionalSkillOptions}
                      value={workType}
                      isMulti
                      name="skills"
                      options={[
                        { value: 'On-field', label: 'On-field' },
                        { value: 'on-desk', label: 'on-desk' },
                        { value: 'on-call', label: 'on-call' }
                      ]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Col>
                </Form.Group>
              </Row>
            </div>
            <div className=" mt-2">
              <Row>
                <Form.Group className="my-3 d-flex flex-column flex-md-row">
                  <Col xs={12} md={4}>
                    <Form.Label className="me-1">
                      Job Type Suitability:
                    </Form.Label>
                  </Col>
                  <Col xs={12} md={8}>
                    <Select
                      required
                      onChange={handleJobType}
                      // defaultValue={defaultProfessionalSkillOptions}
                      value={jobType}
                      isMulti
                      name="skills"
                      options={[
                        {
                          value: 'Sales & Marketing',
                          label: 'Sales & Marketing'
                        },
                        { value: 'Operations', label: 'Operations' },
                        { value: 'Creative', label: 'Creative' },
                        { value: 'Technical', label: 'Technical' },
                        { value: 'Admin', label: 'Admin' }
                      ]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Col>
                </Form.Group>
              </Row>
            </div>
            <div className=" mt-2">
              <Row>
                <Form.Group className="my-3 d-flex flex-column flex-md-row">
                  <Col xs={12} md={4}>
                    <Form.Label className="me-5">
                      Job Location Preference:
                    </Form.Label>
                  </Col>
                  <Col xs={12} md={8}>
                    <Select
                      required
                      onChange={handleJobLocation}
                      // defaultValue={defaultProfessionalSkillOptions}
                      value={jobLocation}
                      name="skills"
                      options={[
                        { value: 'Anywhere', label: 'Anywhere' },
                        { value: 'Pune', label: 'Pune' },
                        { value: 'Satara', label: 'Satara' },
                        { value: 'Nashik', label: 'Nashik' },
                        { value: 'Mumbai', label: 'Mumbai' }
                      ]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Col>
                </Form.Group>
              </Row>
            </div>
            <div className=" mt-2">
              <Row>
                <Form.Group className="my-3 d-flex flex-column flex-md-row">
                  <Col xs={12} md={4}>
                    <Form.Label className="">Expected CTC:</Form.Label>
                  </Col>
                  <Col xs={12} md={8}>
                    <textarea
                      className="w-100"
                      value={ctc}
                      onChange={e => setCtc(e.target.value)}
                    ></textarea>
                  </Col>
                </Form.Group>
              </Row>
            </div>
            <div className="">
              <Row>
                <Form.Group className="my-3 d-flex flex-column flex-md-row">
                  <Col xs={12} md={4}>
                    <Form.Label className="me-5">Has Own:</Form.Label>
                  </Col>
                  <Col xs={12} md={8}>
                    <Select
                      required
                      onChange={handlehasOwn}
                      // defaultValue={defaultProfessionalSkillOptions}
                      value={hasOwn}
                      isMulti
                      name="skills"
                      options={[
                        { value: 'Bike', label: 'Bike' },
                        { value: 'Laptop', label: 'Laptop' }
                      ]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Col>
                </Form.Group>
              </Row>
            </div>
            <div className=" mt-2">
              <Row>
                <Form.Group className="my-3 d-flex flex-column flex-md-row">
                  <Col xs={12} md={4}>
                    <Form.Label className="me-5">
                      Availability to Join:
                    </Form.Label>
                  </Col>
                  <Col xs={12} md={8}>
                    <Select
                      required
                      onChange={handleJoin}
                      // defaultValue={defaultProfessionalSkillOptions}
                      value={availability}
                      name="skills"
                      options={[
                        { value: 'Immediate', label: 'Immediate' },
                        { value: 'Within 30 days', label: 'Within 30 days' },
                        { value: 'After 30 days', label: 'After 30 days' }
                      ]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Col>
                </Form.Group>
              </Row>
            </div>
            <div className=" mt-2">
              <Row>
                <Form.Group className="my-3 d-flex flex-column flex-md-row">
                  <Col xs={12} md={4}>
                    <Form.Label className="">Other Remarks:</Form.Label>
                  </Col>
                  <Col xs={12} md={8}>
                    <textarea
                      className="w-100"
                      style={{ height: '6rem' }}
                      value={otherRemarks}
                      onChange={e => setOtherRemarks(e.target.value)}
                    ></textarea>
                  </Col>
                </Form.Group>
              </Row>
            </div>
            <div className="d-flex">
              <Button
                type="button"
                onClick={() => navigate('/vsp')}
                className="btn btn-warning btn-lg btn-block me-2"
              >
                Cancel
              </Button>
              {vspStatus === 'true' ? (
                <>
                  <Button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block"
                  >
                    Update
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block"
                  >
                    Submit
                  </Button>
                </>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { userData, allCities, role, fullName, ID, authToken } = auth

  return {
    userData,
    allCities,
    role,
    fullName,
    ID,
    authToken
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateProfileForHr)

// Delete modal component
function DeleteEducation(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.titleMessage}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.confirmDeleteMessage}</h4>
        {/* <h4>Are you sure you want to delete this Placement Drive?</h4> */}
        {/* <p>
            This action cannot be undone. Once you confirm, the job will be permanently deleted from the system.
        </p> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.handleDelete}>
          Confirm Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
