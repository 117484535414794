import React from 'react'
import { Pagination } from 'react-bootstrap'
// import CsLineIcons from 'cs-line-icons/CsLineIcons';

const TablePagination = ({ tableInstance }) => {
  const {
    gotoPage,
    canPreviousPage,
    pageCount,
    previousPage,
    nextPage,
    canNextPage,
    state: { pageIndex }
  } = tableInstance

  if (pageCount <= 1) {
    return <></>
  }

  return (
    <Pagination className="justify-content-center mb-0 mt-3 custom-pagination-active overflow-hidden">
      {/* <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} class="page-link custom-pagination-scroll">
        <span class="d-flex">
          <ion-icon name="chevron-back-outline"></ion-icon>
          <ion-icon name="chevron-back-outline" style={{marginLeft: '-7px'}}></ion-icon>
        </span>
      </Pagination.First> */}
      <Pagination.Prev
        disabled={!canPreviousPage}
        onClick={() => previousPage()}
      >
        <ion-icon name="chevron-back-outline"></ion-icon>
      </Pagination.Prev>
      {[...Array(pageCount)].map((x, i) => (
        <>
          {i <= pageIndex + 2 && i >= pageIndex - 2 && (
            <Pagination.Item
              key={`pagination${i}`}
              active={pageIndex === i}
              onClick={() => {
                console.log(i)
                gotoPage(i)
              }}
            >
              {i + 1}
            </Pagination.Item>
          )}
        </>
      ))}
      <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage}>
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </Pagination.Next>
      {/* <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <span class="d-flex">
          <ion-icon name="chevron-forward-outline" style={{marginRight: '-7px'}}></ion-icon>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </span>
      </Pagination.Last> */}
    </Pagination>
  )
}
export default TablePagination
