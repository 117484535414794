import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button, Form, Accordion, Badge } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import job5 from '../images/jobs/job6.png'
import { createJobApplication, fetchJobData, getAllJobs, getAllFakeJobs } from '../../features/constants/apis'
import { connect } from 'react-redux'
import { setToken } from '../../features/auth/authSlice'
import { postNewJob } from '../../features/constants/apis'
import { setSelectedJob } from "../../features/data/selectedDriveSlice.js";
import ShimmerJobs from './Shimmer/ShimmerJobs.js';
import { Link } from 'react-router-dom';
import { IoFunnel, IoFunnelOutline } from "react-icons/io5";
import { FaArrowLeft } from 'react-icons/fa';
import JobListCard from './JobListCard.js';
import HiringKanban from './HiringKanban.js';



function Joblist({ authToken, role, ID, setSelectedJob }) {

    // console.log(ID)

    const navigate = useNavigate();

    // Validation
    const [validated, setValidated] = useState(false);

    const [allJobs, setAllJobs] = useState([]);
    const [allFakeJobs, setAllFakeJobs] = useState([]);

    const [isNewJob, setIsNewJob] = useState(false);

    const [jobData, setJobData] = useState([])


    const [jobImage, setJobImage] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [jobType, setJobType] = useState(null);
    const [jobLocation, setJobLocation] = useState(null)
    const [jobCtc, setJobCtc] = useState(null)
    const [numValueOpenings, setNumValueOpenings] = useState('')
    const [jobEducation, setJobEducation] = useState(null)


    const [buttonDisableID, setButtonDisableID] = useState([])


    const [expandedItem, setExpandedItem] = useState(null);

    const toggleAccordion = (idx) => {
        if (expandedItem === idx) {
            setExpandedItem(null);
        } else {
            setExpandedItem(idx);
        }
    };

    const handleInputTitle = (event) => {
        setJobTitle(event.target.value);
    };
    const handleInputOpenings = (event) => {
        const newNumValue = event.target.value.replace(/\D/g, '');
        setNumValueOpenings(newNumValue);
    };


    useEffect(()=> {
        const jobByID = async() => {
            const response = await fetchJobData(112, authToken);
            console.log(response)
        }
        jobByID();
    }, [])

    useEffect(() => {
        const fetchAllJobs = async () => {
            const data = await getAllJobs(authToken);
            // const filteredJobs = data.data.filter(job => job.PlacementDriveID === null)
            setAllJobs(data.data);
            console.log(data.data)
            // console.log(filteredJobs.length)
            // console.log(ID)
        }
        fetchAllJobs();
    }, [authToken]);

    useEffect(() => {
        const fetchAllFakeJobs = async () => {
            const data = await getAllFakeJobs(authToken);
            // const filteredJobs = data.data.filter(job => job.PlacementDriveID === null)
            setAllFakeJobs(data.data);
            console.log(data.data)
            // console.log(filteredJobs.length)
            console.log(ID)
        }
        fetchAllFakeJobs();
    }, [authToken]);



    const uploadRefImage = (filePath) => {
        // const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        const formData = new FormData();
        formData.append('inputFile', filePath)
        axios.post('https://api.webweb.ai/website/upload/scopehai', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(res => {
            console.log(res)
            // setImagePreview(res.data.data)
            // tempCustomOrderObj2.ReferenceImage = res.data.data
            // tempCustomOrderObj2.OrderDetails.ReferenceImage = res.data.data
            // setTempCustomOrderObj(tempCustomOrderObj2)
        }).catch(err => {
        })
    }


    const handleAddNewJob = () => {
        // setIsNewJob(true);

        console.log("true")
        navigate(`/new-job-form`)
    }

    const handleSaveClick = (event) => {
        // Perform save action here (e.g., update backend)
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity() === true) {
            // Proceed with saving the job if the form is valid
            const newJobFormData = {
                'JobTitle': jobTitle,
                'JobType': jobType,
                'JobLocation': jobLocation,
                'JobCTC': jobCtc,
                'OpenPositions': numValueOpenings,
                'EducationRequirement': jobEducation,
                'tokenUserID': ID
            }

            const newJob = async () => {
                const newJobData = await postNewJob(newJobFormData, authToken)
                console.log(newJobData)
            }

            newJob();
            setIsNewJob(false);
        }
        setIsNewJob(true);
    };

    const handleCancelClick = () => {
        setJobImage('')
        setJobTitle('')
        setJobType(null)
        setJobCtc(null)
        setJobEducation(null)
        setJobLocation(null)
        setNumValueOpenings('')
        setIsNewJob(false);
    }

    const handleViewJobDetails = (job) => {
        navigate(`/job-details?Job=${job}`)
    }


    const notify = async (ar, index) => {

        setButtonDisableID([...buttonDisableID, index]);

        console.log(ar?.ID, ID, ar?.EmployerID)

        const applicationData = {
            'JobID': ar?.ID,
            'EmpID': ar?.EmployerID,
            'JobTitle': ar?.Title,
            'tokenUserID': ID,
        }

        const jobs = async () => {
            const jobData = await createJobApplication(applicationData, authToken)
            console.log(jobData)

            if (jobData?.data === "Job appliation created successfully!") {
                toast.success('Your Skill profile has been shared with the recruiter!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                })
            }
            // console.log(jobData?.data)
        }
        jobs()
    };

    // console.log(allJobs)

    const [activeKey, setActiveKey] = useState(null);

    const handleToggleAccordion = () => {
        setActiveKey(activeKey === '0' ? null : '0');
    };

    return (
        <>
            <div id="appCapsule" class="d-none">
                <section className="section full pt-4">
                    <div className='container'>
                        <Link to="/" type="submit" className='my-2 text-white'>
                            <Button variant="primary mb-2"><FaArrowLeft style={{ width: '15px', height: '15px', marginRight: "7px" }} />Go to Dashboard
                            </Button>
                        </Link>
                        <Row>
                            <Col className='d-flex justify-content-between'>
                                <div>
                                    <div className="d-flex align-items-center gap-2">
                                        <h1 className="title center text-dark" style={{ margin: "0" }}>Job Listing</h1>
                                        <div>
                                            <Link to="" className='d-flex' onClick={handleToggleAccordion}>
                                                {activeKey === '0' ?
                                                    <>
                                                        <Badge style={{ color: "#45a994" }} bg="none"><IoFunnel style={{ height: 'auto', width: '25px' }} /></Badge>
                                                    </>
                                                    :
                                                    <>
                                                        <Badge style={{ color: "#45a994" }} bg="none"><IoFunnelOutline style={{ height: 'auto', width: '25px' }} /></Badge>
                                                    </>

                                                }
                                            </Link>
                                        </div>

                                    </div>
                                    {role === "Candidate" ? <div className="section-title ps-0 text-dark">Top jobs matching your profile</div> : <div className="section-title ps-0 text-dark">Your Jobs</div>}
                                    {/* <p className='text-dark'>We have considered your profi  le and the inputs given by you and have pulled some of the jobs from our recruitement partners.</p> */}
                                </div>
                                <div>
                                    {(role === 'Employer' || role === 'Admin') &&
                                        <Button className='add-btn btn-secondary' variant="primary" onClick={handleAddNewJob}>Add</Button>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="container section full mt-2 mb-4">
                    <div className='container'>
                        <Row>
                            <Col md="12">
                                {/* <div className='top-sec-heading'>
                                <h4>Filter By</h4>
                            </div> */}
                                <Accordion activeKey={activeKey}>
                                    <Accordion.Item eventKey="0">
                                        {/* <Accordion.Header><h2>Filter By</h2></Accordion.Header> */}
                                        <Accordion.Body>
                                            <Row>
                                                <Col md="4">
                                                    <Accordion style={{ borderTop: 'none' }}>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>Location</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Select aria-label="Default select example">
                                                                    <option>Select your location</option>
                                                                    <option value="1">Mumbai</option>
                                                                    <option value="2">Navi Mumbai</option>
                                                                    <option value="3">Pune</option>
                                                                </Form.Select>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </Col>
                                                <Col md="4">
                                                    <Accordion>
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header>Job Type</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form className='wide-block py-3'>
                                                                    <Form.Check
                                                                        label="Full time"
                                                                        name="group1"
                                                                        type='radio'
                                                                        id={`job-type-1`}
                                                                    />
                                                                    <Form.Check
                                                                        label="Part time"
                                                                        name="group1"
                                                                        type='radio'
                                                                        id={`job-type-2`}
                                                                    />
                                                                    <Form.Check
                                                                        label="Freelance"
                                                                        name="group1"
                                                                        type='radio'
                                                                        id={`job-type-3`}
                                                                    />
                                                                </Form>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </Col>
                                                <Col md="4">
                                                    <Accordion>
                                                        <Accordion.Item eventKey="2">
                                                            <Accordion.Header>Experience</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form className='wide-block py-3'>
                                                                    <Form.Check
                                                                        label="Fresher"
                                                                        name="group2"
                                                                        type='radio'
                                                                        id={`xp-1`}
                                                                    />
                                                                    <Form.Check
                                                                        label="Intermediate"
                                                                        name="group2"
                                                                        type='radio'
                                                                        id={`xp-2`}
                                                                    />
                                                                    <Form.Check
                                                                        label="Expert"
                                                                        name="group2"
                                                                        type='radio'
                                                                        id={`xp-3`}
                                                                    />
                                                                </Form>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <Accordion>
                                                        <Accordion.Item eventKey="3">
                                                            <Accordion.Header>Salary</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form.Label>Range</Form.Label>
                                                                <Form.Range />
                                                                <Form className='wide-block py-3'>
                                                                    <Form.Check
                                                                        label="3-5 LPA"
                                                                        name="group3"
                                                                        type='radio'
                                                                        id={`salary-1`}
                                                                    />
                                                                    <Form.Check
                                                                        label="5-7 LPA"
                                                                        name="group3"
                                                                        type='radio'
                                                                        id={`salary-2`}
                                                                    />
                                                                    <Form.Check
                                                                        label="7-10 LPA"
                                                                        name="group3"
                                                                        type='radio'
                                                                        id={`salary-3`}
                                                                    />
                                                                </Form>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </Col>
                                                <Col md="4">
                                                    <Accordion>
                                                        <Accordion.Item eventKey="4">
                                                            <Accordion.Header>Category</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form className='wide-block py-3'>
                                                                    <Form.Check
                                                                        label="Developer"
                                                                        name="group3"
                                                                        type='checkbox'
                                                                        id={`category-1`}
                                                                    />
                                                                    <Form.Check
                                                                        label="Finance"
                                                                        name="group3"
                                                                        type='checkbox'
                                                                        id={`category-2`}
                                                                    />
                                                                    <Form.Check
                                                                        label="Design"
                                                                        name="group3"
                                                                        type='checkbox'
                                                                        id={`category-3`}
                                                                    />
                                                                </Form>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </Col>
                                                <Col md="4">
                                                    <Accordion>
                                                        <Accordion.Item eventKey="5">
                                                            <Accordion.Header>Tags</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form className='wide-block py-3'>
                                                                    <Form.Check
                                                                        label="Developer"
                                                                        name="group3"
                                                                        type='checkbox'
                                                                        id={`Tags-1`}
                                                                    />
                                                                    <Form.Check
                                                                        label="Finance"
                                                                        name="group3"
                                                                        type='checkbox'
                                                                        id={`Tags-2`}
                                                                    />
                                                                    <Form.Check
                                                                        label="Design"
                                                                        name="group3"
                                                                        type='checkbox'
                                                                        id={`Tags-3`}
                                                                    />
                                                                </Form>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </Col>
                                            </Row>
                                            <div className='job-filter-box'>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12 pt-4">
                                <div className='top-sec-heading'>
                                    {(role === 'Candidate' || role === 'Admin') &&
                                        <h4>All {allFakeJobs.length !== null && allFakeJobs.length} jobs found</h4>
                                    }
                                    {
                                        role === 'Employer' && <h4>All {allFakeJobs.filter(item => item.EmployerID === ID).length} jobs found</h4>
                                    }

                                </div>

                                {isNewJob && (
                                    <div className='py-4'>
                                        <h3>Create New Job</h3>
                                        <ul className="listview image-listview media">
                                            <li className='my-2 newJob'>
                                                <div className="item">
                                                    <div className="imageWrapper in">
                                                        {/* <img src="" alt="" className="imaged w64" /> */}
                                                        <label htmlFor="jobImageInput" className="job-image-input rounded-circle position-relative">
                                                            {jobImage ? (
                                                                <img src={jobImage} alt="Uploaded" className="imaged w64" />
                                                            ) : (
                                                                <>
                                                                    <input
                                                                        id="jobImageInput"
                                                                        type="file"
                                                                        accept="image/*"
                                                                        className="position-absolute opacity-0"
                                                                        onChange={(e) => {
                                                                            const file = e.target.files[0];
                                                                            if (file) {
                                                                                const reader = new FileReader();
                                                                                reader.onload = (e) => {
                                                                                    const imageUrl = e.target.result;
                                                                                    setJobImage(imageUrl); // Assuming you have a state variable to store the image URL
                                                                                };
                                                                                reader.readAsDataURL(file);
                                                                            }
                                                                        }}
                                                                    />Upload Image
                                                                </>
                                                            )}
                                                        </label>
                                                        <div className="in">
                                                            <div>
                                                                <div className="text-muted">
                                                                    <Form.Select aria-label="Default select example"
                                                                        required
                                                                        onChange={(e) => setJobType(e.target.value)}
                                                                        isInvalid={validated && !jobType}
                                                                    >
                                                                        <option selected disabled>job type</option>
                                                                        <option value="Full Time">Full Time</option>
                                                                        <option value="Part Time">Part Time</option>
                                                                        <option value="Internship">Internship</option>
                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please select Job Type.
                                                                    </Form.Control.Feedback>
                                                                </div>
                                                                <Form.Control className='b-bottom'
                                                                    required
                                                                    type="text"
                                                                    onChange={handleInputTitle}
                                                                    value={jobTitle}
                                                                    placeholder="Enter Job Title"
                                                                    isInvalid={validated && !jobTitle}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a Job Title.
                                                                </Form.Control.Feedback>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="in">
                                                        <div>
                                                            <div className="text-muted">
                                                                <Form.Select aria-label="Default select example"
                                                                    onChange={(e) => setJobLocation(e.target.value)}
                                                                    isInvalid={validated && !jobLocation}
                                                                >
                                                                    <option selected disabled>Select Location</option>
                                                                    <option value="Mumbai">Mumbai</option>
                                                                    <option value="Navi Mumbai">Navi Mumbai</option>
                                                                    <option value="Pune">Pune</option>
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please select Job Location.
                                                                </Form.Control.Feedback>
                                                            </div>
                                                            <Form.Select aria-label="Default select example"
                                                                onChange={(e) => setJobCtc(e.target.value)}
                                                                isInvalid={validated && !jobCtc}
                                                            >
                                                                <option selected disabled>Select CTC range</option>
                                                                <option value="1 Lakh to 5 Lakh">1 Lakh to 5 Lakh</option>
                                                                <option value="6 Lakh to 10 Lakh">6 Lakh to 10 Lakh</option>
                                                                <option value="11 Lakh to 15 Lakh">11 Lakh to 15 Lakh</option>
                                                                <option value="15 Lakh and above">15 Lakh and above</option>
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please select CTC Range.
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </div>
                                                    <div className="in">
                                                        <div>
                                                            <div className="text-muted">
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    value={numValueOpenings}
                                                                    onChange={handleInputOpenings}
                                                                    placeholder="Enter number of Openings"
                                                                    isInvalid={validated && !numValueOpenings}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a openings.
                                                                </Form.Control.Feedback>
                                                            </div>
                                                            <Form.Select aria-label="Default select example"
                                                                onChange={(e) => setJobEducation(e.target.value)}
                                                                isInvalid={validated && !jobEducation}
                                                            >
                                                                <option selected disabled>Selecte qualification</option>
                                                                <option value="12th Pass">12th Pass</option>
                                                                <option value="Diploma">Diploma</option>
                                                                <option value="ITI">ITI</option>
                                                                <option value="Graduation">Graduate</option>
                                                                <option value="Post Graduation">Post Graduate</option>
                                                                <option value="PHD">PHD</option>
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please select a required qualification.
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </div>
                                                    {/* <div className='d-flex align-items-center'>
                                                        </div> */}
                                                    <div className="d-flex gap-1">
                                                        <Button noValidate validated={validated} onClick={handleSaveClick}>Save</Button>
                                                        <Button onClick={handleCancelClick}>Cancel</Button>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )}


                                {allFakeJobs.length === null ? <ShimmerJobs/> 
                                :
                                <JobListCard
                                    allFakeJobs={allFakeJobs}
                                />
                                }
                                

                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
            <div id='appCapsule' style={{ backgroundColor: '#ffffff' }}>
                <HiringKanban></HiringKanban>
            </div>
        </>
    )
}
const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, authToken } = auth

    return {
        userData,
        role,
        fullName,
        ID,
        authToken
    }
}

const mapDispatchToProps = {
    setToken,
    setSelectedJob
}

export default connect(mapStateToProps, mapDispatchToProps)(Joblist)
