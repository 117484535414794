import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CandidateCard from './Cards/CandidateCard';
import axios from '../../AxiosConfig';
import { Link } from 'react-router-dom';

const Shortlisted = () => {
    const [shortlistedData, setShortlistedData] = useState(null)
    useEffect(async () => {
        const response = await axios.get(`/shortlist-by-emp-id`);
        console.log(response)
        setShortlistedData(response.data.items)
    }, [])
    return (
        <div id="appCapsule">
            <div class="section full">
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-12 col-md-12 d-flex align-items-center mt-2 mb-2">
                            <Link to="/employer-dashboard">
                                <h3 className="mb-0 d-flex align-items-center  cursor-pointer"><ion-icon name="chevron-back-outline" class="fs-3 me-1"></ion-icon> {shortlistedData?.length} Candidates Shortlisted</h3>
                            </Link>
                        </div>
                        {[1].map((v) => {
                            return <>
                                <CandidateCard type="readOnly" />
                            </>
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = () => {

    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Shortlisted);