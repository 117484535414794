import React, { useState, useHistory, useEffect } from 'react'
import { connect } from "react-redux";
import { Form, InputGroup, Button, Row, Col, FloatingLabel } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setToken } from '../../../features/auth/authSlice';
import { fetchPlacementDrives, postNewPlacementDrive, fetchAllJobRoles } from '../../../features/constants/apis';
import { useNavigate } from 'react-router';
import moment from 'moment';
import axios from 'axios';
import { FaArrowLeft } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Select from "react-select";

const NewPlacementDriveForm = ({ authToken, ID }) => {

    const navigate = useNavigate();

    // console.log("Auth Token:", authToken);
    console.log(ID)

    const [placementTitle, setPlacementTitle] = useState('');
    const [placementCriteria, setPlacementCriteria] = useState('');
    const [numValueProfiles, setNumValueProfiles] = useState('');
    const [selectEduQualification, setSelectEduQualification] = useState(null)
    const [domain, setDomain] = useState(null)
    const [selectLocation, setSelectLocation] = useState(null)
    const [domainData, setDomainData] = useState();

    // Date picker
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        const getJobroles = async () => {
            const response = await fetchAllJobRoles(authToken);
            console.log("jobroles:", response.data);
            const uniqueDomains = [...new Map(response?.data.map(item => [item.Domain, item])).values()];
            setDomainData(uniqueDomains)
            console.log("uniqueDomains:", uniqueDomains);

        }
        getJobroles();
    }, [authToken])
    console.log("domainData:", domainData);

    const handleInputTitle = (event) => {
        setPlacementTitle(event.target.value);
    };

    const handleInputCriteria = (event) => {
        setPlacementCriteria(event.target.value);
        console.log(placementCriteria)
    };

    const handleInputProfiles = (event) => {
        const newNumValue = event.target.value.replace(/\D/g, '');
        setNumValueProfiles(newNumValue);
    };

    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

    // Validation
    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        const newPlacementFormData = {
            'domain': domain,
            'PlacementQualification': selectEduQualification,
            'NumOfProfiles': numValueProfiles,
            'PlacementTitle': placementTitle,
            'PlacementCriteria': placementCriteria,
            'PlacementLocation': selectLocation,
            'PlacementFromDate': formattedStartDate,
            'PlacementToDate': formattedEndDate,
            'tokenUserID': ID
        }

        const placementDriveData = await postNewPlacementDrive(newPlacementFormData, authToken)
        // console.log('formattedstartdate',placementDriveData?.formattedEndDate)
        // console.log('startdata', startDate)
        // console.log('endddate', endDate)

        const updatedPlacementDrives = await fetchPlacementDrives(authToken);
        console.log(updatedPlacementDrives)

        if (updatedPlacementDrives && updatedPlacementDrives.data) {
            const employerPlacementDrives = await updatedPlacementDrives.data.filter((drive) => drive.EmployerID === ID)
            console.log('employer placement drives', employerPlacementDrives)

            employerPlacementDrives.sort((a, b) => a.ID - b.ID)
            const lastAddedPlacementDrive = employerPlacementDrives[employerPlacementDrives.length - 1]
            const lastAddedPlacementDriveID = lastAddedPlacementDrive?.ID;
            console.log(lastAddedPlacementDriveID)

            if (lastAddedPlacementDriveID) {
                navigate(`/emp-placement-drive-details?drive=${lastAddedPlacementDriveID}`);
            }
        }

        setValidated(true);

    };


    //   const sendBack = () =>{
    //     navigate('/emp-placement-drives');
    //   }

    const uploadRefImage = (filePath) => {
        // const tempCustomOrderObj2 = JSON.parse(JSON.stringify(tempCustomOrderObj))
        const formData = new FormData();
        formData.append('inputFile', filePath)
        axios.post('https://api.webweb.ai/website/upload/scopehai', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(res => {
            console.log(res)
            // setImagePreview(res.data.data)
            // tempCustomOrderObj2.ReferenceImage = res.data.data
            // tempCustomOrderObj2.OrderDetails.ReferenceImage = res.data.data
            // setTempCustomOrderObj(tempCustomOrderObj2)
        }).catch(err => {
        })
    }



    return (
        <div className="section mb-2 mt-5 full">
            <div className="container py-3">
                <div className="d-flex align-items-center">
                    <Link to="/emp-placement-drives" type="submit" className='my-2 pe-2' ><FaArrowLeft style={{ width: '23px', height: '23px' }} /></Link>
                    <h1 className='text-primary mb-0'>Add new placement drive</h1>
                </div>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            onChange={handleInputTitle}
                            value={placementTitle}
                            placeholder="Enter Placement Drive Title"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid Title.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Sub-Title</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter Sub-Title"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid Sub-Title.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Avg. Package</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter Avg. Package"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid Package.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>Openings</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            value={numValueProfiles}
                            onChange={handleInputProfiles}
                            placeholder="Enter number of Openings"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid number of Openings.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className="d-md-flex gap-2 mb-2">
                        <Form.Group className='my-0 d-flex'>
                            {/* <Form.Label>Start Date(YYYY/MM/DD)</Form.Label> */}
                            <InputGroup.Text id="inputGroup-sizing-default">
                                <ion-icon name="calendar-outline" style={{ color: '#45a994' }}></ion-icon>
                            </InputGroup.Text>
                            <InputGroup>
                                <DatePicker
                                    style={{ height: '100%' }}
                                    showIcon
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    icon="fa fa-calendar"
                                    dateFormat="dd/MM/yyyy" />
                            </InputGroup>
                        </Form.Group>
                        <div className='d-flex align-items-center'>
                            <h4 className='m-0'>to</h4>
                        </div>
                        <Form.Group className='my-0'>
                            {/* <Form.Label>End Date(dd/mm/yyyy)</Form.Label> */}
                            <InputGroup>
                                <DatePicker
                                    showIcon
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    minDate={startDate}
                                    icon="fa fa-calendar"
                                    dateFormat="dd/MM/yyyy" />
                            </InputGroup>
                        </Form.Group>
                    </div>




                    <Form.Group className='my-3'>
                        <Form.Label>Qualification</Form.Label>
                        <Form.Select
                            required
                            aria-label="Default select example"
                            onChange={(e) => setSelectEduQualification(e.target.value)}
                        >
                            <option selected disabled value="">Select your educational qualification</option>
                            <option value="12th Pass">12th Pass</option>
                            <option value="Diploma">Diploma</option>
                            <option value="ITI">ITI</option>
                            <option value="Graduate">Graduate</option>
                            <option value="Post graduate">Post graduate</option>
                            <option value="PHD">PHD</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='my-3'>
                        <Form.Label>Select Domain</Form.Label>
                        <Select
                            options={Array.from(new Set(domainData?.map(item => item.Domain))) // Extract unique domains
                                .map(domain => ({ value: domain, label: domain }))} // Format for react-select
                            isSearchable
                            placeholder="Select a domain"
                            onChange={(selectedOption) => setDomain(selectedOption.value)}
                        />
                    </Form.Group>
                    
                    
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Criteria</Form.Label> */}
                        <Form.Control
                            required
                            as="textarea"
                            placeholder="Description"
                            onChange={handleInputCriteria}
                            value={placementCriteria}
                            style={{ height: '100px' }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid Criteria.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        {/* <Form.Label>Location</Form.Label> */}
                        <div className="w-25 d-flex" style={{ height: '37px' }}>
                            <InputGroup.Text id="inputGroup-sizing-default" style={{ height: '37px' }}>
                                <ion-icon name="location-outline" style={{ color: '#45a994' }}></ion-icon>
                                {/* <IoLocationOutline style={{color: '#45a994'}}/> */}
                            </InputGroup.Text>
                            <Form.Select
                                required
                                aria-label="Default select example"
                                onChange={(e) => setSelectLocation(e.target.value)}
                            >
                                <option selected disabled value="">Select location</option>
                                <option value="Mumbai">Mumbai</option>
                                <option value="Navi Mumbai">Navi Mumbai</option>
                                <option value="Panvel">Panvel</option>
                                <option value="Pune">Pune</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                    
                    <Row>
                    </Row>
                    <div className="d-flex gap-2">
                        <Button type="submit" variant="primary">Create placement drive</Button>
                        <Button type="button" variant="outline-primary" onClick={() => window.history.back()}>Cancel</Button>
                    </div>
                </Form>
            </div>
        </div >
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, authToken } = auth

    return {
        userData,
        role,
        fullName,
        ID,
        authToken
    }
}

const mapDispatchToProps = {
    setToken
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewPlacementDriveForm)

// export default NewPlacementDriveForm
