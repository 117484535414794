import axios from 'axios'
import config from '../../config'

// Get all users
export const GetAllUsers = async authToken => {
  console.log(authToken)
  const response = await axios.get(config.apiUrl + '/user', {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  return data
}

// Get users
export const GetCandidates = async authToken => {
  const response = await axios.get(config.apiUrl + '/user/candidate', {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  return data
}

// Get Candidate by ID
export const GetCandidateByID = async (ID, authToken) => {
  const response = await axios.get(config.apiUrl + '/user/candidate/' + ID, {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  return data
}


export const fetchPlacementDrives = async authToken => {
  const response = await axios.get(config.apiUrl + '/placement-drive', {
    headers: {
      Authorization: authToken
    }
  })

  const { data } = response
  return data
}

export const getPlacementDriveById = async (authToken) => {
  const response = await axios.get(config.apiUrl + '/placement-drive-by-id', {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  return data
}

export const fetchPlacementDrivesData = async (driveID, authToken) => {
  const response = await axios.get(
    config.apiUrl + '/job-placement-drive/' + driveID,
    {
      headers: {
        Authorization: authToken
        // Add other headers as needed
      }
    }
  )
  // console.log(response)
  const { data } = response
  return data
}

export const createJobApplication = async (applicationData, authToken) => {
  const response = await axios.post(
    config.apiUrl + '/job-application',
    applicationData,
    {
      headers: {
        Authorization: authToken
        // Add other headers as needed
      }
    }
  )
  //   console.log(response)
  const { data } = response
  return data
}

export const createPDJobApplication = async (
  websiteApplicationData,
  retrievedUrl,
  authToken
) => {
  const response = await axios.post(
    config.apiUrl + '/placement-drive/url/' + retrievedUrl,
    websiteApplicationData,
    {
      headers: {
        Authorization: authToken
        // Add other headers as needed
      }
    }
  )
  //   console.log(response)
  const { data } = response
  return data
}

export const GetAllApplicationsOfDrive = async (applicationData, authToken) => {
  const response = await axios.post(
    config.apiUrl + '/placement-drive/filter',
    applicationData,
    {
      headers: {
        Authorization: authToken
        // Add other headers as needed
      }
    }
  )
  //   console.log(response)
  const { data } = response.data
  return data
}

export const getAllJobApplications = async authToken => {
  console.log(config.apiUrl + '/job-application')
  const response = await axios.get(config.apiUrl + '/job-application', {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response.data
  return data
}

export const getCandidateJobApplication = async (candidateID, authToken) => {
  const response = await axios.get(
    config.apiUrl + '/job-application/' + candidateID,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  // console.log(response)
  const { data } = response.data
  return data
}

export const fetchCertificationCards = async authToken => {
  const response = await axios.get(config.apiUrl + '/courses', {
    headers: {
      Authorization: authToken
      // Add other headers as needed
    }
  })
  const { data } = response
  // console.log(response)
  return data
}

export const fetchCertificationDetailsData = async (courseID, authToken) => {
  const response = await axios.get(config.apiUrl + '/courses/' + courseID, {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  return data
}

export const getAllCourseApplications = async authToken => {
  const response = await axios.get(config.apiUrl + '/course-application', {
    headers: {
      Authorization: authToken
    }
  })
  // console.log(response)
  const { data } = response
  return data
}

export const createCourseApplication = async (
  courseApplicationData,
  authToken
) => {
  const response = await axios.post(
    config.apiUrl + '/course-application',
    courseApplicationData,
    {
      headers: {
        Authorization: authToken
        // Add other headers as needed
      }
    }
  )
  //   console.log(response)
  const { data } = response
  return data
}

export const GetAllApplicationsOfJob = async (applicationData, authToken) => {
  const response = await axios.post(
    config.apiUrl + '/jobs/filter',
    applicationData,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  // console.log(response)
  const { data } = response.data
  return data
}

// Get all Jobs
export const getAllJobs = async authToken => {
  const response = await axios.get(config.apiUrl + '/jobs', {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  return data
}

//
export const fetchJobData = async (jobID, authToken) => {
  const response = await axios.get(config.apiUrl + '/jobs/' + jobID, {
    headers: {
      Authorization: authToken
      // Add other headers as needed
    }
  })
  // console.log(response)
  const { data } = response
  return data
}

// placementDrive
export const postNewPlacementDrive = async (
  newPlacementFormData,
  authToken
) => {
  const response = await axios.post(
    config.apiUrl + '/placement-drive',
    newPlacementFormData,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  // console.log(response)
  const { data } = response.data
  return data
}

// delete placement drive
export const deletePlacementDriveByID = async (pDriveID, authToken) => {
  const response = await axios.delete(
    config.apiUrl + '/placement-drive/' + pDriveID,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  // console.log(response.data);
  const { data } = response.data
  return data
}

// delete Job
export const deleteJobByID = async (jobID, authToken) => {
  const response = await axios.delete(config.apiUrl + '/jobs/' + jobID, {
    headers: {
      Authorization: authToken
    }
  })
  // console.log(response.data);
  const { data } = response.data
  return data
}

// Job form
export const postNewJob = async (newJobFormData, authToken) => {
  const response = await axios.post(config.apiUrl + '/jobs', newJobFormData, {
    headers: {
      Authorization: authToken
    }
  })
  // console.log(response)
  const { data } = response.data
  return data
}

// post Placement Drive Job
export const postNewPlacementDriveJob = async (
  pDriveID,
  newJobData,
  authToken
) => {
  const response = await axios.post(
    config.apiUrl + '/placement-drive/addjob/' + pDriveID,
    newJobData,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  // console.log(response)
  const { data } = response.data
  return data
}

// update Job
export const updateExistingJob = async (jobID, updatedJobData, authToken) => {
  const response = await axios.put(
    config.apiUrl + '/jobs/' + jobID,
    updatedJobData,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  // console.log(response)
  const { data } = response.data
  return data
}

// update Placement Drive
export const updatePlacementDrive = async (
  pDriveID,
  updatedPlacementDriveData,
  authToken
) => {
  const response = await axios.put(
    config.apiUrl + '/placement-drive/' + pDriveID,
    updatedPlacementDriveData,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  // console.log(response)
  const { data } = response.data
  return data
}

// get all skills
export const fetchAllSkills = async authToken => {
  const response = await axios.get(config.apiUrl + '/skills', {
    headers: {
      Authorization: authToken
      // Add other headers as needed
    }
  })
  const { data } = response
  // console.log(response)
  return data
}

// get all education degrees
export const fetchAllEduDegrees = async authToken => {
  const response = await axios.get(config.apiUrl + '/eduDegrees', {
    headers: {
      Authorization: authToken
      // Add other headers as needed
    }
  })
  const { data } = response
  // console.log(response)
  return data
}

// get all education levels
export const fetchAllEduLevels = async authToken => {
  const response = await axios.get(config.apiUrl + '/eduLevels', {
    headers: {
      Authorization: authToken
      // Add other headers as needed
    }
  })
  const { data } = response
  // console.log(response)
  return data
}
// get all job roles
export const fetchAllJobRoles = async authToken => {
  const response = await axios.get(config.apiUrl + '/jobRoles', {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  return data
}

// get all skills (Gen and Pro) from jobRoles
export const fetchAllJobRolesSkills = async authToken => {
  const response = await axios.get(config.apiUrl + '/jobRoles/allSkills', {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  return data
}

// get all education levels
export const fetchAllEducationLevels = async authToken => {
  const response = await axios.get(config.apiUrl + '/eduLevels', {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  return data
}

// get all education degrees
export const fetchAllEducationDegrees = async authToken => {
  const response = await axios.get(config.apiUrl + '/eduDegrees', {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  return data
}

// post user education data
export const postUserEducation = async (userId, newEduData, authToken) => {
  const response = await axios.post(
    config.apiUrl + '/user/' + userId + '/education',
    newEduData,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

// post user Experience
export const postUserExperience = async (userId, newExpData, authToken) => {
  console.log('authToken', authToken)

  const response = await axios.post(
    config.apiUrl + '/user/' + userId + '/experience',
    newExpData,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

export const postUserImage = async (userId, image, authToken) => {
  const response = await axios.put(
    config.apiUrl + '/user/candidate/' + userId,
    image,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

export const postUserCertificates = async (userId, image, authToken) => {
  const response = await axios.put(
    config.apiUrl + '/user/candidate/certificate/' + userId,
    image,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

export const UpdateUserProfile = async (userId, userInfo, authToken) => {
  const response = await axios.put(
    config.apiUrl + '/user/update/' + userId + '/profile',
    userInfo,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

export const updateUserCV = async (userId, userCV, authToken) => {
  const responase = await axios.put(
    config.apiUrl + '/user/' + userId + '/updatecv',
    userCV,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = responase
  return data
}

export const deleteCandidateEducationRecord = async (
  authToken,
  userId,
  eduID
) => {
  const response = await axios.put(
    config.apiUrl + '/user/education',
    { userId, eduID },
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

export const deleteCandidateExperienceRecord = async (
  authToken,
  userId,
  expID
) => {
  const response = await axios.put(
    config.apiUrl + '/user/experience',
    { userId, expID },
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

export const updateEducationRecord = async (
  authToken,
  userId,
  educationData
) => {
  console.log('educationData', educationData)

  const response = await axios.put(
    config.apiUrl + '/user/education/' + userId,
    educationData,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

export const updateExperienceRecord = async (
  authToken,
  userId,
  experienceData
) => {
  const response = await axios.put(
    config.apiUrl + '/user/experience/' + userId,
    experienceData,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

// get all admin dashboard data
export const getAdminDashboardData = async (authToken, startDate, endDate) => {
  console.log(authToken)
  const response = await axios.get(
    config.apiUrl + '/dashboardData',
    startDate,
    endDate,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

export const getCertificateAppliationById = async (authToken, certID) => {
  const response = await axios.get(
    config.apiUrl + '/course-application/' + certID,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

// get skills from jobRoles selected
export const getSkillsFromJobRole = async (authToken, jobTitle) => {
  const response = await axios.get(
    config.apiUrl + '/jobroles/allSkills/' + jobTitle,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

// update employer profile
export const updateEmployerProfile = async (authToken, empID, employerData) => {
  const response = await axios.put(
    config.apiUrl + '/user/update-employer-profile/' + empID,
    employerData,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

//shortlist Applicant
export const shortlistApplicant = async (authToken, jobID, candidateID) => {
  const response = await axios.put(
    config.apiUrl + '/job-application/shortlist/' + jobID + '/' + candidateID,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  return data
}

export const getRemovedSkills = async (authToken, userID) => {
  const response = await axios.get(config.apiUrl + `/skillsHistory/${userID}`, {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  return data
}

// Get all populated Jobs
export const getAllFakeJobs = async authToken => {
  const response = await axios.get(config.apiUrl + '/getfakejobs', {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  console.log('')
  return data
}

//Check if the candidate profile is complete
export const getCompleteProfileusers = async (authToken, ID) => {
  const response = await axios.post(
    config.apiUrl + `/getCompleteProfileuser`,
    { CandidateID: ID },
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  return response
}

//payment gateway create order
export const paymentGateway = async (authToken, ID, amount) => {
  // const response = await axios.post(config.apiUrl + `/create-order`, {userId: ID, amount: amount, appliedTo: 'VSP'}, {
  const response = await axios.post(
    config.apiUrl + `/create-order`,
    { userId: ID, amount: amount, appliedTo: 'VSP' },
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  return response
}

//payment gateway verify payment
export const paymentGatewayVerify = async paymentData => {
  const response = await axios.post(
    config.apiUrl + '/verify-payment',
    paymentData
  )
  return response
}

// VSP_________________________________________________________________________________________

// Fetch all users
export const GetAllVspApplied = async authToken => {
  const response = await axios.get(config.apiUrl + '/getallvsp', {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  console.log('Fetched users:', data) // Log fetched users
  return data
}

// Update VSP status for a user
export const UpdateUserVspStatus = async (authToken, candidateID) => {
  // Check if userID is not an object, but a valid string or number
  const response = await axios.post(
    config.apiUrl + `/vsp/update/${candidateID}`,
    null,
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  // console.log(`VSP status updated for user ${userID}:`, data);
  return data
}

// PAYMENTS -----------------------------------------------------------------

export const GetAllPayments = async (authToken, startDate, endDate) => {
  // Create an object to hold query parameters
  const params = {
    ...(startDate && { startDate: startDate.toISOString() }), // Convert date to ISO string
    ...(endDate && { endDate: endDate.toISOString() }) // Convert date to ISO string
  }

  const response = await axios.get(`${config.apiUrl}/getallpayments`, {
    headers: {
      Authorization: authToken
    },
    params // Pass the params object as query parameters
  })

  const { data } = response
  console.log('Fetched payments:', data) // Log fetched payments
  return data
}

// export const GetAllPayments = async (authToken, startDate, endDate, status) => {
//       const response = await axios.get(config.apiUrl + '/getallpayments', {
//             headers: {
//                   "Authorization": authToken
//             }
//       });
//       const { data } = response;
//       console.log("Fetched payments:", data); // Log fetched users
//       return data;
// };

// chohert paymnets --------------------------------------------------------------

export const GetAllCohertPayments = async authToken => {
  const response = await axios.get(config.apiUrl + '/getallcohertpayments', {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  console.log('Fetched payments:', data) // Log fetched users
  return data
}

export const handelOpenToWork = async (join, authToken) => {
  const response = await axios.get(config.apiUrl + '/user/datafilter' + join, {
    headers: {
      Authorization: authToken
      // Add other headers as needed
    }
  })
  // console.log(response)
  const { data } = response
  return data
}

export const vspVerification = async (candidateID, hrComments, authToken) => {
  const response = await axios.post(
    config.apiUrl + `/vsp-verification/${candidateID}`,
    { hrComments },
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  console.log('form data : ', hrComments)
  const { data } = response.data
  return data
}

export const UserJoinStatus = async params => {
  console.log('what data is in api: ', params)
  const response = await axios.put(
    config.apiUrl + '/user/update/joiningstatus/joinorswitch/' + params.userID,
    params
  )
  const { data } = response
  return data
}

export const GetVspStatusByID = async (ID, authToken) => {
  const response = await axios.get(config.apiUrl + '/user/vspstatus/' + ID, {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  return data
}

export const getVerifiedCandidates = async authToken => {
  const response = await axios.get(
    config.apiUrl + '/user-candidate/verified-count',
    {
      headers: {
        Authorization: authToken
      }
    }
  )
  const { data } = response
  console.log('Fetched payments:', data) // Log fetched users
  return data
}
export const UpdateAttendanceStatus = async (authToken, ID, Role, comment) => {
  const response = await axios.post(
    config.apiUrl + `/hr-attended/${ID}/${Role}`,
    { comment },
    {
      headers: {
        Authoriation: authToken
      }
    }
  )
  const { data } = response
  return data
}

export const serverTable = async (params, authToken) => {
  console.log('params: ', params)

  const response = await axios.get(config.apiUrl + '/user/datafilter', {
    params,
    headers: {
      Authorization: authToken
    }
  })
  const data = response
  console.log('Fetched payments:', data)
  return data
}

// collage list api fuc.
export const GetAllColleges = async authToken => {
  console.log(authToken)
  const response = await axios.get(config.apiUrl + '/colleges', {
    headers: { Authoriation: authToken }
  })
  const { data } = response
  return data
}

export const getSearchAppearance = async (authToken, today, oneWeekAgo) => {
  console.log("authToken, today, oneWeekAgo", authToken, today, oneWeekAgo);
  const response = await axios.get(config.apiUrl + `/user/search-appearance?today=${today}&oneWeekAgo=${oneWeekAgo}`, {
    headers: {
      Authorization: authToken
    }
  })
  const { data } = response
  return data
}
