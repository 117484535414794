import React, { useEffect, useState, useRef, useMemo } from "react";
import { ToggleButton, ButtonGroup, Form, Row, Col, Badge, Button, InputGroup, Modal } from 'react-bootstrap';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import config from '../../config';
import { connect } from "react-redux";
import axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import AppToast from "../Elements/Notification/AppToast";
import { showAppToast, hideAppToast } from '../../features/utilities/appToastSlice';
import Countdown, { zeroPad } from 'react-countdown';
import { requestOtp, validateOtp, backToLogin, registerUser, setCourseID, setXpLevel } from "../../features/auth/authSlice";
import Logo from '../images/ScopeHaiLogo-two-color.png';
import animation from '../images/Animation-tick.gif';
import LoginImg from '../images/Login-screen.png';
import { FaUserGraduate } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { getContrastRatio } from "@mui/material";
import CheckStepNumber from "./CheckStepNumber";
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import women_graph from '../images/women_graph.svg';
import { UserCheck } from 'lucide-react';
import OTPInput from "./OTPInput";


const EmpSignup = ({
    ID,
    showAppToast,
    appToastVisible,
    appToastMessage,
    appToastIcon,
    appToastBackground,
    appToastPosition,
    requestOtp,
    validateOtp,
    registerUser,
    otpRequestSuccess,
    otpRequestError,
    hideSendOtpButton,
    hideSendOtpButton1,
    showEmpVerifyOtpForm,
    showRegistrationForm,
    otpValidateSuccess,
    otpValidateError,
    backToLogin,
    isVisible,
    setCourseID,
    certCourseID,
    setXpLevel,
    registerCandidate,
    empRegistrationForm,
    newShowEmpRegistrationForm,
    showEmpDashboard,
    verificationOtpMessage
}) => {
    console.log('ID: ', ID, verificationOtpMessage);
    const [show, setShow] = useState(false)

    const inputRef = useRef(null);
    const emailInputRef = useRef(null)
    const navigate = useNavigate()

    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState("")
    const [mobileNumberCC, setMobileNumberCC] = useState("+91")
    const [disableVerifyOtpButton, setDisableVerifyOtpButton] = useState(false)
    const [logo, setAppLogo] = useState("")
    const [disableOtp, setDisableOtp] = useState(true)
    const [loginType, setLoginType] = useState(null)


    const isEmployer = "";

    const [searchParams] = useSearchParams();
    const role = searchParams.get('Employer');
    const phone = searchParams.get('phone');
    console.log("role: ", role);
    console.log("role: ", phone);


    const isEmbedded = searchParams.get('src') === 'embeded';
    const getCourseID = searchParams.get('id');
    const getJobID = searchParams.get('jobID');
    const getApplicationType = searchParams.get('type')?.toString();
    const getItemUrl = searchParams.get('Url');

    useEffect(() => {
        setLoginType(getApplicationType)
    }, [getApplicationType])

    console.log('showEmpDashboard', showEmpDashboard);

    useEffect(() => {
        if (showEmpDashboard == true) {
            navigate('/emp-candidate-list')
        }
    }, [showEmpDashboard])




    // useEffect(() => {
    //     if (role) {
    //         // setDisableVerifyOtpButton(true)
    //         requestOtp({ mobileNumber: `91${phone}` })
    //         // navigate('/emp-auth');
    //     }
    //     if (newShowEmpRegistrationForm == true) {
    //         navigate('/emp-login' + '?phone=' + `91${phone}`)
    //     }

    // }, [newShowEmpRegistrationForm])

    useEffect(() => {
        if (otpRequestSuccess) {
            showAppToast({ toastMessage: 'OTP SENT', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
        }
    }, [otpRequestSuccess, showAppToast])

    useEffect(() => {
        if (otpRequestError) {
            showAppToast({ toastMessage: 'Error', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
        }
    }, [otpRequestError, showAppToast])

    useEffect(() => {
        console.log(otpValidateSuccess)
        if (otpValidateSuccess) {
            showAppToast({ toastMessage: 'OTP Verified', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
            setDisableVerifyOtpButton(true)
            // navigate(`/`);
            console.log('one')
        }
    }, [otpValidateSuccess, showAppToast])

    useEffect(() => {
        if (otpValidateError) {
            showAppToast({ toastMessage: 'Error', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
            setDisableVerifyOtpButton(false)
        }
    }, [otpValidateError, showAppToast])

    const handleUserNameValidation = (e) => {
        e.preventDefault()
        console.log('form submit data : ', mobileNumber);
        console.log('form submit data email : ', email);
        const role = 'Employer'
        // setSendOtpButton(true)
        console.log('requestOtp:', requestOtp); // Debug to check the function
        requestOtp({ mobileNumber: `91${mobileNumber}`, role: role, email: email })
        //  requestOtp({ mobileNumber: `91${mobileNumber}` })
        // console.log('response : ', response);
    }


    //testing function
    const demo = (e) => {
        e.preventDefault()

        const mobileNumber = '1234567890'; // Example mobile number

        console.log('requestOtp:', requestOtp); // Debug to check the function
        if (requestOtp) {
            requestOtp({ mobileNumber: `91${mobileNumber}` });
        } else {
            console.error('requestOtp function is undefined!');
        }
    };









    const HandleOtpValidation = (e) => {
        e.preventDefault()
        // setDisableVerifyOtpButton(true)
        const role = 'Employer'
        validateOtp({ mobileNumber: `91${mobileNumber}`, otp, role, email, emailOtp })
    }

    const [timer, setTimer] = useState(Date.now() + 59999)


    const reSendOtp = (e) => {
        e.preventDefault()
        axios.get(config.apiUrl + '/user/login?mobile=' + mobileNumber).then(res => {

            if (res.data.message === 'Success') {
                // setResMessage(res.data.data)
                setDisableOtp(true)
                showAppToast({ toastMessage: 'OTP Sent', background: 'success', timeout: '1000', icon: 'checkmark-circle-outline', position: 'toast-center' })
                setTimer(Date.now() + 59999)
            } else {
                showAppToast({ toastMessage: 'Something Went Wrong', background: 'danger', timeout: '1000', icon: 'close-circle-outline', position: 'toast-center' })
            }
        })
    }

    const handleBackToLogin = () => {
        backToLogin()
    }

    const Renderer = () => {
        setDisableOtp(false)
    }

    const countdownRenderer = ({ minutes, seconds }) => {
        return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>
    }

    useEffect(() => {
        setTimer(Date.now() + 59999)
    }, [showEmpVerifyOtpForm])

    const countDownTimer = useMemo(() => {
        return <Countdown
            key={timer}
            date={timer}
            onComplete={Renderer}
            renderer={countdownRenderer}
        />
    }, [timer])

    // axios.get(config.apiUrl + '/Ai/readWhitelabelJSON/its.me.in').then(res=>{
    //     console.log(res)
    //     if (res.data.message === 'Success') {
    //       setAppLogo(res.data.data.logo)
    //       localStorage.setItem('AppLogo', res.data.data.logo);
    //       console.log(logo)
    //     } else {
    //       showAppToast({ toastMessage:'Something Went Wrong', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
    //     }
    // })

    const [showEmailInput, setShowEmailInput] = useState(false)
    const [emailInput, setEmailInput] = useState(false)
    const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(false)

    const handleUserNameInput = (event) => {
        const value = event.target.value
        console.log(value)
        if (value) {
            if (isNaN(value)) {
                setShowEmailInput(true)
                setShowPhoneNumberInput(false)
                setEmailInput(value)
            } else {
                setMobileNumber(`${mobileNumberCC}${value}`)
                setShowEmailInput(false)
                setShowPhoneNumberInput(true)
            }
        } else {
            setShowEmailInput(false)
            setShowPhoneNumberInput(false)
            setEmailInput('')
            setMobileNumber('')
        }
    }

    useEffect(() => {
        if (setShowPhoneNumberInput && inputRef && mobileNumber.length <= 4) {
            inputRef?.current?.focus();
        }
    }, [setShowPhoneNumberInput, mobileNumber])

    useEffect(() => {
        if (showEmailInput && emailInputRef && emailInput.length <= 2) {
            emailInputRef?.current?.focus();
        }
    }, [emailInput, showEmailInput])

    const handlePhoneNumberInput = (event) => {
        const value = event.target.value
        setMobileNumber(value)
    }
    console.log(mobileNumber)

    const handleEmailInput = (event) => {
        const value = event.target.value
        if (value) {
            setEmailInput(value)
        } else {
            setShowEmailInput(false)
            setShowPhoneNumberInput(false)
            setEmailInput('')
            setMobileNumber('')
        }
    }

    const handleCountryChange = (event) => {
        const countryCode = getCountryCallingCode(event)
        setMobileNumberCC(`+${getCountryCallingCode(event)}`)
        if (countryCode !== '91') {
            setEmailInput('')
            setShowEmailInput(true)
        } else {
            setShowEmailInput(false)
            setEmailInput('')
        }
    }

    const [radioValue, setRadioValue] = useState('Fresher');
    // setXpLevel({ xpLevel: radioValue });
    const radios = [
        { icon: <FaUserGraduate />, name: ' Fresher', value: 'Fresher' },
        { icon: <FaBriefcase />, name: ' Experienced', value: 'Experienced' },
    ];

    const [GetUserData, setGetUserData] = useState({
        'username': '',
        'mobileNumber': '',
        'email': '',
        'role': 'Fresher'
    })
    const [GetEmpData, setGetEmpData] = useState({
        'empFirstName': '',
        'empLastName': '',
        'empCompanyName': '',
        'empCompanyDomain': '',
        'empLocation': '',
        'empDesignation': '',
        'email': '',
        'mobileNumber': '',
        'role': 'Employer'
    })
    console.log("emp data", GetEmpData);

    const setRegisteredData = () => {
        const fullName = `${GetEmpData.empFirstName} ${GetEmpData.empLastName}`;
        const newRegisteredData = {
            'username': fullName,
            'empLastName': GetEmpData.empLastName,
            'empCompanyName': GetEmpData.empCompanyName,
            'empCompanyDomain': GetEmpData.empCompanyDomain,
            'empLocation': GetEmpData.empLocation,
            'empDesignation': GetEmpData.empDesignation,
            'email': (GetEmpData.email) ? GetEmpData.email : email,
            'mobileNumber': `91${mobileNumber}`,
            'role': GetEmpData.role,
            'step': 4,
            'ID': ID
        }
        return newRegisteredData
    }

    const updateUserData = (e) => {
        const field = e.target.name;
        setGetUserData(prevState => ({
            ...prevState,
            [field]: e.target.value
        }));
    }
    const updateEmpData = (e) => {
        const field = e.target.name;
        setGetEmpData(prevState => ({
            ...prevState,
            [field]: e.target.value
        }));
    }

    const handleUserAdd = (e) => {
        e.preventDefault()
        // console.log(setRegisteredData()) 
        // setSendOtpButton(true)
        registerUser({ ...setRegisteredData() })
    }

    const setRole = (e) => {
        setRadioValue(e.currentTarget.value);
        updateUserData(e);
    }



    useEffect(() => {
        console.log(getApplicationType)
        // Retrieve ID from URL
        const retrievedSrc = isEmbedded;
        const retrievedCourseID = getCourseID;
        const retrievedJobID = getJobID;
        const retrievedType = getApplicationType;
        const retrievedUrl = getItemUrl;

        // Store ID in local storage only if it exists
        if (retrievedCourseID) return localStorage.setItem('certCourseID', retrievedCourseID);
        localStorage.setItem('certSRC', retrievedSrc);
        localStorage.setItem('jobId', retrievedJobID);
        localStorage.setItem('applicationType', retrievedType);
        localStorage.setItem('itemUrl', retrievedUrl);
        if (retrievedCourseID || retrievedJobID || retrievedSrc || retrievedType) {
            console.log(`Stored certCourseID in local storage: ${retrievedCourseID}`);
            console.log(`Stored src is: ${retrievedSrc}`);
            console.log(`Stored job ID is: ${retrievedJobID}`);
            console.log(`Stored application type is: ${retrievedType}`);
            console.log(`Stored item URL is: ${retrievedUrl}`);
        } else {
            console.log("No data found in URL");
        }
    }, [getItemUrl, getApplicationType, getCourseID, getJobID, isEmbedded]);

    const [isPrivacyChecked, setIsPrivacyChecked] = useState(true);
    const [isTermsChecked, setIsTermsChecked] = useState(true);
    const handleCheckboxChange = (setter) => () => {
        setter((prev) => !prev);
    };
    const isButtonDisabled = !isPrivacyChecked || !isTermsChecked;



    /////////////////////////////////// otp validation functions ////////////////////////////


    const [otp, setOtp] = useState(""); // OTP state
    const [emailOtp, setEmailOtp] = useState(""); // OTP state
    // const [email, setEmail] = useState(""); // Email state
    const [mobile, setMobile] = useState(""); // Mobile state



    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("OTP Submitted:", otp);
        console.log("Email:", email);
        console.log("Mobile:", `91${mobileNumber}`);

        // API Call to verify OTP
        try {
            const response = await axios.post("https://your-backend-api.com/verify-otp", {
                email: email,
                mobile: mobile,
                otp: otp,
            });

            console.log("API Response:", response.data);
            alert("OTP Verified Successfully!");
        } catch (error) {
            console.error("API Error:", error);
            alert("Failed to verify OTP.");
        }
    };

    console.log('newShowEmpRegistrationForm', newShowEmpRegistrationForm);

    useEffect(() => {
        console.log('GetEmpData', GetEmpData)
    }, [GetEmpData])

    return (
        <>
            {!showEmpVerifyOtpForm && <>
                {
                        newShowEmpRegistrationForm === true ?
                        (<>
                            {/* emp registration form start */}
                            <div className="mb-5" style={{ width: '80%', margin: 'auto' }}>
                                <section>
                                    <div className="d-flex justify-content-center pt-5">
                                        {/* #ECFDF5 */}
                                        <div className=" p-1 w-100" style={{ backgroundColor: '#ECFDF5', border: '1px solid #E5E5E5', borderRadius: '6px' }}>
                                            <div className="d-flex">
                                                <UserCheck className="mx-1" style={{ height: '20px', color: 'black' }} />
                                                <b><p className="mb-0">Heads up!</p></b>
                                            </div>
                                            <p className="ms-4" style={{ fontSize: '0.9rem' }}>Please complete your profile to start using Scopehai</p>
                                        </div>
                                    </div>
                                    <div className="mt-2 d-flex justify-content-center">
                                        <div className=" p-1 w-100" style={{ borderBottom: '3px solid  #E5E5E5 ' }}>
                                            <div className="d-flex">
                                                <b><h2 className="mb-0">Settings</h2></b>
                                            </div>
                                            <p style={{ fontSize: '1rem' }}>Manage your account settings and set e-mail references.</p>
                                        </div>
                                    </div>

                                    <div className="mt-2 d-flex justify-content-center">
                                        <div className="w-100">
                                            <Row className=" mt-2 ps-1">
                                                <Col md={3} sm={12} className="d-flex flex-column justify-content-start p-0 d-none">
                                                    <div className="d-flex justify-content-center" style={{ backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
                                                        <button className="my-2" style={{ textDecoration: 'none', border: 'none', background: 'none', fontWeight: 'bold' }}>Profile</button>
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <button className="my-2" style={{ color: '#334155', textDecoration: 'none', border: 'none', background: 'none', fontWeight: 'bold' }}>Account</button>
                                                    </div>
                                                    <div className="d-flex justify-content-center">
                                                        <button className="my-2" style={{ color: '#334155', textDecoration: 'none', border: 'none', background: 'none', fontWeight: 'bold' }}>Notifications</button>
                                                    </div>
                                                </Col>
                                                <Col md={12} sm={12} >
                                                    <form type="submit" onSubmit={handleUserAdd}>


                                                        <div className="">
                                                            <div className="d-flex justify-content-between  mt-1" style={{ borderBottom: '1px solid #E5E5E5' }}>
                                                                <div>
                                                                    <h2 className="mb-0">Profile</h2>
                                                                    <label>This is how your profile will be shown to candidates.</label>
                                                                </div>
                                                                {/* <button className="btn btn d-none d-md-block" style={{backgroundColor:'#358D77', color:'white'}}>Save Profile</button> */}
                                                            </div>
                                                            <div>
                                                                <div className=" mt-2 gap-2">
                                                                    <Row>
                                                                        <Col md={6} sm={12}>
                                                                            <div className="">
                                                                                <label class="text-nowrap">First name</label>
                                                                                <Form.Group className="form-group boxed" style={{}} >
                                                                                    <Form.Control
                                                                                        style={{ border: '1px solid #E5E5E5' }}
                                                                                        type="text"
                                                                                        placeholder="First name"
                                                                                        name="empFirstName"
                                                                                        value={GetEmpData?.empFirstName}
                                                                                        onChange={updateEmpData}
                                                                                        required />
                                                                                </Form.Group>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6} sm={12}>
                                                                            <div className="">
                                                                                <label class="text-nowrap">Last name</label>
                                                                                <Form.Group className="form-group boxed" style={{}} >
                                                                                    <Form.Control
                                                                                        style={{ border: '1px solid #E5E5E5' }}
                                                                                        type="text"
                                                                                        placeholder="Last name"
                                                                                        name="empLastName"
                                                                                        value={GetEmpData?.empLastName}
                                                                                        onChange={updateEmpData}
                                                                                        required />
                                                                                </Form.Group>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col md={6} sm={12}>

                                                                            <div className="">
                                                                                <label class="text-nowrap">Company name</label>
                                                                                <Form.Group className="form-group boxed" style={{}} >
                                                                                    <Form.Control
                                                                                        style={{ border: '1px solid #E5E5E5' }}
                                                                                        type="text"
                                                                                        placeholder="Company name"
                                                                                        name="empCompanyName"
                                                                                        value={GetEmpData?.empCompanyName}
                                                                                        onChange={updateEmpData}
                                                                                        required />
                                                                                </Form.Group>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={3} sm={12}>
                                                                            <div>
                                                                                <Form.Group className="boxed mb-2" >
                                                                                    <Form.Label for="xpLevel1" class="text-nowrap" style={{marginBottom: '10px'}}>Domain of company</Form.Label>
                                                                                    <InputGroup className="boxed position-relative">
                                                                                        <Form.Select

                                                                                            style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                                                            aria-label="Default select example"
                                                                                            id="xpLevel1"
                                                                                            name="empCompanyDomain"
                                                                                            value={GetEmpData?.empCompanyDomain}
                                                                                            required
                                                                                            onChange={updateEmpData}
                                                                                        >
                                                                                            <option value="IT/software">IT/software</option>
                                                                                            <option value="Online business">Online business</option>
                                                                                            <option value="Manufacturing">Manufacturing</option>
                                                                                            <option value="Finance">Finance</option>
                                                                                            <option value="Media and Advertisement">Media and Advertisement</option>
                                                                                            <option value="E-commerce">E-commerce</option>
                                                                                            <option value="Sales and marketing">Sales and marketing</option>
                                                                                            <option value="Consultancy services">Consultancy services</option>
                                                                                            <option value="Logistics">Logistics</option>
                                                                                            <option value="Real estate">Real estate</option>
                                                                                            <option value="Legal">Legal</option>
                                                                                            <option value="Hospitality">Hospitality</option>
                                                                                            <option value="Other">Other</option>
                                                                                        </Form.Select>
                                                                                    </InputGroup>
                                                                                </Form.Group>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={3} sm={12}>
                                                                            <div className="">
                                                                                <label class="text-nowrap">Location</label>
                                                                                <Form.Group className="form-group boxed" style={{}} >
                                                                                    <Form.Control
                                                                                        style={{ border: '1px solid #E5E5E5' }}
                                                                                        type="text"
                                                                                        placeholder="City/Town"
                                                                                        name="empLocation"
                                                                                        value={GetEmpData?.empLocation}
                                                                                        onChange={updateEmpData}
                                                                                        required />
                                                                                </Form.Group>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col md={6} sm={12}>
                                                                            <div>
                                                                                <Form.Group className="boxed mb-2" >
                                                                                    <Form.Label for="xpLevel1" class="text-nowrap" style={{marginBottom: '10px'}}>Your designation in the company</Form.Label>
                                                                                    <InputGroup className="boxed position-relative">
                                                                                        <Form.Select
                                                                                            style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                                                            aria-label="Default select example"
                                                                                            id="xpLevel1"
                                                                                            required
                                                                                            name="empDesignation"
                                                                                            value={GetEmpData?.empDesignation}
                                                                                            onChange={updateEmpData}
                                                                                        >
                                                                                            <option value="" disabled selected>Select designation</option>
                                                                                            <option value="HR personnel">HR personnel</option>
                                                                                            <option value="Management personnel">Management personnel</option>
                                                                                            <option value="Director">Director</option>
                                                                                            <option value="Other">Other</option>
                                                                                        </Form.Select>
                                                                                    </InputGroup>
                                                                                </Form.Group>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={12} sm={12} class="mt-1">
                                                                        <button className="btn btn w-100 d-block" style={{backgroundColor:'#358D77', color:'white', fontSize:'16px'}}>Save Profile</button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* emp reg complete modal */}
                            <Modal show={show} centered>
                                <Modal.Header closeButton style={{ border: 'none' }}>
                                    <Modal.Title>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="d-flex align-items-center flex-column">
                                        <img src={animation} style={{ height: '100px', width: '100px' }}></img>

                                        <h1>Congratulations!</h1>
                                        <label>You’re all set to start searching for the perfect candidates!</label>

                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="d-flex justify-content-center" style={{ border: 'none' }} >
                                    <button className="btn btn-success">Start Searching</button>
                                </Modal.Footer>
                            </Modal>

                            {/* emp registration from end */}
                        </>)
                        :
                        (<>
                            {/* login page new UI code start */}
                            <Row className="" style={{ "height": '100vh' }}>
                                <Col className="women-graph" style={{ 'backgroundColor': '#F5F5F5' }}>
                                    <div className="d-flex justify-content-center mt-4 mb-5">
                                        <img
                                            src={Logo}
                                            alt="Brand Logo"
                                            className="form-image"
                                            style={{ 'height': '4rem' }}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <img
                                            src={women_graph}
                                            className="form-image mt-5"
                                            style={{ 'height': '20rem', 'aspectRatio': 'auto' }}
                                        />
                                    </div>
                                    <div className="d-flex align-items-center flex-column">
                                        <h1 style={{ 'fontSize': '1.2rem' }}>Hire Better & Faster with Scopehai</h1>
                                        <p>Get pre-screened certified candidates for your company</p>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <lable className="chip ms-05 mb-05 m-2 p-2" style={{ 'backgroundColor': '#DBEAFE', 'color': '#2563EB' }} >Interns</lable>
                                        <lable className="chip ms-05 mb-05 m-2 p-2" style={{ 'backgroundColor': '#F3E8FF', 'color': '#9333EA' }} >Freshers</lable>
                                        <lable className="chip ms-05 mb-05 m-2 p-2" style={{ 'backgroundColor': '#DCFCE7', 'color': '#059669' }} >Experienced</lable>
                                        <lable className="chip ms-05 mb-05 m-2 p-2" style={{ 'backgroundColor': '#FEF3C7', 'color': '#D97706' }} >Freelancers</lable>
                                    </div>
                                </Col>
                                <Col>

                                    <div className="d-flex justify-content-end mt-4 pe-4">
                                        {loginType == 'register' &&
                                            <Link to={"/emp-login"} className="login-btn btn btn-link" style={{ 'fontSize': '1rem' }}>Login</Link>
                                        }
                                        {loginType != 'register' &&
                                            <Link to={"/emp-login?type=register"} className="login-btn btn btn-link" style={{ 'fontSize': '1rem' }}>Register</Link>
                                        }
                                    </div>
                                    <div className="mobile-logo-login-page d-flex justify-content-center mt-4 mb-5">
                                        <img
                                            src={Logo}
                                            alt="Brand Logo"
                                            className="form-image"
                                            style={{ 'height': '4rem' }}
                                        />
                                    </div>
                                    <div className="login-box d-flex flex-column justify-content-center align-items-center m-auto p-auto"
                                        style={{ 'width': '22rem', 'height': '80%' }}>
                                        <h2>{(loginType == 'register' ? 'Register' : 'Login')} as Employer</h2>
                                        {loginType == 'register' &&
                                            <label className="text-center mb-2" style={{ 'color': '#f5f5f5s' }}>Enter your company email & mobile below to create your account</label>
                                        }
                                        {loginType != 'register' &&
                                            <label className="text-center mb-2" style={{ 'color': '#f5f5f5s' }}>Enter mobile number to login</label>
                                        }
                                        <form className="w-100" onSubmit={handleUserNameValidation}>
                                            {loginType == 'register' &&
                                                <Form.Group className="form-group boxed w-auto" controlId="exampleForm.ControlInput1">
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Company Email"
                                                        id="email"
                                                        name="email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required />
                                                </Form.Group>
                                            }
                                            <Form.Group className="form-group boxed w-auto" controlId="exampleForm.ControlInput1">
                                                <Form.Control
                                                    className="form-control phoneInput mt-1"
                                                    international
                                                    type="number"
                                                    min="0"
                                                    maxLength="10"
                                                    placeholder="Mobile Number"
                                                    id="number"
                                                    name="number"
                                                    value={mobileNumber}
                                                    onChange={(e) => setMobileNumber(e.target.value)}
                                                    required />
                                            </Form.Group>
                                            <button type="submit" className="btn btn-primary w-100 mt-1" style={{ 'fontSize': '1rem' }} disabled={isButtonDisabled}>Request OTP</button>
                                        </form>
                                        <p className="mt-2 text-center" style={{ 'color': '#f5f5f5s' }}>By clicking continue, you agree to our <a href="#">Terms of Service</a> and <a>Privacy Policy.</a></p>
                                    </div>
                                </Col>
                            </Row >
                            {/* login page new UI code start */}
                        </>)
                }
            </>
            }
            {showEmpVerifyOtpForm && <>
                {/* OTP verification UI start */}
                < Row >
                    <div className="d-flex justify-content-center p-0" style={{ height: '100vh', backgroundColor: '#f5f5f5' }}>
                        <div className="mt-5 mx-1" style={{ height: '33rem', backgroundColor: 'white', width: '40rem', borderRadius: '1%' }}>
                            <div className="d-flex flex-column align-items-center mt-5">
                                <h1 style={{ fontSize: '2rem' }}>Enter verification code</h1>
                                {loginType != 'register' &&
                                    <p>We've sent code to your phone.</p>
                                }
                                {loginType == 'register' &&
                                    <p>We've sent codes to your email and phone.</p>
                                }
                            </div>
                            <form onSubmit={HandleOtpValidation}>
                                {loginType == 'register' &&
                                    <div className="p-3">
                                        <b><p style={{ color: 'black' }}>One-Time Password for Email</p></b>
                                        <OTPInput length={6} value={emailOtp} onChange={setEmailOtp} validateFor={'email'} />
                                        <p className="mt-1 mb-0" >Verification code sent to {email}</p>
                                        {verificationOtpMessage?.includes('Email') &&
                                            <p class="text-danger" style={{fontSize: '14px'}}>Incorrect Email OTP</p>
                                        }
                                    </div>
                                }
                                <div className={(loginType != 'register') ? "p-3" : "px-4"}>
                                    <b><p style={{ color: 'black' }}>One-Time Password for Phone</p></b>
                                    <OTPInput length={6} value={otp} onChange={setOtp} validateFor={'mobile'} />
                                    <p className="mt-1 mb-0" >Verification code sent to +91 {mobileNumber}</p>
                                    {verificationOtpMessage?.includes('Mobile') &&
                                        <p class="text-danger" style={{fontSize: '14px'}}>Incorrect Mobile OTP</p>
                                    }
                                </div>
                                

                                <div>
                                    <button className="btn btn-primary mt-2 ms-3">Verify</button>
                                </div>
                            </form>


                        </div>

                    </div>
                </Row >
                {/* OTP verification UI end */}
            </>
            }
            <CheckStepNumber></CheckStepNumber>

































































































            {/* login page new UI code start */}
            {/* <Row className="" style={{ "height": '100vh' }}>
                <Col className="women-graph" style={{ 'backgroundColor': '#F5F5F5' }}>

                    <div className="d-flex justify-content-center mt-4 mb-5">
                        <img
                            src={Logo}
                            alt="Brand Logo"
                            className="form-image"
                            style={{ 'height': '4rem' }}
                        />
                    </div>
                    <div className="d-flex justify-content-center">
                        <img
                            src={women_graph}
                            className="form-image mt-5"
                            style={{ 'height': '20rem', 'aspectRatio': 'auto' }}
                        />
                    </div>
                    <div className="d-flex align-items-center flex-column">
                        <h1 style={{ 'fontSize': '1.2rem' }}>Hire Better & Faster with Scopehai</h1>
                        <p>Get pre-screened certified candidates for your company</p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <lable className="chip ms-05 mb-05 m-2 p-2" style={{ 'backgroundColor': '#DBEAFE', 'color': '#2563EB' }} >Interns</lable>
                        <lable className="chip ms-05 mb-05 m-2 p-2" style={{ 'backgroundColor': '#F3E8FF', 'color': '#9333EA' }} >Freshers</lable>
                        <lable className="chip ms-05 mb-05 m-2 p-2" style={{ 'backgroundColor': '#DCFCE7', 'color': '#059669' }} >Experienced</lable>
                        <lable className="chip ms-05 mb-05 m-2 p-2" style={{ 'backgroundColor': '#FEF3C7', 'color': '#D97706' }} >Freelancers</lable>
                    </div>
                </Col>
                <Col>

                    <div className="d-flex justify-content-end mt-4 pe-4">

                        <button className="login-btn btn btn-link" style={{ 'fontSize': '1rem' }}>Login</button>
                    </div>
                    <div className="mobile-logo-login-page d-flex justify-content-center mt-4 mb-5">
                        <img
                            src={Logo}
                            alt="Brand Logo"
                            className="form-image"
                            style={{ 'height': '4rem' }}
                        />
                    </div>




                    <div className="login-box d-flex flex-column justify-content-center align-items-center m-auto p-auto"
                        style={{ 'width': '22rem', 'height': '80%' }}>
                        <h2>Register as Employer</h2>
                        <label className="text-center mb-2" style={{ 'color': '#f5f5f5s' }}>Enter your company email & mobile below to create your account</label>
                        <form className="w-100">
                            <Form.Group className="form-group boxed w-auto" controlId="exampleForm.ControlInput1">
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Your Name"
                                    id="name1"
                                    name="username"
                                    value={GetUserData?.username}
                                    onChange={updateUserData}
                                    required />
                            </Form.Group>
                            <Form.Group className="form-group boxed w-auto" controlId="exampleForm.ControlInput1">
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Your Name"
                                    id="name1"
                                    name="username"
                                    value={GetUserData?.username}
                                    onChange={updateUserData}
                                    required />
                            </Form.Group>
                            <button className="btn btn-primary w-100 mt-1" style={{ 'fontSize': '1rem' }} >Sign</button>
                        </form>
                        <p className="mt-2 text-center" style={{ 'color': '#f5f5f5s' }}>By clicking continue, you agree to our <a href="#">Terms of Service</a> and <a>Privacy Policy.</a></p>
                    </div>
                </Col>
            </Row > */}
            {/* login page new UI code start */}



            {/* OTP verification UI start */}
            {/* <Row>
                <div className="d-flex justify-content-center p-0" style={{ height: '100vh', backgroundColor: '#f5f5f5' }}>
                    <div className="mt-5 mx-1" style={{ height: '33rem', backgroundColor: 'white', width: '40rem', borderRadius: '1%' }}>
                        <div className="d-flex flex-column align-items-center mt-5">
                            <h1 style={{ fontSize: '2rem' }}>Enter verification code</h1>
                            <p>We've sent codes to your email and phone.</p>
                        </div>

                        <div className="p-3">
                            <b><p style={{ color: 'black' }}>One-Time Password for Phone</p></b>
                            <input className="ms-1" style={{ width: '2rem', backgroundColor: '#f5f5f5', border: '1px solid grey', borderRadius: '10px 2px 2px 10px' }} type="text" />
                            <input className="ms-1" style={{ width: '2rem', backgroundColor: '#f5f5f5', border: '1px solid grey', borderRadius: '2px 2px 2px 2px' }} />
                            <input className="ms-1" style={{ width: '2rem', backgroundColor: '#f5f5f5', border: '1px solid grey', borderRadius: '2px 2px 2px 2px' }} />
                            <input className="ms-1" style={{ width: '2rem', backgroundColor: '#f5f5f5', border: '1px solid grey', borderRadius: '2px 10px 10px 2px' }} />
                            <p className="mt-1" >Verification code sent to +91 9933 234 2342</p>

                        </div>
                        <div className="px-4">
                            <b><p style={{ color: 'black' }} >One-Time Password for Email</p></b>
                            <input className="ms-1" style={{ width: '2rem', backgroundColor: '#f5f5f5', border: '1px solid grey', borderRadius: '10px 2px 2px 10px' }} />
                            <input className="ms-1" style={{ width: '2rem', backgroundColor: '#f5f5f5', border: '1px solid grey', borderRadius: '2px 2px 2px 2px' }} />
                            <input className="ms-1" style={{ width: '2rem', backgroundColor: '#f5f5f5', border: '1px solid grey', borderRadius: '2px 2px 2px 2px' }} />
                            <input className="ms-1" style={{ width: '2rem', backgroundColor: '#f5f5f5', border: '1px solid grey', borderRadius: '2px 10px 10px 2px' }} />
                            <p className="mt-1" >Verification code sent to email@company.com</p>
                        </div>

                        <div>
                            <button className="btn btn-primary mt-2 ms-3">Submit</button>
                        </div>


                    </div>

                </div>
            </Row> */}
            {/* OTP verification UI end */}



            {/* emp registration form start */}
            {/* <div className="" style={{ width: '80%', margin: 'auto' }}>
                <section >
                    <div className="d-flex justify-content-center pt-5">
                        <div className=" p-1 w-100" style={{ backgroundColor: '#ECFDF5', border: '1px solid #E5E5E5', borderRadius: '6px' }}>
                            <div className="d-flex">
                                <UserCheck className="mx-1" style={{ height: '20px', color: 'black' }} />
                                <b><p className="mb-0">Heads up!</p></b>
                            </div>
                            <p className="ms-4" style={{ fontSize: '0.9rem' }}>Please complete your profile to start using Scopehai</p>
                        </div>
                    </div>
                    <div className="mt-2 d-flex justify-content-center">
                        <div className=" p-1 w-100" style={{ borderBottom: '3px solid  #E5E5E5 ' }}>
                            <div className="d-flex">
                                <b><h2 className="mb-0">Settings</h2></b>
                            </div>
                            <p style={{ fontSize: '1rem' }}>Manage your account settings and set e-mail references.</p>
                        </div>
                    </div>

                    <div className="mt-2 d-flex justify-content-center">
                        <div className="w-100">
                            <Row className=" mt-2 ps-1">
                                <Col md={3} sm={12} className="d-flex flex-column justify-content-start p-0">
                                    <div className="d-flex justify-content-center" style={{ backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
                                        <button className="my-2" style={{ textDecoration: 'none', border: 'none', background: 'none', fontWeight: 'bold' }}>Profile</button>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button className="my-2" style={{ color: '#334155', textDecoration: 'none', border: 'none', background: 'none', fontWeight: 'bold' }}>Account</button>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button className="my-2" style={{ color: '#334155', textDecoration: 'none', border: 'none', background: 'none', fontWeight: 'bold' }}>Notifications</button>
                                    </div>
                                </Col>
                                <Col md={9} sm={12} >
                                    <div className="">
                                        <div className="d-flex justify-content-between  mt-1" style={{ borderBottom: '1px solid #E5E5E5' }}>
                                            <div>
                                                <h2 className="mb-0">Profile</h2>
                                                <label>This is how your profile will be shown to candidates.</label>
                                            </div>
                                            <button className="btn btn-success">Save Profile</button>
                                        </div>
                                        <div>
                                            <div className=" mt-2 gap-2">
                                                <Row>
                                                    <Col md={6} sm={12}>
                                                        <div className="">
                                                            <label>First name</label>
                                                            <Form.Group className="form-group boxed" style={{}} >
                                                                <Form.Control
                                                                    style={{ border: '1px solid #E5E5E5' }}
                                                                    type="text"
                                                                    placeholder="First name"
                                                                    name="username"
                                                                    value={GetEmpData?.username}
                                                                    onChange={updateEmpData}
                                                                    required />
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} sm={12}>
                                                        <div className="">
                                                            <label>Last name</label>
                                                            <Form.Group className="form-group boxed" style={{}} >
                                                                <Form.Control
                                                                    style={{ border: '1px solid #E5E5E5' }}
                                                                    type="text"
                                                                    placeholder="Last name"
                                                                    name="username"
                                                                    value={GetEmpData?.username}
                                                                    onChange={updateEmpData}
                                                                    required />
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} sm={12}>

                                                        <div className="">
                                                            <label>Company name</label>
                                                            <Form.Group className="form-group boxed" style={{}} >
                                                                <Form.Control
                                                                    style={{ border: '1px solid #E5E5E5' }}
                                                                    type="text"
                                                                    placeholder="Company name"
                                                                    name="username"
                                                                    value={GetEmpData?.username}
                                                                    onChange={updateEmpData}
                                                                    required />
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={3} sm={12}>
                                                        <div>
                                                            <Form.Group className="boxed mb-2" >
                                                                <Form.Label for="xpLevel1">Domain of company</Form.Label>
                                                                <InputGroup className="boxed position-relative">
                                                                    <Form.Select

                                                                        style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                                        aria-label="Default select example"
                                                                        id="xpLevel1"
                                                                        value='{selectXp}'
                                                                        required
                                                                        onChange='{(e) => setSelectXp(e.target.value)}'
                                                                    >
                                                                        <option value="" disabled>Domain of company</option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="5">5</option>
                                                                    </Form.Select>
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={3} sm={12}>
                                                        <div className="">
                                                            <label>Location</label>
                                                            <Form.Group className="form-group boxed" style={{}} >
                                                                <Form.Control
                                                                    style={{ border: '1px solid #E5E5E5' }}
                                                                    type="text"
                                                                    placeholder="City/Town"
                                                                    name="username"
                                                                    value={GetEmpData?.username}
                                                                    onChange={updateEmpData}
                                                                    required />
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} sm={12}>
                                                        <div>
                                                            <Form.Group className="boxed mb-2" >
                                                                <Form.Label for="xpLevel1">Domain of company</Form.Label>
                                                                <InputGroup className="boxed position-relative">
                                                                    <Form.Select
                                                                        style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                                        aria-label="Default select example"
                                                                        id="xpLevel1"
                                                                        value='{selectXp}'
                                                                        required
                                                                        onChange='{(e) => setSelectXp(e.target.value)}'
                                                                    >
                                                                        <option value="" disabled>Domain of company</option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="5">5</option>
                                                                    </Form.Select>
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} sm={12}></Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </section>
            </div> */}

            {/* emp reg complete modal */}
            {/* <Modal show={show} centered>
                <Modal.Header closeButton style={{ border: 'none' }}>
                    <Modal.Title>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex align-items-center flex-column">
                        <img src={animation} style={{ height: '100px', width: '100px' }}></img>

                        <h1>Congratulations!</h1>
                        <label>You’re all set to start searching for the perfect candidates!</label>

                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center" style={{ border: 'none' }} >
                    <button className="btn btn-success">Start Searching</button>
                </Modal.Footer>
            </Modal> */}

            {/* emp registration from end */}























            {/* /////////////////////////// old login and reg code  */}
            {/* <><div className="login-form mt-1 d-none">
                {console.log("candidate reg")}
                {!localStorage.getItem('certCourseID') && (
                    <div className="section">
                        <img
                            src={Logo}
                            alt="Brand Logo"
                            className="form-image"
                        />
                    </div>
                )}
                <div className="section mt-1">
                    <h1>REGISTRATION</h1>
                </div>
                <div className="section mt-1 mb-5">
                    <Form onSubmit={handleUserAdd}>
                        <Form.Group className="form-group boxed" controlId="exampleForm.ControlInput1">
                            <Form.Control
                                type="text"
                                placeholder="Enter Your Name"
                                id="name1"
                                name="username"
                                value={GetUserData?.username}
                                onChange={updateUserData}
                                required />
                        </Form.Group>
                        <Form.Group className="form-group boxed">
                            <div className="input-wrapper">
                                {
                                    showPhoneNumberInput &&
                                    <PhoneInput
                                        className="form-control phoneInput mt-1"
                                        international
                                        defaultCountry="IN"
                                        value={`${mobileNumber}`}
                                        onChange={handlePhoneNumberInput}
                                        onCountryChange={handleCountryChange}
                                        ref={inputRef}
                                        maxLength={15}
                                        countryCallingCodeEditable={false}
                                    />
                                }
                                {
                                    !showPhoneNumberInput && !showEmailInput &&
                                    <input
                                        type="tel"
                                        className="form-control mt-1"
                                        id="number"
                                        placeholder="Enter Your Mobile Number"
                                        maxLength={10}
                                        required name="mobileNumber"
                                        onChange={handleUserNameInput}
                                    />
                                }
                                {
                                    showEmailInput && <input
                                        className="form-control mt-1"
                                        id="email"
                                        placeholder="Enter Email"
                                        required
                                        name="email"
                                        value={emailInput}
                                        onChange={handleEmailInput}
                                        ref={emailInputRef}
                                    />
                                }
                                <i className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </Form.Group>
                        
                        <div className="form-group boxed">
                            <ButtonGroup>
                                {radios.map((radio, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        id={`radio-${idx}`}
                                        type="radio"
                                        variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                                        name="role"
                                        value={radio.value}
                                        checked={radioValue === radio.value}
                                        onChange={(e) => setRole(e)}
                                        style={{ gap: '5px' }}
                                    >
                                        {radio.icon} {radio.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckPP" checked />
                            <label class="form-check-label" for="flexCheckPP">
                                I read & accept Privacy Policy
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckTC" checked />
                            <label class="form-check-label" for="flexCheckTC">
                                I read & accept Terms & Conditions
                            </label>
                        </div>
                        <div className="form-group boxed" style={{ paddingRight: '16px', paddingLeft: '16px' }}>
                            {hideSendOtpButton1
                                ? <button className="btn btn-primary btn-block btn-lg" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                    Authorizing...
                                </button>
                                : <button className="btn btn-primary btn-block btn-lg" type="submit">
                                    GET STARTED
                                </button>
                            }
                        </div>
                    </Form>
                </div>
            </div>
            </>
            :
            <div className="login-form mt-1">
                {console.log("candidate login")}
                <div className="section mt-1">
                    <h2 className="fill-primary">EMP LOGIN</h2>
                </div>
                {((!localStorage.getItem('certCourseID')) || (localStorage.getItem('jobId') !== null)) && (
                    <div className="section">
                       
                        <img
                            src={LoginImg}
                            alt="Login Img"
                            className="form-image"
                        />
                    </div>
                )}

                <div className="section mt-1 mb-5">
                    <form onSubmit={handleUserNameValidation}>

                        <div className="form-group boxed" style={{ paddingRight: '16px', paddingLeft: '16px' }}>
                            <div className="input-wrapper">
                                {
                                    showPhoneNumberInput &&
                                    <PhoneInput
                                        className="form-control phoneInput mt-1"
                                        international
                                        defaultCountry="IN"
                                        value={`${mobileNumber}`}
                                        onChange={handlePhoneNumberInput}
                                        onCountryChange={handleCountryChange}
                                        ref={inputRef}
                                        maxLength={15}
                                        countryCallingCodeEditable={false}
                                    />
                                }
                                {
                                    !showPhoneNumberInput && !showEmailInput &&
                                    <input
                                        type="tel"
                                        className="form-control mt-1"
                                        id="number"
                                        placeholder="Enter Mobile Number"
                                        maxLength={10}
                                        required name="mobileNumber"
                                        onChange={handleUserNameInput}
                                    />
                                }
                                {
                                    showEmailInput && <input
                                        className="form-control mt-1"
                                        id="email"
                                        placeholder="Enter Email"
                                        required
                                        name="email"
                                        value={emailInput}
                                        onChange={handleEmailInput}
                                        ref={emailInputRef}
                                    />
                                }
                                <i className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>

                        </div>

                        <div className="form-group boxed login-form" style={{ paddingRight: '16px', paddingLeft: '16px' }}>
                            {hideSendOtpButton
                                ? <button className="btn btn-primary btn-block btn-lg" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                                    Authorizing...
                                </button>
                                : <button
                                    className="btn btn-primary btn-block btn-lg"
                                    type="submit"
                                    disabled={isButtonDisabled}
                                >
                                    Send OTP
                                </button>
                            }
                        </div>
                        <div class="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckPP"
                                checked={isPrivacyChecked}
                                onChange={handleCheckboxChange(setIsPrivacyChecked)}
                            />
                            <label class="form-check-label" for="flexCheckPP">
                                I read & accept <a href="https://scopehai.com/privacy-policy" target="_blank">Privacy Policy</a>
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckTC"
                                checked={isTermsChecked}
                                onChange={handleCheckboxChange(setIsTermsChecked)}
                            />
                            <label class="form-check-label" for="flexCheckTC">
                                I read & accept <a href="https://scopehai.com/terms-conditions" target="_blank">Terms & Conditions</a>
                            </label>
                        </div>

                    </form>
                </div>
            </div> */}
        </>
    )
}

const mapStateToProps = ({ appToast, auth }) => {
    const {
        isVisible,
        toastMessage,
        background,
        icon,
        position
    } = appToast

    const {
        otpRequestSuccess,
        otpRequestError,
        hideSendOtpButton,
        hideSendOtpButton1,
        showEmpVerifyOtpForm,
        showRegistrationForm,
        otpValidateSuccess,
        otpValidateError,
        radioValue,
        registerCandidate,
        newShowEmpRegistrationForm,
        requestOtp,
        showEmpDashboard,
        ID,
        verificationOtpMessage
    } = auth

    return {
        appToastVisible: isVisible,
        appToastMessage: toastMessage,
        appToastBackground: background,
        appToastIcon: icon,
        appToastPosition: position,
        otpRequestSuccess,
        otpRequestError,
        hideSendOtpButton,
        hideSendOtpButton1,
        showEmpVerifyOtpForm,
        showRegistrationForm,
        otpValidateSuccess,
        otpValidateError,
        registerCandidate,
        newShowEmpRegistrationForm,
        requestOtp,
        showEmpDashboard,
        ID,
        verificationOtpMessage
    }
}

const mapDispatchToProps = {
    showAppToast,
    hideAppToast,
    requestOtp,
    validateOtp,
    registerUser,
    backToLogin,
    setCourseID,
    setXpLevel,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmpSignup)