import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import SampleBg from '../images/candidate-login/sample.svg';
import { fetchPlacementDrivesData, createJobApplication } from '../../features/constants/apis'
import { toast } from 'react-toastify';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Quotes from '../images/testDetails/quote.png';
import ReviewUser from '../images/testDetails/user1.jpg';
import Accordion from 'react-bootstrap/Accordion';

const DriveDetail = ({ ID, authToken }) => {
    // console.log("ID form store: ", ID, authToken);

    const [PDriveData, setPDriveData] = useState()
    const [disableButton, setDisableButton] = useState(false)
    const [btnLoder, setLoder] = useState(false)
    const [refreshData, setRefreshData] = useState(false);
    const [searchParams] = useSearchParams();
    const DriveID = searchParams.get('ID');
    console.log('placemnet drive ID:', DriveID);

    useEffect(() => {
        const getPlacemnetDriveById = async () => {
            const response = await fetchPlacementDrivesData(DriveID, authToken)
            console.log("response: ", response.data);
            console.log("status: ", response.data?.Status);
            setPDriveData(response?.data)
            if (response?.data?.Status === "Applied") {
                setDisableButton(true)
                console.log(" placemnet drive is applied already!");
            }
        }
        getPlacemnetDriveById();
    }, [refreshData])

    const handelApply = () => {
        console.log("handel applay working");
        setDisableButton('applying')
        const data = {
            'EmpID': PDriveData.EmployerID,
            'JobID': null,
            'JobTitle': null,
            'placementID': PDriveData.ID,
            'tokenUserID': ID
        }

        const drive = async () => {
            const driveData = await createJobApplication(data, authToken);
            if (driveData?.data === "Job appliation created successfully!") {
                toast.success('Apllied successfully!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                })
            }
        }
        drive()
        setRefreshData(prev => !prev);
    }


    return (
        <div id='appCapsule'>
            <div class="container">
                <div class="row mt-3 d-flex justify-content-center">
                    <div class="col-12 col-md-12">
                        <Link to="/" className="m-0 text-black fs-6 fw-bold d-flex">
                            <span class="d-flex align-items-center">
                                <ion-icon name="arrow-back-outline" class="me-1"></ion-icon>
                            </span>
                            Back to Placement Drive
                        </Link>
                        <div class="mt-3">
                            <div style={{ backgroundImage: `url(${SampleBg})`, backgroundPosition: 'center', backgroundSize: 'conver', height: '65vh', borderRadius: '20px' }}></div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 pt-5">
                        <div className='d-flex justify-content-between'>
                            <div>
                                <h1 class="fw-bold" style={{ fontSize: '32px' }}>{PDriveData?.Title}</h1>
                                <p class="text-secondary">{PDriveData?.Criteria}</p>
                            </div>
                            <div>
                                {disableButton !== true && disableButton !== 'applying' && (
                                    <button className='btn btn-primary' onClick={handelApply}>Apply</button>
                                )}

                                {disableButton === 'applying' && (
                                    <button className='btn btn-warning' disabled>Applying...</button>
                                )}

                                {PDriveData?.Status === 'Applied' && (
                                    <button className='btn btn-outlined' disabled>Apllied</button>
                                )}
                            </div>
                        </div>
                        <ul class="text-secondary">
                            <li>Lorem ipsum dolor sit amet consectetur. Volutpat amet diam mi ac enim. Tempor fusce vel amet augue cras massa nulla ut sit. Nulla bibendum rhoncus tincidunt tristique aliquet nunc. Nunc sit bibendum id cras velit.</li>
                            <li>Eu dui tellus augue feugiat nibh. Praesent fusce adipiscing egestas mauris bibendum vitae. A nunc purus enim diam. Tristique quis vel sapien in tristique. Pulvinar felis nunc viverra aliquam vulputate gravida massa.</li>
                            <li>Quam consectetur euismod eget neque sed tellus turpis adipiscing interdum. Condimentum nibh sed egestas eget lobortis massa.</li>
                            <li>Mauris orci fermentum at imperdiet. Semper commodo mauris sodales tellus pharetra. Rhoncus id mollis dis sit arcu ipsum at tempor ornare. Consequat feugiat in nibh vivamus aliquam mattis nisl donec nulla. Id volutpat diam duis sapien ut in etiam volutpat ornare.</li>
                        </ul>

                    </div>
                    <hr class="mt-2 mb-2" />
                    <div class="row mt-4">
                        <div class="col-12 col-md-4 mb-2">
                            <div class="card border-0" style={{ borderRadius: '10px', boxShadow: '0px 0px 10px #0000001F' }}>
                                <div class="card-body d-flex justify-content-between">
                                    <div style={{ borderLeft: '4px solid #FF924F' }}>
                                        <div class="ps-3">
                                            <h2 class="fw-bold">
                                                12,345
                                            </h2>
                                            <h6 class="mb-0" style={{ fontSize: '14px' }}>
                                                Total Test Taken
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="d-flex justify-content-center align-items-center p-1"
                                            style={{ backgroundColor: '#FF924F1A', borderRadius: '5px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="#FF924F" stroke-width="1"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="lucide lucide-users">
                                                <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                                                <circle cx="9" cy="7" r="4" />
                                                <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                                                <path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <div class="card border-0" style={{ borderRadius: '10px', boxShadow: '0px 0px 10px #0000001F' }}>
                                <div class="card-body d-flex justify-content-between">
                                    <div style={{ borderLeft: '3px solid #4B7BFF' }}>
                                        <div class="ps-3">
                                            <h2 class="fw-bold">
                                                10 K
                                            </h2>
                                            <h6 class="mb-0" style={{ fontSize: '14px' }}>
                                                Got Placements
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="d-flex justify-content-center align-items-center p-1"
                                            style={{ backgroundColor: '#4B7BFF1A', borderRadius: '5px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="#4B7BFF" stroke-width="1"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="lucide lucide-building-2">
                                                <path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z" />
                                                <path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2" />
                                                <path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2" />
                                                <path d="M10 6h4" />
                                                <path d="M10 10h4" />
                                                <path d="M10 14h4" />
                                                <path d="M10 18h4" /></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <div class="card border-0" style={{ borderRadius: '10px', boxShadow: '0px 0px 10px #0000001F' }}>
                                <div class="card-body d-flex justify-content-between">
                                    <div style={{ borderLeft: '3px solid #25BF5B' }}>
                                        <div class="ps-3">
                                            <h2 class="fw-bold">
                                                10.5 LPA
                                            </h2>
                                            <h6 class="mb-0" style={{ fontSize: '14px' }}>
                                                Average Package
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div class="d-flex justify-content-center align-items-center p-1"
                                            style={{ backgroundColor: '#25BF5B1A', borderRadius: '5px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="#25BF5B" stroke-width="1"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="lucide lucide-receipt-indian-rupee">
                                                <path
                                                    d="M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1Z" />
                                                <path d="M8 7h8" />
                                                <path d="M12 17.5 8 15h1a4 4 0 0 0 0-8" />
                                                <path d="M8 11h8" /></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-12">
                            <ul class="p-0 d-flex gap-2 mt-1" style={{ listStyleType: 'none', overflow: 'hidden' }}>
                                <li class="fs-6 text-nowrap text-black fw-bold" style={{ display: 'flex', alignItems: 'center' }}>
                                    Eligibility:</li>
                                <li>
                                    <span class="px-3 py-3 d-flex align-items-center text-nowrap text-black"
                                        style={{ backgroundColor: '#EBF4F1', fontSize: '15px', borderRadius: '8px', height: '25px' }}>Figma</span>
                                </li>
                                <li>
                                    <span class="px-3 py-3 d-flex align-items-center text-nowrap text-black"
                                        style={{ backgroundColor: '#EBF4F1', fontSize: '15px', borderRadius: '8px', height: '25px' }}>Miro</span>
                                </li>
                                <li>
                                    <span class="px-3 py-3 d-flex align-items-center text-nowrap text-black"
                                        style={{ backgroundColor: '#EBF4F1', fontSize: '15px', borderRadius: '8px', height: '25px' }}>Illustrator</span>
                                </li>
                                <li>
                                    <span class="px-3 py-3 d-flex align-items-center text-nowrap text-black"
                                        style={{ backgroundColor: '#EBF4F1', fontSize: '15px', borderRadius: '8px', height: '25px', textOverflow: 'ellipsis' }}>Adobe
                                        Photoshop</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <hr class="mt-2 mb-2" />
                    <div class="col-12 col-md-12 mt-2">
                        <h1 class="fw-bold" style={{ fontSize: '32px' }}>What include in this test ?</h1>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <ul class="p-0" style={{ listStyleType: 'none' }}>
                                    <li class="mt-3">
                                        <div class="d-flex align-items-center">
                                            <div class="d-flex justify-content-center align-items-center me-2"
                                                style={{ height: '35px', width: '35px', backgroundColor: '#EBF4F1', borderRadius: '10px' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                    viewBox="0 0 24 24" fill="none" stroke="#25BF5B"
                                                    stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                                                    class="lucide lucide-users">
                                                    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                                                    <circle cx="9" cy="7" r="4" />
                                                    <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                                                    <path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                                            </div>
                                            <p class="mb-0 text-black" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet consectetur. Consequat aenean
                                                est. </p>
                                        </div>
                                    </li>
                                    <li class="mt-3">
                                        <div class="d-flex align-items-center">
                                            <div class="d-flex justify-content-center align-items-center me-2"
                                                style={{ height: '35px', width: '35px', backgroundColor: '#EBF4F1', borderRadius: '10px' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                    viewBox="0 0 24 24" fill="none" stroke="#25BF5B"
                                                    stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                                                    class="lucide lucide-users">
                                                    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                                                    <circle cx="9" cy="7" r="4" />
                                                    <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                                                    <path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                                            </div>
                                            <p class="mb-0 text-black" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet consectetur tortor posuere
                                                nunc.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12 col-md-6">
                                <ul class="p-0" style={{ listStyleType: 'none' }}>
                                    <li class="mt-3">
                                        <div class="d-flex align-items-center">
                                            <div class="d-flex justify-content-center align-items-center me-2"
                                                style={{ height: '35px', width: '35px', backgroundColor: '#EBF4F1', borderRadius: '10px' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                    viewBox="0 0 24 24" fill="none" stroke="#25BF5B"
                                                    stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                                                    class="lucide lucide-users">
                                                    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                                                    <circle cx="9" cy="7" r="4" />
                                                    <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                                                    <path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                                            </div>
                                            <p class="mb-0 text-black" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet consectetur.</p>
                                        </div>
                                    </li>
                                    <li class="mt-3">
                                        <div class="d-flex align-items-center">
                                            <div class="d-flex justify-content-center align-items-center me-2"
                                                style={{ height: '35px', width: '35px', backgroundColor: '#EBF4F1', borderRadius: '10px' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                    viewBox="0 0 24 24" fill="none" stroke="#25BF5B"
                                                    stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                                                    class="lucide lucide-users">
                                                    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                                                    <circle cx="9" cy="7" r="4" />
                                                    <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                                                    <path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                                            </div>
                                            <p class="mb-0 text-black" style={{ fontSize: '16px' }}>Lorem ipsum dolor sit amet consectetur. Augue sem augue
                                                con.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-2 mb-2" />
                    <div class="col-12 col-md-12 mt-2">
                        <h1 class="fw-bold" style={{ fontSize: '32px' }}>Requirements</h1>
                        <ul class="text-secondary">
                            <li>Lorem ipsum dolor sit amet consectetur. Diam nullam urna arcu sollicitudin mauris
                                erat pretium elementum interdum. Nec sed nisl malesuada nulla .</li>
                            <li>At non elit turpis erat eros. Mattis imperdiet quam varius dolor accumsan tortor
                                lectus. Libero in augue sem fames nisl venenatis diam est. Nisi malesuada lectus
                                augue ac duis a tempus.</li>
                            <li>Viverra morbi ut amet fringilla elit augue lobortis fames. Libero semper quam vitae
                                massa facilisi. Quam eget non convallis placerat consectetur feugiat.</li>
                            <li>Eu aliquam duis tortor ac ante. Dolor amet ullamcorper eget vitae ut augue feugiat
                                blandit id. Sed habitant at justo adipiscing ultrices velit.</li>
                            <li>Augue ut purus varius urna imperdiet volutpat commodo scelerisque. Eget amet sit
                                volutpat elit ullamcorper. Amet nullam facilisis habitant pellentesque.</li>
                            <li>Morbi venenatis faucibus ipsum morbi magna aliquam nisl quam semper. Viverra
                                adipiscing accumsan dignissim suspendisse aliquet libero.</li>
                        </ul>
                    </div>
                    <hr class="mt-2 mb-2" />
                    <div class="col-12 col-md-12 mt-2 pb-5">
                        <h1 class="fw-bold" style={{ fontSize: '32px' }}>Reviews</h1>
                        <Splide options={ { rewind: true,
                            type: "loop",
                            perPage: 3,
                            perMove: 1,
                            arrows: false,
                            breakpoints: {
                                768: {
                                    perPage: 1, // Show only 1 slide per page on mobile screens
                                },
                            },
                        }}
                        aria-label="React Splide Example">
                            {[1,2,3,4,5,6,7,8,9].map((v,k) => {
                                return (
                                    <SplideSlide>
                                        <div class="card border-0"
                                            style={{boxShadow: '0px 0px 10px #0000001F', borderRadius: '5px'}}>
                                            <div class="card-body">
                                                <img src={Quotes} style={{width: '10%'}} />
                                                <p class="text-secondary pt-2" style={{fontSize: '12px', lineHeight: 'inherit'}}>
                                                    Lörem ipsum nisin ultralöskade fösa sor hypofähödat. Heterotism ålasosade
                                                    gibeck: hånin lysa. Dende fäsk. Doment krogisk, sabåligen. Deciktig pantik.
                                                </p>
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex flex-column">
                                                        <h6 class="mb-0 fw-bold" style={{fontSize: '12px'}}>Jenny Wilson</h6>
                                                        <p style={{fontSize: '10px'}} class="mb-0 text-secondary">PHP Developer
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{height: '35px', width: '35px', backgroundImage: `url(${ReviewUser})`, backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: '100%'}}>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SplideSlide>
                                )
                            })}
                        </Splide>
                    </div>
                    <hr class="mt-2 mb-2" />
                    <div class="col-12 col-md-12 mt-2">
                        <h1 class="fw-bold" style={{ fontSize: '32px' }}>Frequently Asked Questions</h1>
                        <div>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Lorem ipsum dolor sit amet, consectetur ?</Accordion.Header>
                                    <Accordion.Body>
                                    <p style={{fontSize: '14px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor pretium diam vel nulla tellus lorem sollicitudin fermentum ultricies. Aenean a consectetur mi libero. Accumsan, ultrices nunc orci ac amet volutpat. Morbi semper erat ullamcorper adipiscing nisi pellentesque.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Lorem ipsum dolor sit amet consectetur. Eu tortor molestie sed consequat tempus com.</Accordion.Header>
                                    <Accordion.Body>
                                    <p style={{fontSize: '14px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Lorem ipsum dolor sit amet consectetur. Aliquam ac consectetur vitae laoreet ut.</Accordion.Header>
                                    <Accordion.Body>
                                    <p style={{fontSize: '14px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Lorem ipsum dolor sit amet consectetur. Quam aliquet pellentesque donec cursus neque.</Accordion.Header>
                                    <Accordion.Body>
                                    <p style={{fontSize: '14px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Lorem ipsum dolor sit amet consectetur. Ultrices curabitur nunc elementum libero cursus pretium felis.</Accordion.Header>
                                    <Accordion.Body>
                                    <p style={{fontSize: '14px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ auth }) => {
    const { ID, authToken } = auth
    return {
        ID,
        authToken
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DriveDetail);