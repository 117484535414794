import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
  Container,
  Card,
  Button,
  Form,
  InputGroup,
  Modal,
  Image,
  Row,
  Col
} from 'react-bootstrap'
import Select from 'react-select'
import {
  generalSkillOptions,
  professionalSkillOptions
} from '../../features/constants/skillsData'
import { Navigate, useLocation, useNavigate } from 'react-router'
import logoPhoto from '../images/Favicon_large-removebg-preview.png'
import userPhoto from '../images/profile-placeholder.jpeg'
import {
  GetAllApplicationsOfDrive,
  GetCandidateByID,
  UpdateUserProfile,
  deleteCandidateEducationRecord,
  deleteCandidateExperienceRecord,
  fetchAllJobRoles,
  fetchAllJobRolesSkills,
  getRemovedSkills,
  handelOpenToWork,
  postUserImage,
  shortlistApplicant,
  UserJoinStatus,
  GetAllVspApplied
} from '../../features/constants/apis'
import { FaArrowLeft, FaPen, FaPlus } from 'react-icons/fa'
import axios from '../../AxiosConfig'
import { RiDeleteBin6Line, RiUserSmileFill } from 'react-icons/ri'
import tick from '../images/icon/tick-image.png'
import tickOutlined from '../images/tick-outlined.png'
import gayTick from '../images/icon/tick-image-gray.png'
import { Link } from 'react-router-dom'
import GetVspModal from './Modals/GetVspModal'
import ProgressBar from 'react-bootstrap/ProgressBar'
import TextAnimation from './MacroComponents/TextAnimation'
import { colors } from '@mui/material'
import ScopehaiLogo from '../images/scopehai-favicon.png'
import StarIcon from '../images/star.png'
import LogoMark from '../images/LogoMark.svg'
import VerifiedBlueLogoMark from '../images/VerifiedBlueLogoMark.svg'
import NotVerifiedGreyLogoMark from '../images/NotVerifiedGreyLogoMark.svg'
import {
  Linkedin,
  Mail,
  Phone,
  Cake,
  BadgeIndianRupee,
  MapPin,
  Mouse,
  File,
  Download,
  Star,
  EllipsisVertical,
  Bike,
  Laptop,
  BadgeCheck
} from 'lucide-react'
import { BsTelephone, BsTelephonePlus } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';

const CandidateProfileReadOnly = ({
  fullName,
  role,
  authToken,
  ID,
  candidateList
}) => {
  console.log('candidate List in profile comp: ', candidateList)

  const [showLoader, setShowLoader] = useState(false)
  const [data, setData] = useState()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [vspData, setVspData] = useState()
  const [openToJoin, setOpenToJoin] = useState(Boolean)
  const [openToSwitch, setOpenToSwitch] = useState(Boolean)
  const [expectedCTC, setExpectedCTC] = useState('')
  const [currentCTC, setCurrentCTC] = useState('')
  const [userCurrentStatus, setUserCurrentStatus] = useState('')
  const [cvUploaded, setCvUploaded] = useState(false) // State variable to track CV upload
  const [downloadCVUrl, setDownloadCVUrl] = useState('')
  const [uploadedImage, setUploadedImage] = useState('')
  const [fileUploaded, setFileUploaded] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)
  const [imageSet, setImageSet] = useState(false)
  const fileInputRef = useRef(null)

  const [userInfo, setUserInfo] = useState([])
  const [isEditingProfile, setIsEditingProfile] = useState(false)

  const [userDomain, setUserDomain] = useState('')

  const [userEduction, setUserEducation] = useState([])
  const [userExperience, setUserExperience] = useState([])

  const [jobRoles, setJobRoles] = useState([])

  // VSP  modal
  const [showGetVspModal, setShowGetVspModal] = useState(false)
  const [vspModalShow, setVspModalShow] = useState(false)

  // Add new Education modal
  const [eduModalShow, setEduModalShow] = useState(false)
  const [expModalShow, setExpModalShow] = useState(false)

  // Delete modals
  const [modalShow, setModalShow] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [eduIdToDeleted, setEduIdToDeleted] = useState(null)
  const [expIdToDeleted, setExpIdToDeleted] = useState(null)
  const [modalTitle, setModalTitle] = useState('')
  const [modalMessage, setModalMessage] = useState('')

  // Update Education Modal
  const [updateEduModalShow, setUpdateEduModalShow] = useState(false)
  const [editabelEducation, setEditableEducation] = useState([])

  // Update Experience Modal
  const [updateExpModalShow, setUpdateExpModalShow] = useState(false)
  const [editableExperience, setEditableExperience] = useState([])

  const [disablePreviousButton, setDisablePreviousButton] = useState(false)
  const [disableNextButton, setDisableNextButton] = useState(false)

  const stateUrl = useLocation()
  const searchParams = new URLSearchParams(stateUrl.search)
  const candidateID = searchParams.get('candidateID')
  // const pageIndex = searchParams.get('pageIndex')
  const jobID = searchParams.get('job')
  // console.log('ID', typeof candidateID)
  const candidate_ID = candidateID

  // http://localhost:3001/candidate-profile-ro?candidateID=13&Experience=true&location=true&skills=true&year=true&salary=true&immediate=true
  const sortBy = searchParams.get('sortBy')
  const pageSize = searchParams.get('pageSize')
  const pageIndex = searchParams.get('pageIndex')
  const year = searchParams.get('year')
  const vsp = searchParams.get('isVsp')
  const salary = searchParams.get('salary')
  const immediate = searchParams.get('immediate')

  const params = {
    candidateID: candidateID,
    Experience: year,
    vsp: vsp,
    salary: salary,
    immediate: immediate
  }
  // console.log('params: ', params)

  const [cID, setCID] = useState()
  // setCID(candidate_ID);

  // Edit Bio
  const [editableUserFullName, setEditableUserFullName] = useState('')
  const [editableUserDomain, setEditableUserDomain] = useState('')
  const [editableDescription, setEditableDescription] = useState('')
  const [editableGenSkills, setEditableGenSkills] = useState([])
  const [editableProSkills, setEditableProSkills] = useState([])

  // shortlist candidate index
  const [currentApplicantIndex, setCurrentApplicantIndex] = useState(0)

  // job applicants
  const [jobApplicants, setJobApplicants] = useState([])
  const [perticularApplication, setPerticularApplication] = useState([])

  // removed skills
  const [removedGeneralSkills, setRemovedGeneralSkills] = useState([])
  const [removedProfessinalSkills, setRemovedProfessinalSkills] = useState([])

  // progressbar
  const [profileProgressCount, setProfileProgressCount] = useState(0)

  // skills lis
  const [generalSkillsList, setGeneralSkillsList] = useState([])
  const [proSkillsList, setProSkillsList] = useState([])
  const [genSkillByHr, setGenSkillByHr] = useState([])
  const [proSkillByHr, setProSkillByHr] = useState([])
  const [hrComments, setHrComments] = useState()
  const [suitableJobs, setSuitableJobs] = useState([])
  const [userVerified, setUserVerified] = useState(false)
  const [urlPath, setUrlPath] = useState()

  useEffect(() => {
    const UserData = async () => {
      const data = await GetCandidateByID(candidateID, authToken)
      console.log('DATA: ', data.data)
      setUserInfo(data?.data)
      setUserEducation(data.data?.Education)
      setUserExperience(data.data?.Experience)

      setCurrentCTC(data.data?.CareerProfile.expectedCtc)
      setExpectedCTC(data.data?.CareerProfile.incomeCTC)
      if (data.data.JoiningStatus === 'join') {
        setOpenToJoin(true)
        setOpenToSwitch(false)
      } else if (data.data.JoiningStatus === 'switch') {
        setOpenToJoin(false)
        setOpenToSwitch(true)
      } else {
        setOpenToJoin(false)
        setOpenToSwitch(false)
      }
      // console.log('incoming user data: ', data.data)
      setGenSkillByHr(data.data?.HrComments?.generalSkillsByHr)
      setProSkillByHr(data.data?.HrComments?.professionalSkillsByHr)
      setHrComments(data.data)
      const selectedLanguage = data.data?.HrComments?.language

      if (data.data.JoiningStatus === 'join') {
        setOpenToJoin(data.data.JoiningStatus)
        setOpenToSwitch(false)
      } else if (data.data.JoiningStatus === 'switch') {
        setOpenToJoin(false)
        setOpenToSwitch(data.data?.JoiningStatus)
      } else {
        setOpenToJoin(false)
        setOpenToSwitch(false)
      }

      if (data.data?.HrComments) {
        setUserVerified(true)
      } else {
        setUserVerified(false)
      }
    }
    // };
    UserData()

    const fetchData = async () => {
      try {
        const response = await GetAllVspApplied(authToken)
        setVspData(response.data) // Set the users data from the response
        // console.log('vsp data: ', response.data)
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    }
    fetchData()
  }, [ID, authToken, candidateID, role, cvUploaded, currentIndex])

  // console.log("User Verification status:", userVerified);
  // console.log(
  //   'HR comments ',
  //   hrComments?.HrComments?.language?.map(lan => ({ value: lan, label: lan }))
  // )

  // console.log('User Verification status:', vspData)

  const getVspId = vspData?.some(data => data?.UserId == candidateID)

  // console.log('ID from vsp :', getVspId)

  // jobRole
  useEffect(() => {
    const fetchJobRoles = async () => {
      const data = await fetchAllJobRoles(authToken)
      setJobRoles(data.data)
      // console.log('JobRoles:', data.data)
    }
    fetchJobRoles()
  }, [authToken])

  useEffect(() => {
    if (!userInfo?.CareerProfile?.domain || !jobRoles.length) return
    const matchingDomain = jobRoles.find(
      jobRole => jobRole?.ID == userInfo.CareerProfile.domain
    )?.Domain
    if (matchingDomain) {
      setSuitableJobs(
        jobRoles
          .filter(jobRole => jobRole?.Domain === matchingDomain)
          .map(jobRole => jobRole.Title)
          .filter(Boolean)
          .slice(0, 10)
      )
    }
  }, [jobRoles, userInfo])
  // console.log('Suitable Jobs:', suitableJobs)

  useEffect(() => {
    // console.log('suitable job')
    if (userInfo?.CareerProfile && jobRoles?.length > 0) {
      const domainValue = userInfo?.CareerProfile?.domain
      const matchingJobRoles = jobRoles.find(
        jobRole => jobRole.ID == domainValue
      )
      // setUserDomain(matchingJobRoles.Title)
      // console.log('Suitable Jobs:', matchingJobRoles)
    }
  }, [userInfo.CareerProfile, jobRoles])

  // skills from job roles
  const [jobRolesSkills, setJobRolesSkills] = useState({
    GeneralSkills: [],
    ProfessionalSkills: []
  })
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAllJobRolesSkills(authToken)
      setJobRolesSkills(data.data)
    }
    fetchData()
  }, [authToken])

  useEffect(() => {
    if (jobRolesSkills) {
      const generalSkillOptions = jobRolesSkills?.GeneralSkills.map(skill => ({
        value: skill,
        label: skill
      }))

      const professionalSkillOptions = jobRolesSkills?.ProfessionalSkills.map(
        skill => ({
          value: skill,
          label: skill
        })
      )
      setGeneralSkillsList(generalSkillOptions)
      setProSkillsList(professionalSkillOptions)
    }
  }, [jobRolesSkills])

  const refetchUserData = async () => {
    const data = await GetCandidateByID(ID, authToken)
    setUserEducation(data?.data?.Education)
    setUserExperience(data?.data?.Experience)
  }

  const fetchUserData = async () => {
    const data = await GetCandidateByID(ID, authToken)
    if (role === 'Candidate') {
      setUserInfo(data.data)
      setUserEducation(data.data?.Education)
      setUserExperience(data.data?.Experience)
    }
  }

  const handleDelete = async () => {
    if (itemToDelete === 'education') {
      await deleteCandidateEducationRecord(authToken, ID, eduIdToDeleted)
    } else if (itemToDelete === 'experience') {
      await deleteCandidateExperienceRecord(authToken, ID, expIdToDeleted)
    }

    setModalShow(false)
    fetchUserData()
  }

  const handleCloseGetVspModal = () => {
    setShowGetVspModal(false)
  }

  // VSP MODAL
  // const handleShowGetVspModal = () => {
  //     setUpdateEduModalShow(true)
  // }

  // Edit Education
  const handleShowEduUpdateModal = education => {
    setUpdateEduModalShow(true)
  }

  // Edit Experience
  const handleShowExpUpdateModal = experience => {
    setUpdateExpModalShow(true)
    setEditableExperience(experience)
  }

  // candidates
  useEffect(() => {
    const fetchPDApplications = async () => {
      // console.log('fetchApplications')
      const data = await GetAllApplicationsOfDrive(authToken)
      setJobApplicants(
        data.filter(application => application?.EmployerID === ID)
      )

      //get perticular application
      const filteredPerticularApplication = data.filter(
        application =>
          application?.JobID === parseInt(jobID) &&
          application?.CandidateID === parseInt(candidateID) &&
          application?.EmployerID === ID
      )

      setPerticularApplication(filteredPerticularApplication)
    }
    fetchPDApplications()
  }, [authToken, ID, candidateID, jobID])

  const navigate = useNavigate()

  // shortlist and next
  const handlleShortlistAndNext = async () => {
    setCurrentApplicantIndex(prevIndex => {
      const newIndex = prevIndex + 1
      if (newIndex < jobApplicants.length) {
        const nextCandidateID = jobApplicants[newIndex].CandidateID
        const nextJobID = jobApplicants[newIndex].JobID
        navigate(
          `/candidate-profile?candidate=${nextCandidateID}&job=${nextJobID}`
        )
      } else {
        setCurrentApplicantIndex(0)
      }
      return newIndex
    })
    await shortlistApplicant(authToken, jobID, candidateID)
  }
  const viewNextApplicant = async () => {
    setCurrentApplicantIndex(prevIndex => {
      const newIndex = prevIndex + 1
      if (newIndex < jobApplicants.length) {
        const nextCandidateID = jobApplicants[newIndex].CandidateID
        const nextJobID = jobApplicants[newIndex].JobID
        navigate(
          `/candidate-profile?candidate=${nextCandidateID}&job=${nextJobID}`
        )
      } else {
        setCurrentApplicantIndex(0)
      }
      return newIndex
    })
  }
  const messages = [
    'Use your full name for a professional profile.',
    'Showcase skills to stand out.',
    'Upload a photo to make a strong impression.',
    'A detailed CV boosts your chances.',
    'Add education to show qualifications.'
  ]

  ///////////////////////////////////////////////////// radio toggle
  const handleOpenToJoin = event => {
    const newValue = event.target.checked
    setOpenToSwitch(false)
    setOpenToJoin(newValue)
  }
  const handleOpenToSwitch = event => {
    const newValue = event.target.checked
    setOpenToJoin(false)
    setOpenToSwitch(newValue)
  }
  // Handlers for input changes
  const handleExpectedCTCChange = e => setExpectedCTC(e.target.value)
  const handleCurrentCTCChange = e => setCurrentCTC(e.target.value)
  const setUserJoinStatus = () => {
    const newRegisteredData = {
      degree: userInfo?.CareerProfile?.degree,
      domain: userInfo.CareerProfile?.domain,
      education: userInfo.CareerProfile?.education,
      experience: userInfo.CareerProfile?.experience,
      incomeCTC: currentCTC || userInfo.CareerProfile?.incomeCTC || null,
      expectedCtc: expectedCTC
    }
    return newRegisteredData
  }
  let userID = ID
  const [validated, setValidated] = useState(false)

  // if (hrComments === null) {
  //   console.log('hr comments are null')
  // } else {
  //   console.log('hr comments is not null')
  // }

  useEffect(() => {
    if (candidateList?.items.length > 0) {
      const userId = candidateList?.items[currentIndex]?.ID
      // console.log(candidateList?.items[currentIndex]?.ID)
      setCID(userId)
    }
  }, [currentIndex])

  useEffect(() => {
    if (stateUrl.pathname) {
      setUrlPath(stateUrl.pathname)
    }
  }, [stateUrl.pathname])

  // const fetchData = React.useCallback(async () => {
  //     setShowLoader(true)
  //     const isVsp = vsp;
  //     document.body.classList.add('spinner');

  //     const response = await axios.get(`/user/datafilter`, { params: { term, sortBy: sortBy.length > 0 ? sortBy : [{ id: 'ID', desc: false }], pageSize, pageIndex, value, vsp, year, salary, immediate } });
  //     // http://localhost:4444/api/user/datafilter?page=1&pageSize=5&sortOrder=ASC&jstatus=join
  //     // https://beta.scopehai.com/api/user/datafilter
  //     console.log('incoming responses :', response)
  //     setTimeout(() => {
  //         if (response.status) {
  //             setShowLoader(false)
  //         }
  //         const { items, pageCount: pCount } = response.data;
  //         setData(items);
  //         setPageCount(pCount);
  //         sendDataToParent(response?.data?.totalCount);
  //         document.body.classList.remove('spinner');
  //     }, 1000);
  // }, []);
  // console.log('page count: ');

  // Handlers for Next and Previous buttons
  const handleCandidateScroll = async sequenceType => {
    setShowLoader(true)
    const term = searchParams.get("term");
    const searchTitle = searchParams.get("searchTitle");
    const sortBy = searchParams.get("sortBy[]");
    const pageSize = searchParams.get("pageSize");
    const pageIndex = searchParams.get("pageIndex");
    const isVsp = searchParams.get("isVsp");
    const year = searchParams.get("year");
    const salary = searchParams.get("salary");
    const maxCtc = searchParams.get("maxCtc");
    const location = searchParams.get("location");
    const immediate = searchParams.get("immediate");
    const fullTime = searchParams.get("fullTime");
    const partTime = searchParams.get("partTime");
    const internship = searchParams.get("internship");
    const remote = searchParams.get("remote");
    const onSite = searchParams.get("onSite");
    const expStatus = searchParams.get("expStatus");
    // const isVsp = vsp
    // const term = null
    document.body.classList.add('spinner')
    // console.log('next btn before api')
    let data
    if (stateUrl.pathname.includes('candidate-profile-ro')) {
      console.log('FROM SEARCH CANDIDATES')
      data = await axios.get(`/user/datafilter`, {
        params: {
          term,
          sortBy: sortBy?.length > 0 ? sortBy : [{ id: 'ID', desc: false }],
          pageSize,
          pageIndex,
          isVsp,
          year,
          salary,
          immediate,
          candidateID,
          step: sequenceType,
          partTime,
          fullTime,
          internship,
          remote,
          onSite,
          expStatus,
          searchTitle,
          location,
          maxCtc
        }
      })
      console.log('SEARCH CANDIDATE NEXT PREV', data)
      
      if (data.data.error) {
        if (data.data.error.includes('previous')) {
          setDisablePreviousButton(true)
          setDisableNextButton(false)
        } else if (data.data.error.includes('next')) {
          setDisablePreviousButton(false)
          setDisableNextButton(true)
        }
      } else {
        // navigate(
        //   `/candidate-profile-ro?candidateID=${data.data.items[0]?.ID}&sortBy=${sortBy}&pageSize=${pageSize}&pageIndex=${pageIndex}&isVsp=${isVsp}&year=${year}&salary=${salary}&immediate=${immediate}&expStatus=${expStatus}&maxCtc=${maxCtc}&location=${location}&fullTime=${fullTime}&partTime=${partTime}&internship=${internship}&remote=${remote}&onSite=${onSite}`
        // )
        navigate(`/candidate-profile-ro?candidateID=${data.data.items[0]?.ID}&${(term) ? `term=${term}&` : ''}${(searchTitle) ? `searchTitle=${searchTitle}&` : ''}sortBy[]=[{"id":"ID","desc":true}]&pageSize=${pageSize}&pageIndex=${pageIndex}&${(isVsp) ? `isVsp=${isVsp}&` : ''}${(year) ? `year=${year}&` : ''}${(salary) ? `salary=${salary}&` : ''}${(immediate) ? `immediate=${immediate}&` : ''}${(expStatus) ? `expStatus=${expStatus}&` : ''}${(maxCtc) ? `maxCtc=${maxCtc}&` : ''}${(location) ? `location=${location}&` : ''}${(fullTime) ? `fullTime=${fullTime}&` : ''}${(partTime) ? `partTime=${partTime}&` : ''}${(internship) ? `internship=${internship}&` : ''}${(remote) ? `remote=${remote}&` : ''}${(onSite) ? `onSite=${onSite}` : ''}`)
        if (data.data.status == 'last') {
          setDisableNextButton(true)
          setDisablePreviousButton(false)
        } else if (data.data.status == 'first') {
          setDisablePreviousButton(true)
          setDisableNextButton(false)
        } else if (data.data.status != 'last' && data.data.status != 'previous') {
          setDisablePreviousButton(false)
          setDisableNextButton(false)
        }
      }
    } else if (stateUrl.pathname.includes('candidate-shortlisted-profile')) {
      console.log('FROM SHORTLISTED')
      data = await axios.get(`/shortlist-by-emp-id`, {
        params: {
          candidateId: candidateID,
          step: sequenceType
        }
      })
      console.log('SHORTLISTED NEXT PREV',data)
      if (data.data.error) {
        if (data.data.error.includes('previous')) {
          setDisablePreviousButton(true)
          setDisableNextButton(false)
        } else if (data.data.error.includes('next')) {
          setDisablePreviousButton(false)
          setDisableNextButton(true)
        }
      } else {
        navigate(
          `/candidate-shortlisted-profile?candidateID=${data.data.items[0]?.ID}`
        )
        if (data.data.status == 'last') {
          setDisableNextButton(true)
          setDisablePreviousButton(false)
        } else if (data.data.status == 'first') {
          setDisablePreviousButton(true)
          setDisableNextButton(false)
        } else if (data.data.status != 'last' && data.data.status != 'previous') {
          setDisablePreviousButton(false)
          setDisableNextButton(false)
        }
      }
    }
    console.log('SHORTLISTED CANDIDATES', data.data)
    // console.log('next btn after api')
    if (data.data.items) {
      console.log(data)
      setData(data.items)
      // console.log(data.data.items[0]?)
      setUserInfo(data?.data)
      setUserEducation(data.data?.items[0]?.Education)
      setUserExperience(data.data?.items[0]?.Experience)
  
      setCurrentCTC(data.data?.items[0]?.CareerProfile.expectedCtc)
      setExpectedCTC(data.data?.items[0]?.CareerProfile.incomeCTC)
      if (data.data.items[0]?.JoiningStatus === 'join') {
        setOpenToJoin(true)
        setOpenToSwitch(false)
      } else if (data.data.items[0]?.JoiningStatus === 'switch') {
        setOpenToJoin(false)
        setOpenToSwitch(true)
      } else {
        setOpenToJoin(false)
        setOpenToSwitch(false)
      }
      setGenSkillByHr(data.data?.items[0]?.HrComments?.generalSkillsByHr)
      setProSkillByHr(data.data?.items[0]?.HrComments?.professionalSkillsByHr)
      setHrComments(data.data)
      const selectedLanguage = data.data?.items[0]?.HrComments?.language
  
      if (data.data.items[0]?.JoiningStatus === 'join') {
        setOpenToJoin(data.data.items[0]?.JoiningStatus)
        setOpenToSwitch(false)
      } else if (data.data.items[0]?.JoiningStatus === 'switch') {
        setOpenToJoin(false)
        setOpenToSwitch(data.data?.items[0]?.JoiningStatus)
      } else {
        setOpenToJoin(false)
        setOpenToSwitch(false)
      }
  
      if (data.data?.items[0]?.HrComments) {
        setUserVerified(true)
      }
    }
    setShowLoader(false)
  }

  const handleBack = () => {
    if (role === 'hr') {
      navigate(`/cslr-candidate-list?pageIndex1=${pageIndex}`)
    } else {
      console.log(searchParams.get("location"))
      const term = searchParams.get("term");
      const searchTitle = searchParams.get("searchTitle");
      const sortBy = searchParams.get("sortBy[]");
      const pageSize = searchParams.get("pageSize");
      const pageIndex = searchParams.get("pageIndex");
      const isVsp = searchParams.get("isVsp");
      const year = searchParams.get("year");
      const salary = searchParams.get("salary");
      const maxCtc = searchParams.get("maxCtc");
      const location = searchParams.get("location");
      console.log(location)
      const immediate = searchParams.get("immediate");
      const fullTime = searchParams.get("fullTime");
      const partTime = searchParams.get("partTime");
      const internship = searchParams.get("internship");
      const remote = searchParams.get("remote");
      const onSite = searchParams.get("onSite");
      const expStatus = searchParams.get("expStatus");
      navigate(`/emp-candidate-list?${(term) ? `term=${term}&` : ''}${(searchTitle) ? `searchTitle=${searchTitle}&` : ''}sortBy[]=[{"id":"ID","desc":true}]&pageSize=${pageSize}&pageIndex=${pageIndex}${(isVsp) ? `&isVsp=${isVsp}&` : ''}${(year) ? `year=${year}&` : ''}${(salary) ? `salary=${salary}&` : ''}${(immediate) ? `immediate=${immediate}&` : ''}${(expStatus) ? `expStatus=${expStatus}&` : ''}${(maxCtc) ? `maxCtc=${maxCtc}&` : ''}${(location) ? `location=${location}&` : ''}${(fullTime) ? `fullTime=${fullTime}&` : ''}${(partTime) ? `partTime=${partTime}&` : ''}${(internship) ? `internship=${internship}&` : ''}${(remote) ? `remote=${remote}&` : ''}${(onSite) ? `onSite=${onSite}` : ''}`)
    }
  }

  const handleShortlist = async (candidateData) => {
    console.log('Candidate Shortlisted :', candidateData)
    const candiateData = {
        "Candidate_id": candidateData.ID,
        "Status": "1"
    }
    const response = await axios.post(`/shortlist`, candiateData);
    console.log('Shortlisted Success', response);
    if (response.data.message == 'Success') {
        toast.success(`Candidate ${candidateData.FullName} has been shortlisted successfully!`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
        })
        const data = await GetCandidateByID(candidateData.ID, authToken)
        if (data.data) {
          setUserInfo(data?.data)
        }
    } else {
        toast.error(`Something went wrong!`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
        })
    }
  }
  return (
    <div
      id="appCapsule"
      className=""
      style={{ backgroundColor: getVspId ? '#C6E8DF' : '#f5f5f5' }}
    >
      <ToastContainer />
      {/* New UI Start */}
      <section className="container-fluid newUI-container-fluid mt-4">
        <Row>
          <Col className="mb-2">
            {urlPath?.includes('candidate-profile-ro') &&
              // <Link
              //   to={
              //     role === 'hr'
              //       ? `/cslr-candidate-list?pageIndex1=${pageIndex}`
              //       : '/emp-candidate-list'
              //   }
              //   className="d-flex align-items-center gap-2 w-auto"
              // >
              <div
                onClick={() => {handleBack()}}
                className="d-flex align-items-center gap-2 w-auto cursor-pointer"
              >
                <ion-icon
                  style={{ color: 'black' }}
                  name="arrow-back-outline"
                ></ion-icon>
                <p className="m-0" style={{ color: 'black' }}>
                  Back to candidates
                </p>
              </div>
            }
            {urlPath?.includes('candidate-shortlisted-profile') &&
              <Link
                to="/shortlisted"
                className="d-flex align-items-center gap-2 w-auto"
              >
                <ion-icon
                  style={{ color: 'black' }}
                  name="arrow-back-outline"
                ></ion-icon>
                <p className="m-0" style={{ color: 'black' }}>
                  Back to candidates
                </p>
              </Link>
            }
          </Col>
        </Row>
        <Row className="candidateProfRO bg-none mb-3 p-0">
          <Col>
            <div className="d-flex">
              <button
                onClick={() => {
                  handleCandidateScroll('previous')
                }}
                className="btn btn-primary"
                style={{ fontSize: '16px' }}
                disabled={disablePreviousButton}
              >
                <ion-icon
                  name="arrow-back-outline"
                  style={{
                    color: 'white',
                    fontSize: '16px',
                    marginLeft: '0px',
                    marginRight: '4px'
                  }}
                ></ion-icon>
                Previous
              </button>
            </div>
          </Col>
          <Col>
            <div className="d-flex justify-content-end">
              <a
                href="#"
                className="d-flex align-items-center gap-2 justify-content-end w-auto"
              >
                {/* <p className='m-0'>Next Candidate</p>
                                <ion-icon name="arrow-forward-outline"></ion-icon> */}
                <button
                  onClick={() => {
                    handleCandidateScroll('next')
                  }}
                  className="btn btn-primary"
                  style={{ fontSize: '16px' }}
                  disabled={disableNextButton}
                >
                  Next{' '}
                  <ion-icon
                    name="arrow-forward-outline"
                    style={{
                      color: 'white',
                      fontSize: '16px',
                      marginLeft: '4px',
                      marginRight: '0px'
                    }}
                  ></ion-icon>
                </button>
              </a>
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={8} sm={12}>
            <section className="candidateProfRO profileIntro mb-3" style={{position: 'relative'}}>
              {userInfo.shortlisted &&
                <div class="d-block d-md-none" style={{position: 'absolute', right: 10, top: 10}}>
                  <ion-icon name="star" style={{fontSize: '38px', color: '#fef3c7'}}></ion-icon>
                </div>
              }
              {!userInfo.shortlisted &&
                <div class="d-block d-md-none" style={{position: 'absolute', right: 10, top: 10}} onClick={() => {handleShortlist(userInfo)}}>
                  <ion-icon name="star-outline" style={{fontSize: '38px'}}></ion-icon>
                </div>
              }
              <Row className="align-items-center mb-2">
                <Col md={1} sm={1} className="d-flex justify-content-center">
                  <Image
                    src={previewImage || userInfo.Image || userPhoto}
                    alt="avatar"
                    className="profile-image-rounded imaged w-100 rounded profile-pic"
                  />
                </Col>
                <Col md={6} sm={6}>
                  <div className="profile-parent-div d-flex align-items-center justify-content-start">
                    <h3 className="m-0">{userInfo?.FullName}</h3>
                    {userVerified === true && (
                      <div style={{marginLeft: '3px'}}>
                          <img src={VerifiedBlueLogoMark} width="24px" style={{paddingBottom: '1px'}} />
                      </div>
                    )}
                  </div>
                  <div className="profile-edu-div">
                    {userInfo?.Experience && userInfo.Experience.length > 0 ? (
                      (() => {
                        const latestExperience = [...userInfo.Experience].sort(
                          (a, b) => b.endYear - a.endYear
                        )[0]
                        return (
                          <p className="text-muted m-0">
                            {latestExperience.designation} •{' '}
                            {latestExperience.company}
                          </p>
                        )
                      })()
                    ) : userInfo?.Education && userInfo.Education.length > 0 ? (
                      (() => {
                        const latestEducation = [...userInfo.Education].sort(
                          (a, b) => b.endYear - a.endYear
                        )[0]
                        return (
                          <p className="text-muted m-0">
                            {latestEducation.qualification} •{' '}
                            {latestEducation.institution}
                          </p>
                        )
                      })()
                    ) : (
                      <p className="text-muted m-0">No details available</p>
                    )}
                  </div>
                </Col>
                <Col
                  md={5}
                  sm={5}
                  style={{ textAlign: 'right' }}
                  className="profle-linkedin-btn mt-1 d-flex flex-column"
                >
                  {userInfo.shortlisted &&
                    <button className="btn border gap-2 mb-1 d-none d-md-flex" style={{background:'#fef3c7', color: '#333333'}}>
                      <ion-icon name="checkmark"></ion-icon>
                      Shortlisted
                    </button>
                  }
                  {!userInfo.shortlisted &&
                    <button className="btn border gap-2 mb-1 d-none d-md-flex" style={{background:'#f5f5f5', color: '#333333'}} onClick={() => {handleShortlist(userInfo)}}>
                      <ion-icon name="star-outline"></ion-icon>
                      Shortlist
                    </button>
                  }
                  {userInfo.JoiningInfo &&
                    <span className="btn btn-outline-primary border gap-2 mb-1 d-md-flex text-nowrap" style={{background:'#f5f5f5', color: '#333333', cursor: 'auto'}}>
                    <ion-icon name="toggle" style={{width: '25px'}}></ion-icon>
                      {(userInfo.JoiningInfo.status == 'switch') ? 'Ready to Switch' : 'Reday to Join'}
                    </span>
                  }
                  {/* <Button className="btn-outline-primary gap-2">
                    <Linkedin />
                    LinkedIn Account
                  </Button> */}
                  
                </Col>
              </Row>
              <Row>
                <Col className="profile-desc-div">
                  <p className="text-muted m-0">{userInfo?.Description}</p>
                </Col>
              </Row>
            </section>
            <section>
              <Row>
                <Col className="d-block d-md-none">
                  <section className="candidateProfRO profileMenu mb-3">
                    <div className="d-flex gap-3">
                      {/* <Button className='btn-gray gap-2'>
                                                <Star /> Shortlist
                                            </Button> */}
                      {/* <Button className='container-fluid btn-gray gap-2'>
                                                <BsTelephone /> Phone
                                            </Button> */}
                      <Button
                        className="container-fluid btn-gray"
                        href={`tel:+${userInfo?.Phone || ''}`}
                      >
                        <Phone className="me-1" /> Phone
                      </Button>

                      <Button
                        href={`mailto:${userInfo?.Email || ''}`}
                        className="container-fluid btn-gray gap-2"
                        disabled={!userInfo?.Email}
                      >
                        <Mail /> Email
                      </Button>
                      {/* <Button className='btn-gray gap-2'>
                                                <EllipsisVertical />
                                            </Button> */}
                    </div>
                  </section>
                </Col>
              </Row>
            </section>

            {/* for mobile view */}
            <section className="candidateProfRO profileVerify mb-3 d-block d-md-none">
              {userVerified === true ? (
                <>
                  <h3 className="d-flex align-items-center mb-3">
                    <img
                      src={VerifiedBlueLogoMark}
                      width="24px"
                      style={{ marginRight: '5px' }}
                    />
                    Verified by ScopeHai HR Team
                  </h3>
                  <div className="HRverify-grid">
                    <h5 className="HRverify-grid-item text">Communication</h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.ComSkills
                        ? `${userInfo?.HrComments?.ComSkills}`
                        : `N/A`}
                    </h4>
                    <h5 className="HRverify-grid-item text">
                      Language of comfort
                    </h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.language &&
                      userInfo?.HrComments?.language.length > 0
                        ? userInfo?.HrComments?.language
                            .map(work => work.label)
                            .join(', ')
                        : 'N/A'}
                    </h4>
                    <h5 className="HRverify-grid-item text">Personality</h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.personality
                        ? `${userInfo?.HrComments?.personality}`
                        : `N/A`}
                    </h4>
                    <h5 className="HRverify-grid-item text">
                      Work Type Suitability
                    </h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.workType &&
                      userInfo?.HrComments?.workType.length > 0
                        ? userInfo?.HrComments?.workType
                            .map(work => work.label)
                            .join(' / ')
                        : 'N/A'}
                    </h4>
                    <h5 className="HRverify-grid-item text">
                      Job Type Suitability
                    </h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.jobType &&
                      userInfo?.HrComments?.jobType.length > 0
                        ? userInfo?.HrComments?.jobType
                            .map(job => job.label)
                            .join(' / ')
                        : 'N/A'}
                    </h4>
                    <h5 className="HRverify-grid-item text">
                      Job Location Preference
                    </h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.jobLocation
                        ? `${userInfo?.HrComments?.jobLocation}`
                        : `N/A`}
                    </h4>
                    <h5 className="HRverify-grid-item text">Expected CTC</h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.ctc
                        ? `₹ ${userInfo?.HrComments?.ctc} LPA`
                        : 'N/A'}
                    </h4>
                    <h5 className="HRverify-grid-item text">Has Own</h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.hasOwn &&
                      userInfo?.HrComments?.hasOwn.length > 0
                        ? userInfo?.HrComments?.hasOwn.map((own, index) => (
                            <div
                              key={index}
                              className="chip normal-skill-chip gap-1 mx-1 mb-1 px-2"
                            >
                              {own.label === 'Bike' && <Bike />}
                              {own.label === 'Laptop' && <Laptop />}
                              {own.label}
                            </div>
                          ))
                        : 'N/A'}
                    </h4>
                    <h5 className="HRverify-grid-item text">
                      Availability to join
                    </h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.Availability
                        ? `${userInfo?.HrComments?.Availability}`
                        : 'N/A'}
                    </h4>
                  </div>
                  <h5
                    className="HRverify-grid-item text"
                    style={{ marginTop: '0.5rem' }}
                  >
                    Other remarks
                  </h5>
                  <h4 className="HRverify-grid-item">
                    {userInfo?.HrComments?.otherRemarks
                      ? `${userInfo?.HrComments?.otherRemarks}`
                      : 'N/A'}
                  </h4>
                </>
              ) : (
                <>
                  <h3 className="d-flex align-items-center mb-0">
                    <img
                      src={NotVerifiedGreyLogoMark}
                      width="24px"
                      style={{ marginRight: '5px' }}
                    />
                    Not verified by ScopeHai HR Team
                  </h3>
                </>
              )}
            </section>

            <section className="candidateProfRO profileSkills mb-3">
              <h3 className="mb-3">Skills</h3>
              <h4>Professional Skills</h4>
              <div>
                {userInfo?.SkillProfile?.ProfessionalSkills &&
                userInfo?.SkillProfile?.ProfessionalSkills.length > 0 ? (
                  userInfo?.SkillProfile?.ProfessionalSkills.map(
                    (skill, index) => (
                      <>
                        {proSkillByHr?.includes(skill) ? (
                          <div
                            key={index}
                            className="chip verified-skill-chip gap-1 mx-1 mb-1 px-2"
                          >
                            {skill} <BadgeCheck />
                          </div>
                        ) : (
                          <div
                            key={index}
                            className="chip normal-skill-chip mx-1 mb-1 px-2"
                          >
                            {skill}
                          </div>
                        )}
                      </>
                    )
                  )
                ) : (
                  <p className="text-muted">No Professioanl Skills Found</p>
                )}
              </div>
              <h4>General Skills</h4>
              <div>
                {userInfo?.SkillProfile?.GeneralSkills &&
                userInfo?.SkillProfile?.GeneralSkills.length > 0 ? (
                  userInfo?.SkillProfile?.GeneralSkills.map((skill, index) => (
                    <>
                      {genSkillByHr?.includes(skill) ? (
                        <div
                          key={index}
                          className="chip verified-skill-chip gap-1 mx-1 mb-1 px-2"
                        >
                          {skill} <BadgeCheck />
                        </div>
                      ) : (
                        <div
                          key={index}
                          className="chip normal-skill-chip mx-1 mb-1 px-2"
                        >
                          {skill}
                        </div>
                      )}
                    </>
                  ))
                ) : (
                  <p className="text-muted">No General Skills Found</p>
                )}
              </div>
            </section>
            <section className="candidateProfRO profileInfo mb-3">
              <h3 className="mb-3">Personal Information</h3>
              <Row>
                <Col md={6} sm={12} className="mb-2">
                  <a href={`mailto:${userInfo?.Email || ''}`}>
                    <div className="d-flex align-items-center gap-2">
                      <div className="profileInfoCardIcon">
                        <Mail />
                      </div>
                      <div>
                        <h4 className="mb-0">
                          {userInfo?.Email ? `${userInfo?.Email}` : `N/A`}
                        </h4>
                        <p className="text-muted mb-0">Mail Address</p>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col md={6} sm={12} className="mb-2">
                  <a href={`tel:+${userInfo?.Phone || ''}`}>
                    <div className="d-flex align-items-center gap-2">
                      <div className="profileInfoCardIcon">
                        <Phone />
                      </div>
                      <div>
                        <h4 className="mb-0">
                          {userInfo?.Phone ? `${userInfo?.Phone}` : `N/A`}
                        </h4>
                        <p className="text-muted mb-0">Phone Number</p>
                      </div>
                    </div>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12} className="mb-2">
                  <div className="d-flex align-items-center gap-2">
                    <div className="profileInfoCardIcon">
                      <Cake />
                    </div>
                    <div>
                      <h4 className="mb-0">
                        {userInfo?.DOB ? `${userInfo?.DOB}` : `N/A`}
                      </h4>
                      <p className="text-muted mb-0">DOB</p>
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={12} className="mb-2">
                  <div className="d-flex align-items-center gap-2">
                    <div className="profileInfoCardIcon">
                      <BadgeIndianRupee />
                    </div>
                    <div>
                      <h4 className="mb-0">
                        {userInfo?.CareerProfile?.expectedCtc
                          ? `₹ ${userInfo?.CareerProfile?.expectedCtc} LPA`
                          : 'N/A'}
                      </h4>
                      <p className="text-muted mb-0">Salary Expectations</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={12} sm={12} className="mb-2">
                  <div className="d-flex align-items-center gap-2">
                    <div className="profileInfoCardIcon">
                      <MapPin />
                    </div>
                    <div>
                      <h4 className="mb-0">
                        {userInfo?.HrComments?.jobLocation
                          ? `${userInfo?.HrComments?.jobLocation}`
                          : `N/A`}
                      </h4>
                      <p className="text-muted mb-0">Location</p>
                    </div>
                  </div>
                </Col>
                <Col md={12} sm={12}>
                  <div className="d-flex align-items-center gap-2">
                    <div className="profileInfoCardIcon">
                      <Mouse />
                    </div>
                    <div>
                      <h4 className="mb-0">
                        {hrComments?.HrComments?.workType
                          ? hrComments.HrComments.workType
                              .map(work => work.label)
                              .join(', ')
                          : 'N/A'}
                      </h4>
                      <p className="text-muted mb-0">Work Type</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </section>
            <section className="candidateProfRO profileCV mb-3">
              <h3 className="mb-3">Resume</h3>
              <Row>
                <Col md={9} sm={12} className="mb-2">
                  <div className="d-flex align-items-center gap-2">
                    <div className="profileInfoCardIcon">
                      <File />
                    </div>
                    <div>
                      {userInfo?.CV ? (
                        <h4 className="mb-0">{userInfo?.FullName} Resume</h4>
                      ) : (
                        <h4 className="mb-0">No resume found</h4>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={3} sm={12} className="d-md-flex justify-content-end">
                  <Button
                    varient="outline-primary"
                    className="btn-primary gap-2"
                    href={userInfo.CV || ''}
                    disabled={!userInfo?.CV}
                    download="User_CV"
                    target="_blank"
                  >
                    {' '}
                    Download
                    <Download />
                  </Button>
                </Col>
              </Row>
            </section>
            <section className="candidateProfRO profileXp mb-3">
              <h3 className="mb-3">Experience</h3>
              <div className="newPDUI-timeline timed pt-0">
                {userExperience && userExperience.length > 0 ? (
                  userExperience &&
                  userExperience
                    .slice()
                    .sort((a, b) => {
                      if (a.working && !b.working) return -1
                      if (!a.working && b.working) return 1

                      // Parse the end dates for sorting
                      const endDateA = a.working
                        ? new Date()
                        : new Date(a.endYear)
                      const endDateB = b.working
                        ? new Date()
                        : new Date(b.endYear)

                      // Sort by end date (year and month)
                      if (endDateA > endDateB) return -1
                      if (endDateA < endDateB) return 1
                      return 0
                    })
                    .map((experience, idx) => {
                      const startDate = new Date(experience.startYear)
                      if (isNaN(startDate)) {
                        console.error(
                          `Invalid StartYear: ${experience.startYear}`
                        )
                        return null
                      }
                      const startMonth = new Intl.DateTimeFormat('en', {
                        month: 'long'
                      }).format(startDate)
                      const startYear = startDate.getFullYear()
                      let endDate = new Date()
                      if (experience.endYear) {
                        endDate = new Date(experience.endYear)
                        if (isNaN(endDate)) {
                          console.error(
                            `Invalid EndYear: ${experience.endYear}`
                          )
                          return null
                        }
                      }
                      const yearsDifference =
                        endDate.getFullYear() - startDate.getFullYear()
                      const monthsDifference =
                        endDate.getMonth() - startDate.getMonth()
                      const totalMonths =
                        yearsDifference * 12 + monthsDifference
                      const years = Math.floor(totalMonths / 12)
                      const months = totalMonths % 12
                      const duration = `${years} yr${
                        years !== 1 ? 's' : ''
                      } ${months} mos`
                      return (
                        <div className="item" id={idx} key={idx}>
                          <div className="dot"></div>
                          <div className="ring"></div>
                          <div className="content">
                            <div className="d-flex align-items-center">
                              <h4 className="m-0">{experience.designation}</h4>
                            </div>
                            <div className="d-flex align-items-center">
                              <h5 className="title m-0">
                                {experience.company}
                              </h5>
                              <p className="text mx-1 mb-0 pb-1">.</p>
                              <span className="text">
                                {startMonth} {startYear} -{' '}
                                {experience.working
                                  ? 'Present'
                                  : experience.endYear}
                              </span>
                              <p className="text mx-1 mb-0 pb-1">.</p>
                              <div className="text">{duration}</div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                ) : (
                  <div className="item">
                    <div className="content">
                      <div className="d-flex align-items-center">
                        <img
                          src={logoPhoto}
                          alt="avatar"
                          className="imaged w48 rounded"
                        />
                        {role === 'Candidate' && (
                          <h4 className="title m-0">Add your Experience</h4>
                        )}
                        {(role === 'Admin' || role === 'Employer') && (
                          <h4 className="title m-0">No Experience found</h4>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
            <section className="candidateProfRO profileEdu mb-3">
              <h3 className="mb-3">Education</h3>
              <div className="newPDUI-timeline timed pt-0">
                {userEduction && userEduction.length > 0 ? (
                  userEduction &&
                  userEduction
                    .slice()
                    .sort((a, b) => b.endYear - a.endYear)
                    .map((education, idx) => {
                      let startYear = parseInt(education.startYear)
                      let endYear = parseInt(education.endYear)
                      if (isNaN(endYear)) {
                        endYear = new Date().getFullYear()
                      }
                      const yearsDifference = endYear - startYear
                      const duration = `(${yearsDifference} Years)`
                      return (
                        <div className="item" id={idx}>
                          <div className="content ps-0">
                            <h4 className="mb-1">{education?.institution}</h4>
                            <div className="d-flex align-items-center">
                              <h5 className="m-0">
                                {education?.qualification}
                              </h5>
                              <h5 className="m-0">, {education?.degree}</h5>
                            </div>
                            <div className="d-flex align-items-center">
                              <p className="text m-0">
                                {education?.startYear} -{' '}
                                {education?.pursuing === true
                                  ? 'pursuing'
                                  : education?.endYear}
                              </p>
                              <p className="text mx-1 mb-0 pb-1">.</p>
                              <span className="text">{duration}</span>
                            </div>
                          </div>
                        </div>
                        // <div className="item" id={idx}>
                        //     <div className="dot"></div>
                        //     <div className="content">
                        //         <h4 className="title m-0">{education?.institution}</h4>
                        //         <div className='d-flex align-items-center'>
                        //             <div className="text">{education?.qualification}</div>
                        //             <div className="text">{education?.degree}</div>
                        //         </div>
                        //         <span className="time">{education?.startYear} - {education?.pursuing === true ? "pursuing" : education?.endYear} <br /><div className="text">{duration}</div></span>
                        //         {/* <div className="text">{duration}</div> */}
                        //     </div>
                        // </div>
                      )
                    })
                ) : (
                  <div className="item">
                    <div className="content">
                      <div className="d-flex align-items-center">
                        <img
                          src={logoPhoto}
                          alt="avatar"
                          className="imaged w48 rounded"
                        />
                        {role === 'Candidate' && (
                          <h4 className="title m-0">Add your Education</h4>
                        )}
                        {(role === 'Admin' || role === 'Employer') && (
                          <h4 className="title m-0">No Education Found</h4>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </Col>
          <Col md={4} sm={12}>
            <section className="candidateProfRO profileMenu mb-3 d-none d-md-block">
              <div className="d-flex gap-3">
                {/* <Button className='btn-gray gap-2'>
                                    <Star /> Shortlist
                                </Button> */}
                {/* <Button className='btn-block btn-gray gap-2'>
                                    <BsTelephone /> Phone
                                </Button> */}
                <Button
                  className="container-fluid btn-gray"
                  href={`tel:+${userInfo?.Phone || ''}`}
                >
                  <Phone className="me-1" /> Phone
                </Button>
                <Button
                  href={`mailto:${userInfo?.Email || ''}`}
                  className="btn-block btn-gray gap-2"
                  disabled={!userInfo?.Email}
                >
                  <Mail /> Email
                </Button>
                {/* <Button className='btn-gray gap-2'>
                                    <EllipsisVertical />
                                </Button> */}
              </div>
            </section>
            <section className="candidateProfRO profileVerify mb-3 d-none d-md-block">
              {userVerified === true ? (
                <>
                  <h3 className="d-flex align-items-center mb-3">
                    <img
                      src={VerifiedBlueLogoMark}
                      width="24px"
                      style={{ marginRight: '5px' }}
                    />
                    Verified by ScopeHai HR Team
                  </h3>
                  <div className="HRverify-grid">
                    <h5 className="HRverify-grid-item text">Communication</h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.ComSkills
                        ? `${userInfo?.HrComments?.ComSkills}`
                        : `N/A`}
                    </h4>
                    <h5 className="HRverify-grid-item text">
                      Language of comfort
                    </h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.language &&
                      userInfo?.HrComments?.language.length > 0
                        ? userInfo?.HrComments?.language
                            .map(work => work.label)
                            .join(', ')
                        : 'N/A'}
                    </h4>
                    <h5 className="HRverify-grid-item text">Personality</h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.personality
                        ? `${userInfo?.HrComments?.personality}`
                        : `N/A`}
                    </h4>
                    <h5 className="HRverify-grid-item text">
                      Work Type Suitability
                    </h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.workType &&
                      userInfo?.HrComments?.workType.length > 0
                        ? userInfo?.HrComments?.workType
                            .map(work => work.label)
                            .join(' / ')
                        : 'N/A'}
                    </h4>
                    <h5 className="HRverify-grid-item text">
                      Job Type Suitability
                    </h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.jobType &&
                      userInfo?.HrComments?.jobType.length > 0
                        ? userInfo?.HrComments?.jobType
                            .map(job => job.label)
                            .join(' / ')
                        : 'N/A'}
                    </h4>
                    <h5 className="HRverify-grid-item text">
                      Job Location Preference
                    </h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.jobLocation
                        ? `${userInfo?.HrComments?.jobLocation}`
                        : `N/A`}
                    </h4>
                    <h5 className="HRverify-grid-item text">Expected CTC</h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.ctc
                        ? `₹ ${userInfo?.HrComments?.ctc} LPA`
                        : 'N/A'}
                    </h4>
                    <h5 className="HRverify-grid-item text">Has Own</h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.hasOwn &&
                      userInfo?.HrComments?.hasOwn.length > 0
                        ? userInfo?.HrComments?.hasOwn.map((own, index) => (
                            <div
                              key={index}
                              className="chip normal-skill-chip gap-1 mx-1 mb-1 px-2"
                            >
                              {own.label === 'Bike' && <Bike />}
                              {own.label === 'Laptop' && <Laptop />}
                              {own.label}
                            </div>
                          ))
                        : 'N/A'}
                    </h4>
                    <h5 className="HRverify-grid-item text">
                      Availability to join
                    </h5>
                    <h4 className="HRverify-grid-item">
                      {userInfo?.HrComments?.Availability
                        ? `${userInfo?.HrComments?.Availability}`
                        : 'N/A'}
                    </h4>
                  </div>
                  <h5
                    className="HRverify-grid-item text"
                    style={{ marginTop: '0.5rem' }}
                  >
                    Other remarks
                  </h5>
                  <h4 className="HRverify-grid-item">
                    {userInfo?.HrComments?.otherRemarks
                      ? `${userInfo?.HrComments?.otherRemarks}`
                      : 'N/A'}
                  </h4>
                </>
              ) : (
                <>
                  <h3 className="d-flex align-items-center mb-0">
                    <img
                      src={NotVerifiedGreyLogoMark}
                      width="24px"
                      style={{ marginRight: '5px' }}
                    />
                    Not verified by ScopeHai HR Team
                  </h3>
                </>
              )}
            </section>
            <section className="candidateProfRO profileJobs mb-3">
              <h3 className="mb-3">Suitable Job Profiles</h3>
              <div>
                {(userInfo?.Opportunities?.selectedJobRoles?.length > 0) ? (
                  userInfo?.Opportunities?.selectedJobRoles?.map((title, index) => (
                    <>
                      <div key={index} className="suitableJobs mb-1">
                        <h4 className="mb-0">{title.Title}</h4>
                      </div>
                    </>
                  ))
                ) : (
                  <span className="text-muted">No Suitable Jobs Found</span>
                )}
              </div>
            </section>
          </Col>
        </Row>
      </section>
      {/* New UI End */}

      {/* Old UI Start */}
      <div
        className="container candidate-profile d-none"
        style={{ marginBottom: '60px' }}
      >
        {(role === 'Employer' || role === 'Admin') && (
          <Link
            to="/emp-candidate-list"
            type="submit"
            className="my-2 text-white"
          >
            <FaArrowLeft
              style={{ width: '15px', height: '15px', marginRight: '7px' }}
            />
            <Button variant="primary mb-2">Go to List</Button>
          </Link>
        )}
        <div className="section mt-2">
          <div className="profile-head">
            <div className="avatar d-flex flex-column align-items-center">
              <label htmlFor="fileUpload" class="cursor-pointer">
                <img
                  src={previewImage || userInfo.Image || userPhoto}
                  alt="avatar"
                  className="imaged w64 rounded profile-pic"
                />
              </label>
            </div>
            <div className="in w-100">
              <div className="d-flex justify-content-between flex-column flex-md-row">
                <div className="user-name">
                  <h3 className="name">
                    <span class="d-flex align-items-center">
                      {userInfo?.FullName}
                      <div
                        class="animation-container d-flex align-items-center justify-content-center ms-1 cursor-pointer"
                        onClick={() => setShowGetVspModal(true)}
                        style={{ marginTop: '8px' }}
                      >
                        <div
                          class="icon star"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <img src={ScopehaiLogo} width="100%" />
                        </div>
                        <div
                          class="icon box"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '100px',
                            border: 'solid 2.5px #FFBC00',
                            height: '24px',
                            width: '24px'
                          }}
                        >
                          <img src={StarIcon} width="90%" />
                        </div>
                      </div>
                    </span>
                    {perticularApplication[0]?.Shortlist === true && (
                      <span className="text-muted ps-1">shortlisted</span>
                    )}
                  </h3>
                  <h5 className="subtext">{userInfo?.CareerProfile?.degree}</h5>
                  <h5 className="subtext">{userDomain}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section mt-1 mb-2">
          <div className="profile-info">
            <div className=" bio">
              <Form noValidate validated={validated} onSubmit="">
                <Row>
                  <Col>
                    <Form.Group className="d-flex align-items-center">
                      <Form.Label className="me-2">Open To Join :</Form.Label>
                      <div className="form-check form-switch">
                        <input
                          disabled
                          className="open-to-join form-check-input"
                          type="checkbox"
                          id="SwitchCheckDefault1"
                          checked={openToJoin}
                          onChange={handleOpenToJoin}
                        />
                        {/* <label className="form-check-label" htmlFor="SwitchCheckDefault1"></label> */}
                        <label>
                          {userInfo.JoiningStatus === 'join'
                            ? ' Checked'
                            : 'No'}
                        </label>
                      </div>
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center">
                      <Form.Label className="me-2">Open To Switch :</Form.Label>
                      <div className="form-check form-switch">
                        <input
                          disabled
                          className="open-to-join form-check-input"
                          type="checkbox"
                          id="SwitchCheckDefault2"
                          checked={openToSwitch}
                          onChange={handleOpenToSwitch}
                        />
                        {/* <label className="form-check-label" htmlFor="SwitchCheckDefault2"></label> */}
                        <label>
                          {userInfo.JoiningStatus === 'switch'
                            ? ' Checked'
                            : 'No'}
                        </label>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <Card.Text>
                <div className="pb-2">
                  <h5>General Skills:</h5>
                  <div>
                    {userInfo?.SkillProfile?.GeneralSkills ? (
                      userInfo?.SkillProfile?.GeneralSkills.map(
                        (skill, index) => (
                          <div
                            key={index}
                            className="chip bg-transparent text-black ms-05 mb-1 skill-chip"
                          >
                            {genSkillByHr?.includes(skill) ? (
                              <span className="pe-1">
                                <img src={tick} alt="avatar" />
                              </span>
                            ) : (
                              <span className="pe-1">
                                <img src={tickOutlined} alt="avatar" />
                              </span>
                            )}
                            {skill}
                            {/* <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={tick} />{skill}</span> */}
                          </div>
                        )
                      )
                    ) : (
                      <span className="text-muted">No General Skill Found</span>
                    )}
                  </div>
                  <div>
                    {/* modified the api, can't go forward as i couldn't run api at my local network (check the console log at line number 104 & 105 and change the code)*/}
                    {/* {
                                            (role === 'Employer' || role === 'Admin') && 
                                            (
                                                removedGeneralSkills.map((skill, index) => (
                                                    <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip position-relative strike-through">
                                                    <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={gayTick}/>{skill}</span>
                                                </div>
                                                ))
                                            )
                                        } */}
                  </div>
                </div>
                <div className="pb-2">
                  <h5>Professional Skills:</h5>
                  <div>
                    {userInfo?.SkillProfile?.ProfessionalSkills ? (
                      userInfo?.SkillProfile?.ProfessionalSkills.map(
                        (skill, index) => (
                          <div
                            key={index}
                            className="chip bg-transparent text-black ms-05 mb-1 skill-chip"
                          >
                            {proSkillByHr?.includes(skill) ? (
                              <span className="pe-1">
                                <img src={tick} alt="avatar" />
                              </span>
                            ) : (
                              <span className="pe-1">
                                <img src={tickOutlined} alt="avatar" />
                              </span>
                            )}
                            {skill}
                          </div>
                        )
                      )
                    ) : (
                      <span className="text-muted">
                        No Professioanl Skill Found
                      </span>
                    )}
                  </div>
                  <div>
                    {/* modified the api, can't go forward as i couldn't run api at my local network (check the console log at line number 104 & 105 and change the code)*/}
                    {/* {
                                            (role === 'Employer' || role === 'Admin') && 
                                            (
                                                removedProfessinalSkills.map((skill, index) => (
                                                    <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip position-relative strike-through">
                                                    <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={gayTick}/>{skill}</span>
                                                </div>
                                                ))
                                            )
                                        } */}
                  </div>
                </div>
                <div className="pb-2">
                  <h5>Description:</h5>
                  <div className="description">
                    {role === 'Candidate' && (
                      <>
                        {userInfo?.Description ? (
                          userInfo.Description
                        ) : (
                          <p>Edit your profile to add description</p>
                        )}
                      </>
                    )}
                    {(role === 'Admin' || role === 'Employer') && (
                      <>
                        {userInfo?.Description ? (
                          userInfo.Description
                        ) : (
                          <p>No description found.</p>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {!userInfo?.CV && (
                  <div className="pb-2">
                    <div className="description">
                      {role === 'Candidate' && (
                        <>
                          {!userInfo?.CV && (
                            <p>You have not uploaded your CV yet.</p>
                          )}
                        </>
                      )}
                      {(role === 'Admin' || role === 'Employer') && (
                        <>{!userInfo?.CV && <p>No CV found.</p>}</>
                      )}
                    </div>
                  </div>
                )}
                {/* upload CV */}
                {/* {role === 'Candidate' &&
                                    <FileUploadWithProgress onUploadSuccess={handleCVUploadSuccess} />

                                } */}
              </Card.Text>
            </div>
          </div>
        </div>

        <div class="d-none d-sm-block">
          <div className="section full">
            <div className="wide-block transparent p-0">
              <ul
                className="nav nav-tabs lined iconed candidateProfile"
                role="tablist"
              >
                <li className="nav-item">
                  <a className="nav-link" href="#education" role="tab">
                    <i className="fa fa-graduation-cap me-1"></i>Education
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#experience" role="tab">
                    <i className="fa fa-briefcase me-1"></i>Experience
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="section full mb-2">
            <div className="tab-content">
              <div class="row">
                <div class="col-md-6">
                  <div className="tab-pane fade active show" role="tabpanel">
                    <div className="add-education-btn position-relative pb-3 pt-2">
                      {role === 'Candidate' && (
                        <Button
                          className="position-absolute z-index-10"
                          variant="primary"
                          onClick={() => setEduModalShow(true)}
                          style={{ left: '3.4rem' }}
                        >
                          <FaPlus />
                        </Button>
                      )}
                    </div>
                    <div className="timeline timed">
                      {userEduction && userEduction.length > 0 ? (
                        userEduction &&
                        userEduction
                          .slice()
                          .sort((a, b) => b.endYear - a.endYear)
                          .map((education, idx) => {
                            let startYear = parseInt(education.startYear)
                            let endYear = parseInt(education.endYear)

                            // Check if endYear is NaN, then set it to the current year
                            if (isNaN(endYear)) {
                              endYear = new Date().getFullYear()
                            }

                            const yearsDifference = endYear - startYear
                            const duration = `(${yearsDifference} Years)`

                            return (
                              <div className="item" id={idx}>
                                <span className="time">
                                  {education?.startYear} -{' '}
                                  {education?.pursuing === true
                                    ? 'pursuing'
                                    : education?.endYear}{' '}
                                  <br />
                                  <div className="text">{duration}</div>
                                </span>
                                <div className="dot"></div>
                                <div className="content">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={logoPhoto}
                                      alt="avatar"
                                      className="imaged w48 rounded"
                                    />
                                    <h4 className="title m-0">
                                      {education?.institution}
                                    </h4>
                                  </div>
                                  <div className="text">
                                    {education?.qualification}
                                  </div>
                                  <div className="text">
                                    {education?.degree}
                                  </div>
                                  {/* <div className="text">{duration}</div> */}
                                </div>
                              </div>
                            )
                          })
                      ) : (
                        <div className="item">
                          <div className="content">
                            <div className="d-flex align-items-center">
                              <img
                                src={logoPhoto}
                                alt="avatar"
                                className="imaged w48 rounded"
                              />
                              {role === 'Candidate' && (
                                <h4 className="title m-0">
                                  Add your Education
                                </h4>
                              )}
                              {(role === 'Admin' || role === 'Employer') && (
                                <h4 className="title m-0">
                                  No Education Found
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <GetVspModal
                        // onHide={() => setVspModalShow(false)}
                        show={showGetVspModal}
                        handleClose={handleCloseGetVspModal}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div className="tab-pane fade active show" role="tabpanel">
                    <div className="add-education-btn position-relative pb-3 pt-2">
                      {role === 'Candidate' && (
                        <Button
                          className="position-absolute z-index-10"
                          variant="primary"
                          onClick={() => setExpModalShow(true)}
                          style={{ left: '3.4rem' }}
                        >
                          <FaPlus />
                        </Button>
                      )}
                    </div>
                    <div className="timeline timed">
                      {userExperience && userExperience.length > 0 ? (
                        userExperience &&
                        userExperience
                          .slice()
                          .sort((a, b) => {
                            if (a.working && !b.working) return -1
                            if (!a.working && b.working) return 1

                            // Parse the end dates for sorting
                            const endDateA = a.working
                              ? new Date()
                              : new Date(a.endYear)
                            const endDateB = b.working
                              ? new Date()
                              : new Date(b.endYear)

                            // Sort by end date (year and month)
                            if (endDateA > endDateB) return -1
                            if (endDateA < endDateB) return 1
                            return 0
                          })
                          .map((experience, idx) => {
                            //// Parse the start date
                            const startDate = new Date(experience.startYear)

                            if (isNaN(startDate)) {
                              console.error(
                                `Invalid StartYear: ${experience.startYear}`
                              )
                              return null
                            }
                            //// Get the month name and year
                            const startMonth = new Intl.DateTimeFormat('en', {
                              month: 'long'
                            }).format(startDate)
                            const startYear = startDate.getFullYear()

                            //// Parse the end date or set it to the current year if undefined
                            let endDate = new Date()
                            if (experience.endYear) {
                              endDate = new Date(experience.endYear)
                              if (isNaN(endDate)) {
                                console.error(
                                  `Invalid EndYear: ${experience.endYear}`
                                )
                                return null
                              }
                            }

                            //// Calculate the difference in years and months
                            const yearsDifference =
                              endDate.getFullYear() - startDate.getFullYear()
                            const monthsDifference =
                              endDate.getMonth() - startDate.getMonth()
                            const totalMonths =
                              yearsDifference * 12 + monthsDifference

                            //// Format the duration
                            const years = Math.floor(totalMonths / 12)
                            const months = totalMonths % 12
                            const duration = `(${years} Year${
                              years !== 1 ? 's' : ''
                            } ${months} Month${months !== 1 ? 's' : ''})`

                            return (
                              <div className="item" id={idx} key={idx}>
                                <span className="time">
                                  {startMonth} {startYear} -{' '}
                                  {experience.working
                                    ? 'Present'
                                    : experience.endYear}
                                </span>
                                <div className="dot"></div>
                                <div className="content">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={logoPhoto}
                                      alt="avatar"
                                      className="imaged w48 rounded"
                                    />
                                    <h4 className="title m-0">
                                      {experience.company}
                                    </h4>
                                  </div>
                                  <div className="text">{duration}</div>
                                  <div className="text">
                                    {experience.designation}
                                  </div>
                                </div>
                              </div>
                            )
                          })
                      ) : (
                        <div className="item">
                          <div className="content">
                            <div className="d-flex align-items-center">
                              <img
                                src={logoPhoto}
                                alt="avatar"
                                className="imaged w48 rounded"
                              />
                              {role === 'Candidate' && (
                                <h4 className="title m-0">
                                  Add your Experience
                                </h4>
                              )}
                              {(role === 'Admin' || role === 'Employer') && (
                                <h4 className="title m-0">
                                  No Experience found
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-block d-sm-none">
          <div className="section full">
            <div className="wide-block transparent p-0">
              <ul
                className="nav nav-tabs lined iconed candidateProfile"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#education"
                    role="tab"
                  >
                    <i className="fa fa-graduation-cap me-1"></i>Education
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#experience"
                    role="tab"
                  >
                    <i className="fa fa-briefcase me-1"></i>Experience
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="section full mb-2">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="education"
                role="tabpanel"
              >
                <div className="add-education-btn position-relative pb-3 pt-2">
                  {role === 'Candidate' && (
                    <Button
                      className="position-absolute z-index-10"
                      variant="primary"
                      onClick={() => setEduModalShow(true)}
                      style={{ left: '3.4rem' }}
                    >
                      <FaPlus />
                    </Button>
                  )}
                </div>
                <div className="timeline timed">
                  {userEduction && userEduction.length > 0 ? (
                    userEduction &&
                    userEduction
                      .slice()
                      .sort((a, b) => b.endYear - a.endYear)
                      .map((education, idx) => {
                        let startYear = parseInt(education.startYear)
                        let endYear = parseInt(education.endYear)

                        // Check if endYear is NaN, then set it to the current year
                        if (isNaN(endYear)) {
                          endYear = new Date().getFullYear()
                        }

                        const yearsDifference = endYear - startYear
                        const duration = `(${yearsDifference} Years)`

                        return (
                          <div className="item" id={idx}>
                            <span className="time">
                              {education?.startYear} -{' '}
                              {education?.pursuing === true
                                ? 'pursuing'
                                : education?.endYear}{' '}
                              <br />
                              <div className="text">{duration}</div>
                            </span>
                            <div className="dot"></div>
                            <div className="content">
                              <div className="d-flex align-items-center">
                                <img
                                  src={logoPhoto}
                                  alt="avatar"
                                  className="imaged w48 rounded"
                                />
                                <h4 className="title m-0">
                                  {education?.institution}
                                </h4>
                              </div>
                              <div className="text">
                                {education?.qualification}
                              </div>
                              <div className="text">{education?.degree}</div>
                              {/* <div className="text">{duration}</div> */}
                            </div>
                          </div>
                        )
                      })
                  ) : (
                    <div className="item">
                      <div className="content">
                        <div className="d-flex align-items-center">
                          <img
                            src={logoPhoto}
                            alt="avatar"
                            className="imaged w48 rounded"
                          />
                          {role === 'Candidate' && (
                            <h4 className="title m-0">Add your Education</h4>
                          )}
                          {(role === 'Admin' || role === 'Employer') && (
                            <h4 className="title m-0">No Education Found</h4>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <GetVspModal
                    // onHide={() => setVspModalShow(false)}
                    show={showGetVspModal}
                    handleClose={handleCloseGetVspModal}
                  />
                </div>
              </div>

              <div className="tab-pane fade" id="experience" role="tabpanel">
                <div className="add-education-btn position-relative pb-3 pt-2">
                  {role === 'Candidate' && (
                    <Button
                      className="position-absolute z-index-10"
                      variant="primary"
                      onClick={() => setExpModalShow(true)}
                      style={{ left: '3.4rem' }}
                    >
                      <FaPlus />
                    </Button>
                  )}
                </div>
                <div className="timeline timed">
                  {userExperience && userExperience.length > 0 ? (
                    userExperience &&
                    userExperience
                      .slice()
                      .sort((a, b) => {
                        if (a.working && !b.working) return -1
                        if (!a.working && b.working) return 1

                        // Parse the end dates for sorting
                        const endDateA = a.working
                          ? new Date()
                          : new Date(a.endYear)
                        const endDateB = b.working
                          ? new Date()
                          : new Date(b.endYear)

                        // Sort by end date (year and month)
                        if (endDateA > endDateB) return -1
                        if (endDateA < endDateB) return 1
                        return 0
                      })
                      .map((experience, idx) => {
                        //// Parse the start date
                        const startDate = new Date(experience.startYear)

                        if (isNaN(startDate)) {
                          console.error(
                            `Invalid StartYear: ${experience.startYear}`
                          )
                          return null
                        }
                        //// Get the month name and year
                        const startMonth = new Intl.DateTimeFormat('en', {
                          month: 'long'
                        }).format(startDate)
                        const startYear = startDate.getFullYear()

                        //// Parse the end date or set it to the current year if undefined
                        let endDate = new Date()
                        if (experience.endYear) {
                          endDate = new Date(experience.endYear)
                          if (isNaN(endDate)) {
                            console.error(
                              `Invalid EndYear: ${experience.endYear}`
                            )
                            return null
                          }
                        }

                        //// Calculate the difference in years and months
                        const yearsDifference =
                          endDate.getFullYear() - startDate.getFullYear()
                        const monthsDifference =
                          endDate.getMonth() - startDate.getMonth()
                        const totalMonths =
                          yearsDifference * 12 + monthsDifference

                        //// Format the duration
                        const years = Math.floor(totalMonths / 12)
                        const months = totalMonths % 12
                        const duration = `(${years} Year${
                          years !== 1 ? 's' : ''
                        } ${months} Month${months !== 1 ? 's' : ''})`

                        return (
                          <div className="item" id={idx} key={idx}>
                            <span className="time">
                              {startMonth} {startYear} -{' '}
                              {experience.working
                                ? 'Present'
                                : experience.endYear}
                            </span>
                            <div className="dot"></div>
                            <div className="content">
                              <div className="d-flex align-items-center">
                                <img
                                  src={logoPhoto}
                                  alt="avatar"
                                  className="imaged w48 rounded"
                                />
                                <h4 className="title m-0">
                                  {experience.company}
                                </h4>
                              </div>
                              <div className="text">{duration}</div>
                              <div className="text">
                                {experience.designation}
                              </div>
                            </div>
                          </div>
                        )
                      })
                  ) : (
                    <div className="item">
                      <div className="content">
                        <div className="d-flex align-items-center">
                          <img
                            src={logoPhoto}
                            alt="avatar"
                            className="imaged w48 rounded"
                          />
                          {role === 'Candidate' && (
                            <h4 className="title m-0">Add your Experience</h4>
                          )}
                          {(role === 'Admin' || role === 'Employer') && (
                            <h4 className="title m-0">No Experience found</h4>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section full d-none">
        <div className="wide-block transparent p-0">
          <ul
            className="nav nav-tabs lined iconed candidateProfile"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#education"
                role="tab"
              >
                {/* <i className="fa fa-graduation-cap me-1"></i> */}
                HR Comments
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="container d-none">
        <div className=" mt-2">
          <Row>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                Communication Skills:
              </Form.Label>
            </Col>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                {hrComments?.HrComments?.ComSkills || 'N/A'}
              </Form.Label>
            </Col>
          </Row>
        </div>
        <div className=" mt-2">
          <Row>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                Language of comfort :
              </Form.Label>
            </Col>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                {hrComments?.HrComments?.language
                  .map(lan => (lan = lan.value))
                  .join(', ') || 'N/A'}
              </Form.Label>
            </Col>
          </Row>
        </div>
        <div className=" mt-2">
          <Row>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                Personality :
              </Form.Label>
            </Col>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                {hrComments?.HrComments?.personality || 'N/A'}
              </Form.Label>
            </Col>
          </Row>
        </div>
        <div className=" mt-2">
          <Row>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                Work type suitability :
              </Form.Label>
            </Col>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                {hrComments?.HrComments?.workType
                  .map(work => work.label)
                  .join(', ') || 'N/A'}
              </Form.Label>
            </Col>
          </Row>
        </div>
        <div className=" mt-2">
          <Row>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                Job type suitability :
              </Form.Label>
            </Col>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                {hrComments?.HrComments?.jobType
                  .map(job => job.label)
                  .join(', ') || 'N/A'}
              </Form.Label>
            </Col>
          </Row>
        </div>
        <div className=" mt-2">
          <Row>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                Job location preference :
              </Form.Label>
            </Col>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                {hrComments?.HrComments?.jobLocation || 'N/A'}
              </Form.Label>
            </Col>
          </Row>
        </div>
        <div className=" mt-2">
          <Row>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                Expected CTC :
              </Form.Label>
            </Col>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                {hrComments?.HrComments?.ctc || 'N/A'}
              </Form.Label>
            </Col>
          </Row>
        </div>
        <div className=" mt-2">
          <Row>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                Has own :
              </Form.Label>
            </Col>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                {hrComments?.HrComments?.hasOwn.map(own => own.label) || 'N/A'}
              </Form.Label>
            </Col>
          </Row>
        </div>
        <div className=" mt-2">
          <Row>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                Availability to join :
              </Form.Label>
            </Col>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                {hrComments?.HrComments?.Availability || 'N/A'}
              </Form.Label>
            </Col>
          </Row>
        </div>
        <div className=" mt-2">
          <Row>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                Other remarks :
              </Form.Label>
            </Col>
            <Col xs={12} md={4}>
              <Form.Label for="comskill" className="pe-1">
                {hrComments?.HrComments?.otherRemarks || 'N/A'}
              </Form.Label>
            </Col>
          </Row>
        </div>
      </div>
      {/* Old UI End */}
    </div>
  )
}

const mapStateToProps = ({ auth, data }) => {
  const { userData, allCities, role, fullName, ID, authToken } = auth
  const { candidateList } = data
  return {
    userData,
    allCities,
    role,
    fullName,
    ID,
    authToken,
    candidateList
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateProfileReadOnly)
