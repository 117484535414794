import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import {
  GetCandidateByID,
  UpdateUserProfile,
  deleteCandidateEducationRecord,
  deleteCandidateExperienceRecord,
  fetchAllJobRoles,
  fetchAllJobRolesSkills,
  getRemovedSkills,
  handelOpenToWork,
  postUserImage,
  shortlistApplicant,
  UserJoinStatus
} from '../../../../features/constants/apis'
import { hideProfileUpdateModal } from '../../../../features/popup/ProfileUpdateModalSlice'

const EditIntro = ({
  userInfo,
  ID,
  authToken,
  profileUpdateModalVisible,
  hideProfileUpdateModal,
  relatedJobRoles
}) => {
  console.log('inside edit form: ', userInfo)
  console.log('related jobroles: ', relatedJobRoles)

  // Split FullName into fName and lName
  const [selectedRole, setSelectedRole] = useState('')
  const [userData, setUserData] = useState({
    fName: userInfo?.FullName?.split(' ')[0] || '',
    lName: userInfo?.FullName?.split(' ').slice(1).join(' ') || '',
    description: userInfo?.Description || '',
    currentPosition: userInfo?.CurrentPosition || ''
  })
  console.log(userData)
  const [popupLoder, setPopUpLoder] = useState(false)
  const [jobRoles, setJobRoles] = useState([])

  // Handle input changes
  // const handleChange = e => {
  //   const { id, value } = e.target
  //   console.log(id, value)
  //   setUserData(prevData => ({
  //     ...prevData,
  //     [id]: value // Update field based on input ID
  //   }))
  // }

  const handleChange = (input) => {
    if (input && input.target) {
      // Handles native HTML elements
      const { id, value } = input.target;
      console.log(id, value);
      setUserData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    } else {
      // Handles react-select (assumes 'currentPosition' is being updated)
      console.log("currentPosition", input.value);
      setUserData((prevData) => ({
        ...prevData,
        currentPosition: input.value, // Update userData correctly
      }));
    }
  };
  

  const EditUserProfile = async e => {
    e.preventDefault()
    setPopUpLoder(true)
    console.log('editprofile fuc triggerd!!!')
    const combinedName = `${userData.fName} ${userData.lName}`.trim()
    const data = {
      name: combinedName,
      description: userData.description,
      currentPosition: userData.currentPosition
    }
    const updateProfile = await UpdateUserProfile(userInfo?.ID, data, authToken)
    console.log('user Data: ', updateProfile)
    hideProfileUpdateModal()
    // const data = await GetCandidateByID(ID, authToken)
    // if (role === 'Candidate') {
    //     setUserInfo(data.data);
    //     setUserEducation(data.data?.Education);
    //     setUserExperience(data.data?.Experience);
    // }
    setPopUpLoder(false)
  }

  useEffect(() => {
    setJobRoles(relatedJobRoles.map((role) => ({ value: role, label: role })));
  }, [relatedJobRoles])

  return (
    <div>
      <div className="row">
        <form onSubmit={EditUserProfile}>
          <div className="col-12 col-md-12">
            <div className="form-group boxed">
              <div className="input-wrapper">
                <label
                  className="form-label fw-bold"
                  htmlFor="fName"
                  style={{ fontSize: '14px', marginBottom: '3px' }}
                >
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm border"
                  id="fName"
                  value={userData.fName}
                  autoComplete="off"
                  onChange={handleChange}
                />
                <i className="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
            </div>
            <div className="form-group boxed">
              <div className="input-wrapper">
                <label
                  className="form-label fw-bold"
                  htmlFor="lName"
                  style={{ fontSize: '14px', marginBottom: '3px' }}
                >
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm border"
                  id="lName"
                  value={userData.lName}
                  autoComplete="off"
                  onChange={handleChange}
                />
                <i className="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
            </div>
            <div className="form-group boxed">
              <div className="input-wrapper">
                <label
                  className="form-label fw-bold"
                  htmlFor="description"
                  style={{ fontSize: '14px', marginBottom: '3px' }}
                >
                  Headline
                </label>
                <textarea
                  id="description"
                  rows="5"
                  className="form-control form-control-sm border"
                  placeholder="Ex. Microsoft"
                  value={userData.description}
                  onChange={handleChange}
                ></textarea>
                <i className="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
            </div>
            {userInfo?.ExperienceLevel === 'Experienced' && (
              <>
                <div className="form-group boxed">
                  <div className="input-wrapper">
                    <label
                      className="form-label fw-bold"
                      htmlFor="currentPosition"
                      style={{ fontSize: '14px', marginBottom: '3px' }}
                    >
                      Current Position
                    </label>
                    <div className="d-flex">
                      {/* <select
                        className="form-control form-select-sm form-select border"
                        id="currentPosition"
                        value={userData.currentPosition}
                        onChange={handleChange}
                      >
                        {relatedJobRoles.map((role, index) => (
                          <option key={index} value={role}>
                            {role}
                          </option>
                        ))}
                      </select> */}
                      <Select
                        options={jobRoles}
                        value={jobRoles.find((option) => option.value === userData.currentPosition)}
                        onChange={handleChange} // Now works correctly
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: state.isFocused ? "#007bff" : "#ced4da", // Blue when focused, gray otherwise
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* {userInfo?.ExperienceLevel === 'Experienced' && (
            <>
              <div className="col-12 col-md-12">
                <div className="form-check m-0 me-2 mb-2 mt-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="currentCompany"
                  />
                  <label
                    className="form-checkbox-custom form-check-label ps-3"
                    htmlFor="currentCompany"
                  >
                    <h6 className="mb-0 text-nowrap fs-6 fw-normal">
                      Show current company in my Intro
                    </h6>
                  </label>
                </div>
              </div>
            </>
          )} */}
          <div className="col-12 col-md-12 text-end mt-1">
            <button
              disabled={popupLoder}
              type="submit"
              className="btn btn-primary"
            >
              {popupLoder ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = ({ profileUpdate }) => {
  const {
    profileUpdateModalVisible,
    profileUpdateType,
    userInfo,
    relatedJobRoles
  } = profileUpdate
  return {
    profileUpdateModalVisible,
    profileUpdateType,
    userInfo,
    relatedJobRoles
  }
}
const mapDispatchToProps = {
  hideProfileUpdateModal
}

export default connect(mapStateToProps, mapDispatchToProps)(EditIntro)
