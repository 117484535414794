import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  profileUpdateModalVisible: false,
  profileUpdateType: null,
  userInfo: null,
  education: null,
  experience: null,
  relatedJobRoles: null
}

export const ProfileUpdateModalSlice = createSlice({
  name: 'profileUpdate',
  initialState,
  reducers: {
    showProfileUpdateModal: (state, action) => {
      const {
        profileUpdateModalVisible,
        profileUpdateType,
        userInfo,
        education,
        experience,
        relatedJobRoles
      } = action.payload
      console.log('action payload: ', action.payload)

      state.profileUpdateModalVisible = profileUpdateModalVisible
      state.profileUpdateType = profileUpdateType
      state.userInfo = userInfo
      state.education = education
      state.experience = experience
      state.relatedJobRoles = relatedJobRoles
    },
    hideProfileUpdateModal: (state, action) => {
      state.profileUpdateModalVisible = false
      state.profileUpdateType = null
      state.userInfo = null
    }
  }
})

export const { showProfileUpdateModal, hideProfileUpdateModal } =
  ProfileUpdateModalSlice.actions

export default ProfileUpdateModalSlice.reducer
