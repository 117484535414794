import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { hideProfileUpdateModal } from '../../../features/popup/ProfileUpdateModalSlice'
import AddEducation from './Forms/AddEducation'
import AddPosition from './Forms/AddPosition'
import EditIntro from './Forms/EditIntro'
import EditPersonalInfo from './Forms/EditPersonalInfo'
import UpdateSkills from './Forms/UpdateSkills'

const CandidateProfileUpdateModal = ({
  profileUpdateModalVisible,
  profileUpdateType,
  hideProfileUpdateModal,
  userInfo,
  authToken,
  education,
  experience,
  relatedJobRoles
}) => {
  //   console.log('userInfo inside the modal comp. : ', userInfo)
  //   console.log('userInfo inside the modal comp. education : ', education)
  //   console.log('user experience Data in update modal : ', experience)
  // console.log('user relatedJobRoles Data in update modal : ', relatedJobRoles)

  return (
    <div>
      <Modal
        show={profileUpdateModalVisible}
        backdrop="static"
        centered
        onHide={() => {
          hideProfileUpdateModal()
        }}
      >
        <Modal.Body>
          <div
            class="d-flex justify-content-between align-items-center mb-2"
            onClick={() => {
              hideProfileUpdateModal()
            }}
          >
            <h3 class="mb-0 fs-5">{profileUpdateType}</h3>
            <span class="fs-3 cursor-pointer">
              <ion-icon name="close-outline"></ion-icon>
            </span>
          </div>
          {profileUpdateType == 'Add Education' && (
            <AddEducation userInfo={userInfo} education={education} />
          )}
          {profileUpdateType == 'Add Position' && (
            <AddPosition
              userInfo={userInfo}
              authToken={authToken}
              experience={experience}
            />
          )}
          {profileUpdateType == 'Edit Intro' && (
            <EditIntro userInfo={userInfo} relatedJobRoles={relatedJobRoles} />
          )}
          {profileUpdateType == 'Edit Personal Information' && (
            <EditPersonalInfo userInfo={userInfo} />
          )}
          {profileUpdateType == 'Edit Skills' && (
            <UpdateSkills userInfo={userInfo} />
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

const mapStateToProps = ({ profileUpdate }) => {
  const {
    profileUpdateModalVisible,
    profileUpdateType,
    userInfo,
    authToken,
    education,
    experience,
    relatedJobRoles
  } = profileUpdate
  return {
    profileUpdateModalVisible,
    profileUpdateType,
    userInfo,
    authToken,
    education,
    experience,
    relatedJobRoles
  }
}

const mapDispatchToProps = {
  hideProfileUpdateModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateProfileUpdateModal)
