import React, { useState, useEffect } from 'react'
import {
  GetAllPayments,
  UpdateUserVspStatus
} from '../../features/constants/apis' // Correct API functions
// import { Row, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { FaArrowLeft, FaCalendarAlt } from 'react-icons/fa'
import DataTable, { createTheme } from 'react-data-table-component'
import { connect } from 'react-redux'
import Header from '../layout/Header/Header'
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Accordion,
  Badge,
  Modal
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import TablePagination from '../layout/ServerSide/components/TablePagination'

const Payments = ({ authToken }) => {
  const navigate = useNavigate()

  const [allUsers, setAllUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  // useEffect(() => {
  //     const fetchData = async () => {
  //         setLoading(true);
  //         try {
  //             const response = await GetAllPayments(authToken); // Assuming API returns the 'data' object with users
  //             setAllUsers(response);  // Set the users data from the response
  //             console.log("payments data: ", response);
  //         } catch (error) {
  //             console.error('Error fetching users:', error);
  //         } finally {
  //             setLoading(false);
  //         }
  //     };
  //     fetchData();
  // }, [authToken]);

  useEffect(() => {
    fetchData() // Fetch initial data
  }, [authToken])

  const handleSearch = () => {
    fetchData(startDate, endDate, selectedStatus)
  }

  const fetchData = async (startDate = null, endDate = null, status = '') => {
    setLoading(true)
    try {
      const response = await GetAllPayments(authToken, startDate, endDate) // Modify your API to accept these parameters
      // Sort the response data by 'CreatedOn' or any other field in descending order
      const sortedData = response.sort(
        (a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn)
      )
      setAllUsers(sortedData) // Set the sorted data
      console.log('payments data: ', sortedData)
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }

  // Function to handle VSP status update for a user

  const handleUpdateVspStatus = async userID => {
    const currentUser = allUsers.find(user => user.ID === userID) // Find the current user
    if (!currentUser) return

    const currentStatus = currentUser.VspStatus // Get the current VspStatus
    const newStatus = !currentStatus // Toggle the status

    try {
      setLoading(true)
      await UpdateUserVspStatus(authToken, userID) // Call the API to update the status

      // Update the local state to reflect the new status
      setAllUsers(prevUsers =>
        prevUsers.map(user =>
          user.ID === userID ? { ...user, VspStatus: newStatus } : user
        )
      )

      setShowModal(false) // Close the modal after the update
    } catch (error) {
      console.error('Error updating VSP status:', error)
    } finally {
      setLoading(false) // End the loading state
    }
  }

  const handelOpenModal = (ID, currentStatus) => {
    setSelectedUser({ ID: ID, VspStatus: currentStatus })
    setShowModal(true)
  }
  const handelCloseModal = () => {
    setShowModal(false)
    setSelectedUser(null)
  }

  const desiredColumns = [
    {
      name: 'Date',
      selector: row => moment(row.CreatedOn).fromNow(), // Get the ID directly
      width: '150px',
      sortable: true
    },
    {
      name: 'Name',
      selector: row => (
        <span
          style={{ cursor: 'pointer', color: 'blue' }} // Make it look clickable
          onClick={() =>
            navigate(`/candidate-profile-ro?candidateID=${row.User_ID}`)
          } // Navigate to user-profile with User_ID
        >
          {row.FullName}
        </span>
      ),
      width: '100px',
      sortable: true
    },
    // {
    //     name: 'User_ID',
    //     selector: (row) => row.User_ID, // Correctly map FullName
    //     width: '250px',
    //     sortable: true,
    // },
    {
      name: 'Status',
      selector: row => row.Status, // Correctly map Phone number
      width: '180px',
      sortable: true
    },
    {
      name: 'Total Amount',
      selector: row => row.TotalAmount,
      width: '120px',
      wrap: true,
      sortable: true
    },
    {
      name: 'Order ID',
      selector: row => row.OrderID,
      width: '180px',
      wrap: true,
      sortable: true
    },
    // {
    //     name: 'payment Status Code',
    //     selector: (row) => row.paymentStatusCode,
    //     width: '180px',
    //     wrap: true,
    //     sortable: true,
    // },
    {
      name: 'Payment Success Response',
      selector: row => row.PaymentSuccessResponse,
      width: '180px',
      wrap: true,
      sortable: true
    },
    // {
    //     name: 'Payment Fail Response',
    //     selector: (row) => row.PaymentFailResponse,
    //     width: '180px',
    //     wrap: true,
    //     sortable: true,
    // },
    {
      name: 'Payment Method',
      selector: row => row.PaymentMethod,
      width: '180px',
      wrap: true,
      sortable: true
    },
    // {
    //     name: 'Payment Response',
    //     selector: (row) => typeof(row.PaymentResponse?.data) === 'object' ? JSON.stringify(row.PaymentResponse?.data) : null,
    //     width: '180px',
    //     wrap: true,
    //     sortable: true,
    // },

    // {
    //     name: 'Payment Response',
    //     selector: (row) => row.FullName,
    //     width: '180px',
    //     wrap: true,
    //     sortable: true,
    // },
    {
      name: 'Currency Code',
      selector: row => row.CurrencyCode,
      width: '180px',
      wrap: true,
      sortable: true
    },
    // {
    //     name: 'Notes',
    //     selector: (row) => row.FullName,
    //     width: '180px',
    //     wrap: true,
    //     sortable: true,
    // },
    {
      name: 'Payment Card Type',
      selector: row => row.PaymentCardType,
      width: '180px',
      wrap: true,
      sortable: true
    }
    // {
    //     name: 'Applied To',
    //     selector: (row) => row.AppliedTo,
    //     width: '180px',
    //     wrap: true,
    //     sortable: true,
    // },

    // {
    //     name: 'Edit',
    //     selector: (row) => (
    //         <Button
    //             variant={row.VspStatus ? 'outline-primary' : 'primary'}
    //             size="sm"
    //             onClick={() => handelOpenModal(row.ID)}
    //             // disabled={row.VspStatus}
    //         >
    //             {row.VspStatus ? 'Verified' : 'Pending'}
    //         </Button>
    //     ),
    //     width: '120px',
    //     margin:"20px"
    // },
  ]

  createTheme(
    'solarized',
    {
      background: {
        default: 'transparent'
      },
      context: {
        background: '#CDFFD8',
        text: '#FFFFFF'
      },
      divider: {
        default: '#45A994'
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)'
      }
    },
    'light'
  )

  return (
    <>
      <Header />
      <div id="appCapsule">
        <div className="section full py-4 mx-5 px-3">
          <div className="container-fluid">
            <Button variant="primary mb-2 ">
              <Link to="/" className="my-2 text-white">
                <FaArrowLeft
                  style={{ width: '15px', height: '15px', marginRight: '7px' }}
                />
                Go to Dashboard
              </Link>
            </Button>
            <div
              className="d-flex justify-content-between"
              style={{ display: 'flex' }}
            >
              <div>
                <h1 className="title text-dark ">Payments</h1>
              </div>
              <div className="d-flex">
                <div>
                  <FaCalendarAlt
                    style={{
                      width: '15px',
                      height: '15px',
                      color: '#378b7a',
                      marginRight: '5px'
                    }}
                  />
                  <DatePicker
                    className="ms-1"
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    placeholderText="From Date"
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
                <div>
                  <FaCalendarAlt
                    style={{
                      width: '15px',
                      height: '15px',
                      color: '#378b7a',
                      marginRight: '5px',
                      marginLeft: '20px'
                    }}
                  />

                  <DatePicker
                    className="ms-1"
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    placeholderText="To Date"
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
                <Button
                  variant="primary"
                  className="ms-2"
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="section full mt-2 mb-4">
          <div className="container-fluid">
            <Row className="justify-content-between my-3 mx-5">
              <div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <DataTable
                    className="justify-content-center"
                    columns={desiredColumns}
                    data={allUsers}
                    striped={true}
                    theme="solarized"
                    pagination
                    paginationPerPage={10}
                  />
                )}
              </div>
            </Row>
            <div></div>
          </div>
        </div>
      </div>

      {/* confirmation modal is wriiten here  */}
      <Modal show={showModal} onHide={handelCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm VSP Status Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are your Sure you want to
          {selectedUser?.VspStatus
            ? ' mark this user as Pending'
            : 'verify this user for VSP'}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handelCloseModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleUpdateVspStatus(selectedUser?.ID)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const mapStateToProps = ({ auth }) => {
  const { authToken } = auth
  return {
    authToken
  }
}

export default connect(mapStateToProps)(Payments)
