import React from 'react';
import { connect } from 'react-redux';
import VerifiedBlueLogoMark from '../images/VerifiedBlueLogoMark.svg';
import { Dropdown, Button, InputGroup } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

const HiringKanban = () => {
    return (
        <>
            <div style={{ backgroundColor: '#f5f5f5' }}>
                <div className='container'>
                    <div class="pt-2 d-flex">

                        <InputGroup className="mb-1 me-1">

                            <CreatableSelect
                                placeholder="Search for a job position"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                // isClearable
                                components={{
                                    DropdownIndicator: () => null, // Removes dropdown arrow
                                    IndicatorSeparator: () => null, // Removes separator line
                                }}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderLeft: "none",
                                        borderColor: "gainsboro",
                                        borderRight: "solid 1px gainsboro",
                                        height: "36px",
                                    }),
                                }}
                            />
                        </InputGroup>
                    </div>
                    <div class="d-flex w-100 overflow-scroll">
                        <button class="btn bg-white text-nowrap text-black me-1 custom-btn-checkbox" for="btn-check-3-outlined" style={{ border: '1px solid gainsboro', borderRadius: '5px', height: '36px' }}>
                            <span class="me-1">Junior Software Developer</span>
                            <span class="badge border text-black">8</span>
                        </button>

                        <button class="btn bg-white text-nowrap text-black me-1 custom-btn-checkbox" for="btn-check-3-outlined" style={{ border: '1px solid gainsboro', borderRadius: '5px', height: '36px' }}>
                            <span class="me-1">Graphic Designer</span>
                            <span class="badge border text-black">4</span>
                        </button>

                        <button class="btn bg-white text-nowrap text-black me-1 custom-btn-checkbox" for="btn-check-3-outlined" style={{ border: '1px solid gainsboro', borderRadius: '5px', height: '36px' }}>
                            <span class="me-1">Receptionist</span>
                            <span class="badge border text-black">6</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="container mt-2">
                <div class="row">
                    <div class="col-12 col-md-6 d-flex align-items-center mb-2">
                        <h3 className="mb-0">All Candidates</h3>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                        <div class="input-group mb-3 me-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text text-secondary border" id="basic-addon1" style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: 'none'}}>
                                    <ion-icon name="search-outline"></ion-icon>
                                </span>
                            </div>
                            <input type="text" class="form-control form-control-sm ps-0" placeholder="Search Name" aria-label="Search Name" aria-describedby="basic-addon1" style={{borderLeft: 'none'}} />
                        </div>
                        <Dropdown>
                            <Button variant="primary-outline" className="btn btn-outline-primary text-nowrap text-black me-1 p-0" style={{ background: 'white', border: '1px solid gainsboro', borderRadius: '5px' }}>
                                <Dropdown.Toggle variant="primary-outline" className="btn btn-outline-primary text-nowrap text-black me-1 border-0 pe-0" id="dropdown-basic" style={{ background: 'transparent', border: '1px solid gainsboro', borderRadius: '5px', height: '36px' }}>
                                Last 2 months <ion-icon name="chevron-down-outline" class="fs-6 me-0" style={{paddingTop: '2px', marginLeft: '3px'}}></ion-icon>
                                </Dropdown.Toggle>
                            </Button>

                            <Dropdown.Menu className="position-fixed" style={{position: 'fixed', maxHeight: '250px', overflow: 'scroll'}}>
                                <Dropdown.Item>Last 7 days</Dropdown.Item>
                                <Dropdown.Item>Last Week</Dropdown.Item>
                                <Dropdown.Item>Last month</Dropdown.Item>
                                <Dropdown.Item>Last 2 month</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Button variant="primary-outline" className="btn btn-outline-primary text-nowrap text-black me-1 p-0" style={{ background: 'white', border: '1px solid gainsboro', borderRadius: '5px' }}>
                                <Dropdown.Toggle variant="primary-outline" className="btn btn-outline-primary text-nowrap text-black border-0 p-0 m-0" id="dropdown-basic" style={{ background: 'transparent', border: '1px solid gainsboro', borderRadius: '5px', height: '36px' }}>
                                <ion-icon name="ellipsis-vertical-outline" class="fs-6 m-0 px-2" style={{paddingTop: '2px', marginLeft: '3px'}}></ion-icon>
                                </Dropdown.Toggle>
                            </Button>

                            <Dropdown.Menu className="position-fixed" style={{position: 'fixed', maxHeight: '250px', overflow: 'scroll'}}>
                                <Dropdown.Item>Last 7 days</Dropdown.Item>
                                <Dropdown.Item>Last Week</Dropdown.Item>
                                <Dropdown.Item>Last month</Dropdown.Item>
                                <Dropdown.Item>Last 2 month</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className='row kanban-custom d-flex'>
                    <div class="w-5 p-1">
                        <ul class="p-0" style={{listStyleType: 'none'}}>
                            <li class="text-black d-flex justify-content-between mb-2" style={{padding: '12px', borderRadius: '8px', background: '#FEF3C7'}}>
                                <div class="text-nowrap" style={{fontSize: '14px'}}>Shortlisted</div>
                                <span class="badge" style={{backgroundColor: '#D97706'}}>4</span>
                            </li>
                            <li class="mb-2">
                                <div class="border d-flex overflow-hidden" style={{padding: '12px', borderRadius: '8px'}}>
                                    <div style={{minWidth: '40px'}}>
                                        <div style={{backgroundImage: 'URL(https://s3-alpha-sig.figma.com/img/5d96/8711/3e4e6a8515aaa37d3320a648df02d4ca?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=Exu6mwLmDa486TFVI8kQeIPAUGBU0JG2xje94DQC5ekbDs26EbygzPYJ4aZ0NeNeFndPHeeZnopCuOdJRpNS7Ir6shabeG11vCACraPnbRJkmILfvvDllc7zP2~5h6KkdmiuQlbn9UcAvHGpi-y6Ic3u5HGWzCTwt3iXUOOubhV2dFw51-MQ5V4daqmpr9V50gAEFaoFWk3mIQahUf9w0WF~2riGqaaXVKhnYyPObgJXJr3I76t4gzKmg5Kj7xzpcve2-nKwoak2C61jfaXRS97rgogCknGmSkiqVEdViVfwh9JcUjA4pZIGG4RYQWjy~rkMhEHySWsQY4spCo7mAw__)', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', height: '40px', width: '40px'}}>

                                        </div>
                                    </div>
                                    <div class="d-flex flex-column ms-1">
                                        <h4 class="mb-0 fw-bold d-flex text-nowrap">Jithin T&nbsp;<img src={VerifiedBlueLogoMark} width="15px" /> </h4>
                                        <h6 class="text-secondary mb-0">Junior Data Engineer</h6>
                                    </div>
                                </div>
                            </li>
                            <li class="mb-2">
                                <div class="border d-flex overflow-hidden" style={{padding: '12px', borderRadius: '8px'}}>
                                    <div style={{minWidth: '40px'}}>
                                        <div style={{backgroundImage: 'URL(https://s3-alpha-sig.figma.com/img/54a9/594f/0d9b7308c81881e115622514fcbd7bb5?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=hr2cI6fIyBlIcC8156YcXfNFGB3EUoPuzsSJO9XBOl6ToITUGOGIJ5wVZw9YNayBWmpepi1u29CXv-B7XFd7e3H4GUb6jmpeoHgQXF8xQL4vGnIm5zDVGO9YJBfQ5WRBNtUEURuBo53cScfQ2n8TgDiX6cBko6HHNOSyxDo3~0pQH1pFBawh-t2Gf9~SieZdHNIbhjcZ7EOEv4mR9vjTiH3M6NG0UF8Q1SG~MA--D9Rhy7pHJA792RBBbEAOf5h3XCDILfISKWZUv2S77zXyqvm9-CJN8lg6NrkI6epaXJ8-Yp2U898-jPySnlNwkxgOdbGQGEPBRPFvNx1Q0vLP4g__)', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', height: '40px', width: '40px'}}>

                                        </div>
                                    </div>
                                    <div class="d-flex flex-column ms-1">
                                        <h4 class="mb-0 fw-bold d-flex text-nowrap">Mohammad Zeeshan&nbsp;<img src={VerifiedBlueLogoMark} width="15px" /> </h4>
                                        <h6 class="text-secondary mb-0">Junior Data Engineer</h6>
                                    </div>
                                </div>
                            </li>
                            <li class="mb-2">
                                <div class="border d-flex overflow-hidden" style={{padding: '12px', borderRadius: '8px'}}>
                                    <div style={{minWidth: '40px'}}>
                                        <div style={{backgroundImage: 'URL(https://s3-alpha-sig.figma.com/img/dc2d/80ff/e1f546f6e8c996f78fea5c3ac9ec3e72?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=pqYxNoNhlXsTs0MUH2loha1W9cVf2toMmREAUogg~H~-OcbJdwnCG0OcR3yJD-6lK4WH1VatJ-PZke1peXF6YdwfMhukC7nqC1uiremiz5mPnpALJ4S~V2mQsTO~IEslUt-8gwYVpRtehJ3DS~sZmAZGpupa4agvuQAPUbLjTKk3Fe1TBGGHGQJzoLQpAnI~L-oCA8tcCHN3JYbL~oZkdnquT54lFs0G0JTBs4PRXQeKLOSQHKPVqAiHPPS3XbWZ4kezA6UY1O498sXx-Ck76L2ZpomUmBbQU~GxOX023wleasV9hvJng322CMtwIop61Fs9gelhK8qi7Vvh7koCQA__)', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', height: '40px', width: '40px'}}>

                                        </div>
                                    </div>
                                    <div class="d-flex flex-column ms-1">
                                        <h4 class="mb-0 fw-bold d-flex text-nowrap">Bibhu Rajbangshi&nbsp;<img src={VerifiedBlueLogoMark} width="15px" /> </h4>
                                        <h6 class="text-secondary mb-0">Graphic Designer</h6>
                                    </div>
                                </div>
                            </li>
                            <li class="mb-2">
                                <div class="border d-flex overflow-hidden" style={{padding: '12px', borderRadius: '8px'}}>
                                    <div style={{minWidth: '40px'}}>
                                        <div style={{backgroundImage: 'URL(https://s3-alpha-sig.figma.com/img/426c/dc26/2d5611c5a5b81222ae4e26857bdfe613?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=ZHvdrb70pSVxHvAVO1l043Ye1OMxH~~5zmv0XT7ehNn8164Q9zoQK~j62DhW2V991xBrpV9mzIfuUZa4wdi48gIIGBYm1l1kRZ3lHkSL92841Pwey2ea1Zv4YmhH2EsFAf1kVbWcC7GjaGUKY94aeLymYEK0xBanESGHQvC7CEgda4ZI9qgrHjWzbp67iepxwtMFU329u0OV1jF4f-rSROpjD18hHu3jlFMVz-GhLMKEHP9vhD5vkbKuTfp5AaOc23JOmRMpPaseqFEnAgTBW16L2vLexTpoZpIa5ZSexFTdyELI9-P7FchzVe6EHw69fTPAXYx7~4PdWUfgt9jklw__)', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', height: '40px', width: '40px'}}>

                                        </div>
                                    </div>
                                    <div class="d-flex flex-column ms-1">
                                        <h4 class="mb-0 fw-bold d-flex text-nowrap">Raghu Nadh</h4>
                                        <h6 class="text-secondary mb-0">Receptionist</h6>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="w-5 p-1">
                        <ul class="p-0" style={{listStyleType: 'none'}}>
                            <li class="text-black d-flex justify-content-between mb-2" style={{padding: '12px', borderRadius: '8px', background: '#DBEAFE'}}>
                                <div class="text-nowrap" style={{fontSize: '14px'}}>In Communication</div>
                                <span class="badge" style={{backgroundColor: '#2563EB'}}>1</span>
                            </li>
                            <li class="mb-2">
                                <div class="border d-flex overflow-hidden" style={{padding: '12px', borderRadius: '8px'}}>
                                    <div style={{minWidth: '40px'}}>
                                        <div style={{backgroundImage: 'URL(https://s3-alpha-sig.figma.com/img/5d96/8711/3e4e6a8515aaa37d3320a648df02d4ca?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=Exu6mwLmDa486TFVI8kQeIPAUGBU0JG2xje94DQC5ekbDs26EbygzPYJ4aZ0NeNeFndPHeeZnopCuOdJRpNS7Ir6shabeG11vCACraPnbRJkmILfvvDllc7zP2~5h6KkdmiuQlbn9UcAvHGpi-y6Ic3u5HGWzCTwt3iXUOOubhV2dFw51-MQ5V4daqmpr9V50gAEFaoFWk3mIQahUf9w0WF~2riGqaaXVKhnYyPObgJXJr3I76t4gzKmg5Kj7xzpcve2-nKwoak2C61jfaXRS97rgogCknGmSkiqVEdViVfwh9JcUjA4pZIGG4RYQWjy~rkMhEHySWsQY4spCo7mAw__)', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', height: '40px', width: '40px'}}>

                                        </div>
                                    </div>
                                    <div class="d-flex flex-column ms-1">
                                        <h4 class="mb-0 fw-bold d-flex text-nowrap">Jithin T&nbsp;<img src={VerifiedBlueLogoMark} width="15px" /> </h4>
                                        <h6 class="text-secondary mb-0">Junior Data Engineer</h6>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="w-5 p-1">
                        <ul class="p-0" style={{listStyleType: 'none'}}>
                            <li class="text-black d-flex justify-content-between mb-2" style={{padding: '12px', borderRadius: '8px', background: '#F3E8FF'}}>
                                <div class="text-nowrap" style={{fontSize: '14px'}}>Interview Completed</div>
                                <span class="badge" style={{backgroundColor: '#9333EA'}}>3</span>
                            </li>
                            <li class="mb-2">
                                <div class="border d-flex overflow-hidden" style={{padding: '12px', borderRadius: '8px'}}>
                                    <div style={{minWidth: '40px'}}>
                                        <div style={{backgroundImage: 'URL(https://s3-alpha-sig.figma.com/img/5d96/8711/3e4e6a8515aaa37d3320a648df02d4ca?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=Exu6mwLmDa486TFVI8kQeIPAUGBU0JG2xje94DQC5ekbDs26EbygzPYJ4aZ0NeNeFndPHeeZnopCuOdJRpNS7Ir6shabeG11vCACraPnbRJkmILfvvDllc7zP2~5h6KkdmiuQlbn9UcAvHGpi-y6Ic3u5HGWzCTwt3iXUOOubhV2dFw51-MQ5V4daqmpr9V50gAEFaoFWk3mIQahUf9w0WF~2riGqaaXVKhnYyPObgJXJr3I76t4gzKmg5Kj7xzpcve2-nKwoak2C61jfaXRS97rgogCknGmSkiqVEdViVfwh9JcUjA4pZIGG4RYQWjy~rkMhEHySWsQY4spCo7mAw__)', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', height: '40px', width: '40px'}}>

                                        </div>
                                    </div>
                                    <div class="d-flex flex-column ms-1">
                                        <h4 class="mb-0 fw-bold d-flex text-nowrap">Jithin T&nbsp;<img src={VerifiedBlueLogoMark} width="15px" /> </h4>
                                        <h6 class="text-secondary mb-0">Junior Data Engineer</h6>
                                    </div>
                                </div>
                            </li>
                            <li class="mb-2">
                                <div class="border d-flex overflow-hidden" style={{padding: '12px', borderRadius: '8px'}}>
                                    <div style={{minWidth: '40px'}}>
                                        <div style={{backgroundImage: 'URL(https://s3-alpha-sig.figma.com/img/dc2d/80ff/e1f546f6e8c996f78fea5c3ac9ec3e72?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=pqYxNoNhlXsTs0MUH2loha1W9cVf2toMmREAUogg~H~-OcbJdwnCG0OcR3yJD-6lK4WH1VatJ-PZke1peXF6YdwfMhukC7nqC1uiremiz5mPnpALJ4S~V2mQsTO~IEslUt-8gwYVpRtehJ3DS~sZmAZGpupa4agvuQAPUbLjTKk3Fe1TBGGHGQJzoLQpAnI~L-oCA8tcCHN3JYbL~oZkdnquT54lFs0G0JTBs4PRXQeKLOSQHKPVqAiHPPS3XbWZ4kezA6UY1O498sXx-Ck76L2ZpomUmBbQU~GxOX023wleasV9hvJng322CMtwIop61Fs9gelhK8qi7Vvh7koCQA__)', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', height: '40px', width: '40px'}}>

                                        </div>
                                    </div>
                                    <div class="d-flex flex-column ms-1">
                                        <h4 class="mb-0 fw-bold d-flex text-nowrap">Bibhu Rajbangshi&nbsp;<img src={VerifiedBlueLogoMark} width="15px" /> </h4>
                                        <h6 class="text-secondary mb-0">Graphic Designer</h6>
                                    </div>
                                </div>
                            </li>
                            <li class="mb-2">
                                <div class="border d-flex overflow-hidden" style={{padding: '12px', borderRadius: '8px'}}>
                                    <div style={{minWidth: '40px'}}>
                                        <div style={{backgroundImage: 'URL(https://s3-alpha-sig.figma.com/img/426c/dc26/2d5611c5a5b81222ae4e26857bdfe613?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=ZHvdrb70pSVxHvAVO1l043Ye1OMxH~~5zmv0XT7ehNn8164Q9zoQK~j62DhW2V991xBrpV9mzIfuUZa4wdi48gIIGBYm1l1kRZ3lHkSL92841Pwey2ea1Zv4YmhH2EsFAf1kVbWcC7GjaGUKY94aeLymYEK0xBanESGHQvC7CEgda4ZI9qgrHjWzbp67iepxwtMFU329u0OV1jF4f-rSROpjD18hHu3jlFMVz-GhLMKEHP9vhD5vkbKuTfp5AaOc23JOmRMpPaseqFEnAgTBW16L2vLexTpoZpIa5ZSexFTdyELI9-P7FchzVe6EHw69fTPAXYx7~4PdWUfgt9jklw__)', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', height: '40px', width: '40px'}}>

                                        </div>
                                    </div>
                                    <div class="d-flex flex-column ms-1">
                                        <h4 class="mb-0 fw-bold d-flex text-nowrap">Raghu Nadh</h4>
                                        <h6 class="text-secondary mb-0">Receptionist</h6>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="w-5 p-1">
                        <ul class="p-0" style={{listStyleType: 'none'}}>
                            <li class="text-black d-flex justify-content-between mb-2" style={{padding: '12px', borderRadius: '8px', background: '#D1FAE5'}}>
                                <div class="text-nowrap" style={{fontSize: '14px'}}>Hired</div>
                                <span class="badge" style={{backgroundColor: '#358D77'}}>1</span>
                            </li>
                            <li class="mb-2">
                                <div class="border d-flex overflow-hidden" style={{padding: '12px', borderRadius: '8px'}}>
                                    <div style={{minWidth: '40px'}}>
                                        <div style={{backgroundImage: 'URL(https://s3-alpha-sig.figma.com/img/dc2d/80ff/e1f546f6e8c996f78fea5c3ac9ec3e72?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=pqYxNoNhlXsTs0MUH2loha1W9cVf2toMmREAUogg~H~-OcbJdwnCG0OcR3yJD-6lK4WH1VatJ-PZke1peXF6YdwfMhukC7nqC1uiremiz5mPnpALJ4S~V2mQsTO~IEslUt-8gwYVpRtehJ3DS~sZmAZGpupa4agvuQAPUbLjTKk3Fe1TBGGHGQJzoLQpAnI~L-oCA8tcCHN3JYbL~oZkdnquT54lFs0G0JTBs4PRXQeKLOSQHKPVqAiHPPS3XbWZ4kezA6UY1O498sXx-Ck76L2ZpomUmBbQU~GxOX023wleasV9hvJng322CMtwIop61Fs9gelhK8qi7Vvh7koCQA__)', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', height: '40px', width: '40px'}}>

                                        </div>
                                    </div>
                                    <div class="d-flex flex-column ms-1">
                                        <h4 class="mb-0 fw-bold d-flex text-nowrap">Bibhu Rajbangshi&nbsp;<img src={VerifiedBlueLogoMark} width="15px" /> </h4>
                                        <h6 class="text-secondary mb-0">Graphic Designer</h6>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="w-5 p-1">
                        <ul class="p-0" style={{listStyleType: 'none'}}>
                            <li class="text-black d-flex justify-content-between mb-2" style={{padding: '12px', borderRadius: '8px', background: '#FEE2E2'}}>
                                <div class="text-nowrap" style={{fontSize: '14px'}}>Rejected</div>
                                <span class="badge" style={{backgroundColor: '#DC2626'}}>3</span>
                            </li>
                            <li class="mb-2">
                                <div class="border d-flex overflow-hidden" style={{padding: '12px', borderRadius: '8px'}}>
                                    <div style={{minWidth: '40px'}}>
                                        <div style={{backgroundImage: 'URL(https://s3-alpha-sig.figma.com/img/5d96/8711/3e4e6a8515aaa37d3320a648df02d4ca?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=Exu6mwLmDa486TFVI8kQeIPAUGBU0JG2xje94DQC5ekbDs26EbygzPYJ4aZ0NeNeFndPHeeZnopCuOdJRpNS7Ir6shabeG11vCACraPnbRJkmILfvvDllc7zP2~5h6KkdmiuQlbn9UcAvHGpi-y6Ic3u5HGWzCTwt3iXUOOubhV2dFw51-MQ5V4daqmpr9V50gAEFaoFWk3mIQahUf9w0WF~2riGqaaXVKhnYyPObgJXJr3I76t4gzKmg5Kj7xzpcve2-nKwoak2C61jfaXRS97rgogCknGmSkiqVEdViVfwh9JcUjA4pZIGG4RYQWjy~rkMhEHySWsQY4spCo7mAw__)', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', height: '40px', width: '40px'}}>

                                        </div>
                                    </div>
                                    <div class="d-flex flex-column ms-1">
                                        <h4 class="mb-0 fw-bold d-flex text-nowrap">Jithin T&nbsp;<img src={VerifiedBlueLogoMark} width="15px" /> </h4>
                                        <h6 class="text-secondary mb-0">Junior Data Engineer</h6>
                                    </div>
                                </div>
                            </li>
                            <li class="mb-2">
                                <div class="border d-flex overflow-hidden" style={{padding: '12px', borderRadius: '8px'}}>
                                    <div style={{minWidth: '40px'}}>
                                        <div style={{backgroundImage: 'URL(https://s3-alpha-sig.figma.com/img/dc2d/80ff/e1f546f6e8c996f78fea5c3ac9ec3e72?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=pqYxNoNhlXsTs0MUH2loha1W9cVf2toMmREAUogg~H~-OcbJdwnCG0OcR3yJD-6lK4WH1VatJ-PZke1peXF6YdwfMhukC7nqC1uiremiz5mPnpALJ4S~V2mQsTO~IEslUt-8gwYVpRtehJ3DS~sZmAZGpupa4agvuQAPUbLjTKk3Fe1TBGGHGQJzoLQpAnI~L-oCA8tcCHN3JYbL~oZkdnquT54lFs0G0JTBs4PRXQeKLOSQHKPVqAiHPPS3XbWZ4kezA6UY1O498sXx-Ck76L2ZpomUmBbQU~GxOX023wleasV9hvJng322CMtwIop61Fs9gelhK8qi7Vvh7koCQA__)', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', height: '40px', width: '40px'}}>

                                        </div>
                                    </div>
                                    <div class="d-flex flex-column ms-1">
                                        <h4 class="mb-0 fw-bold d-flex text-nowrap">Bibhu Rajbangshi&nbsp;<img src={VerifiedBlueLogoMark} width="15px" /> </h4>
                                        <h6 class="text-secondary mb-0">Graphic Designer</h6>
                                    </div>
                                </div>
                            </li>
                            <li class="mb-2">
                                <div class="border d-flex overflow-hidden" style={{padding: '12px', borderRadius: '8px'}}>
                                    <div style={{minWidth: '40px'}}>
                                        <div style={{backgroundImage: 'URL(https://s3-alpha-sig.figma.com/img/426c/dc26/2d5611c5a5b81222ae4e26857bdfe613?Expires=1743984000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=ZHvdrb70pSVxHvAVO1l043Ye1OMxH~~5zmv0XT7ehNn8164Q9zoQK~j62DhW2V991xBrpV9mzIfuUZa4wdi48gIIGBYm1l1kRZ3lHkSL92841Pwey2ea1Zv4YmhH2EsFAf1kVbWcC7GjaGUKY94aeLymYEK0xBanESGHQvC7CEgda4ZI9qgrHjWzbp67iepxwtMFU329u0OV1jF4f-rSROpjD18hHu3jlFMVz-GhLMKEHP9vhD5vkbKuTfp5AaOc23JOmRMpPaseqFEnAgTBW16L2vLexTpoZpIa5ZSexFTdyELI9-P7FchzVe6EHw69fTPAXYx7~4PdWUfgt9jklw__)', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', height: '40px', width: '40px'}}>

                                        </div>
                                    </div>
                                    <div class="d-flex flex-column ms-1">
                                        <h4 class="mb-0 fw-bold d-flex text-nowrap">Raghu Nadh</h4>
                                        <h6 class="text-secondary mb-0">Receptionist</h6>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = () => {

    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(HiringKanban);