import React from "react";

const OTPInput = ({ length = 6, value = "", onChange, validateFor }) => {
  console.log(validateFor)
  const handleChange = (e, index) => {
    const inputValue = e.target.value;

    if (!isNaN(inputValue) && inputValue.length <= 1) {
      const newValue = value.split("");
      newValue[index] = inputValue;
      onChange(newValue.join("")); // Pass the updated OTP to the parent

      // Auto-focus next input
      if (inputValue && e.target.nextSibling) {
        e.target.nextSibling.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newValue = value.split("");
      newValue[index] = "";
      onChange(newValue.join(""));

      // Move focus to previous input
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").trim(); // Get the pasted value

    if (!isNaN(pastedData)) {
      const newValue = value.split("");
      for (let i = 0; i < length; i++) {
        if (pastedData[i] !== undefined) {
          newValue[i] = pastedData[i];
        }
      }
      onChange(newValue.join("")); // Update the OTP value
    }
  };



  return (
    <>
      {validateFor == 'mobile' &&
        <div style={{ display: "flex" }}>
          {Array.from({ length }, (_, index) => (
            <input
              
              key={index}
              type="tel"
              maxLength="1"
              value={value[index] || ""}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={handlePaste}
              className="otp-input"
              style={{
                width: "2.5rem",
                height: "2.5rem",
                textAlign: "center",
                fontSize: "1.5rem",
                border: "1px solid #ccc",
                borderRadius: "2px",
              }}
              required
            />
          ))}
        </div>
      }
      {validateFor == 'email' &&
        <div style={{ display: "flex" }}>
          {Array.from({ length }, (_, index) => (
            <input
              
              key={index}
              type="tel"
              maxLength="1"
              value={value[index] || ""}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={handlePaste}
              className="otp-input"
              style={{
                width: "2.5rem",
                height: "2.5rem",
                textAlign: "center",
                fontSize: "1.5rem",
                border: "1px solid #ccc",
                borderRadius: "2px",
              }}
              required
            />
          ))}
        </div>
      }
    </>
  );
};

export default OTPInput;
