import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { showAppToast, hideAppToast } from '../../features/utilities/appToastSlice';
import { requestOtp, validateOtp, backToLogin, registerUser, setCourseID, setXpLevel, addUser, updateRegistrationStep } from "../../features/auth/authSlice";
import { ToggleButton, ButtonGroup, Form, Row, Col, Badge, Button, InputGroup, Modal } from 'react-bootstrap';
import candidateBg from '../images/Frame_5.png';
import Logo from '../images/ScopeHaiLogo-two-color.png';
import graphAnalysis from '../images/graph-analysis.svg';
import Linkedin from '../images/linkedin.svg';
import FaceBook from '../images/Facebook.png';
import tick from '../images/tick.png'
import { colors } from '@mui/material';
import OTPInput from "./OTPInput";
import { ArrowRight, User, GraduationCap, BriefcaseBusiness, Check, X, ArrowLeft } from 'lucide-react';
import { text } from "@fortawesome/fontawesome-svg-core";
import CandidateSignup5 from "./RegistrationFormComponents/CandidateSignup5";
import StepperForm from "./StepperForm";
import CheckStepNumber from "./CheckStepNumber";
import SideValiDidi from "./SideValiDidi";
import userPhoto from '../images/profile-placeholder.jpeg'
import { GetAllApplicationsOfDrive, GetCandidateByID, UpdateUserProfile, deleteCandidateEducationRecord, deleteCandidateExperienceRecord, fetchAllJobRoles, fetchAllJobRolesSkills, getRemovedSkills, handelOpenToWork, postUserImage, shortlistApplicant, UserJoinStatus } from '../../features/constants/apis';
import axios from 'axios';
import Opportunity from "./Opportunity";
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../../features/utilities/appToastSlice';
import SuccessAlert from "./Modals/SuccessAlert";
import DesktopBg from '../images/candidate-login/blue-desktop-bg.svg';
import image2 from '../images/candidate-login/open-for-job-switch.svg';
import image3 from '../images/candidate-login/hired.svg';
import image4 from '../images/candidate-login/employer_hero_05.png';
import image5 from '../images/candidate-login/hired.svg';
import user1 from '../images/candidate-login/young-brunette-man-showing-fingers-up.webp'

const CandidateSignup = ({
    candidateAuth,
    ID,
    showAppToast,
    appToastVisible,
    appToastMessage,
    appToastIcon,
    appToastBackground,
    appToastPosition,
    requestOtp,
    validateOtp,
    registerUser,
    otpRequestSuccess,
    otpRequestError,
    hideSendOtpButton,
    hideSendOtpButton1,
    showEmpVerifyOtpForm,
    showRegistrationForm,
    otpValidateSuccess,
    otpValidateError,
    backToLogin,
    isVisible,
    setCourseID,
    certCourseID,
    setXpLevel,
    registerCandidate,
    empRegistrationForm,
    newShowEmpRegistrationForm,
    showEmpDashboard,
    showRegistrationStep,
    updateRegistrationStep,

    // new UI states 
    newUiCandidateLoginStep,
    newUiCandiateOtpStep,
    newUiCandidateRegistrationStep,
    newUiCandidateSkillStep,
    newUiCandidateOpportunitiesStep,
    newUiCandidateExperience,
    addUser,
    showAlert,
    message,
    verificationOtpMessage
}) => {

    if (otpValidateError) {

        console.log('OPT IS NOT VALID CONDITION TRUE', otpValidateError)
    }
    console.log('newUiCandidateRegistrationStep', newUiCandidateRegistrationStep);
    console.log('candidateAuth', candidateAuth);
    console.log('ID', ID);

    const navigate = useNavigate();

    const [imageSet, setImageSet] = useState();
    const [otp, setOtp] = useState(""); // OTP state
    const [fileName, setFileName] = useState('No file chosen');
    // login states start
    const [mobileNumber, setMobileNumber] = useState("")
    // login states end

    // registration states start
    const [gender, setGender] = useState('');
    // registration states end

    // experience states start
    const [selectedExperience, setSelectedExperience] = useState('');
    // experience states end

    ///////////////////////////////////////
    const fileInputRef = useRef(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [userInfo, setUserInfo] = useState([])
    const [fileUploaded, setFileUploaded] = useState(false);

    ///////////////////////////////////////
    const [candidateData, setCandidateData] = useState();




    //login functionality start
    const handleUserNameValidation = (e) => {
        e.preventDefault()
        console.log('form submit data : ', mobileNumber);
        const role = 'Candidate'
        // setSendOtpButton(true)
        console.log('requestOtp:', requestOtp); // Debug to check the function
        const response = requestOtp({ mobileNumber: `91${mobileNumber}`, role: role })
        console.log('response : ', response);

        // if (message) {
        //     showAlert({ alertMessage: message, alertColor: 'primary', alertVisible: true });
        // }
        //  requestOtp({ mobileNumber: `91${mobileNumber}` })
        // console.log('response : ', response);
    }
    //login functionality end


    // useEffect(() => {
    //     console.log('message useEffect :', message)
    //     showAlert({ alertMessage: message, alertColor: 'primary', alertVisible: true });

    //     setTimeout(() => {
    //         showAlert({ alertMessage: null, alertColor: null, alertVisible: false });
    //     }, 2000)
    // }, [message])

    //registration functionality start

    const handleGenderChange = (selectedGender) => {
        setGender(selectedGender);
    };
    const [getUserData, setGetUserData] = useState({
        'firstName': '',
        'lastName': '',
        'gender': '',
        'location': '',
        'image': '',
        'email': '',
        'mobileNumber': '',
        'role': 'Candidate'
    });
    const setRegisteredData = () => {
        const fullName = `${getUserData.firstName} ${getUserData.lastName}`;
        const newRegisteredData = {
            'username': fullName,
            'gender': gender,
            'location': getUserData.location,
            'image': getUserData.image,
            'email': getUserData.email,
            'mobileNumber': `91${mobileNumber}`,
            'role': getUserData.role
        }
        return newRegisteredData
    }

    const updateUserData = (e) => {
        const field = e.target.name;
        console.log('field: ', field);

        setGetUserData(prevState => ({
            ...prevState,
            [field]: e.target.value
        }));
    }

    const handleUserAdd = (e) => {
        e.preventDefault()
        console.log('handel add user fuc running')
        // setSendOtpButton(true)
        addUser({ ...setRegisteredData() })
    }
    //registration functionality end


    // experience functionality start
    const handleSelection = (experience) => {
        console.log('slected experience: ', experience);
        setSelectedExperience(experience);
    };

    const handelExperience = (e) => {
        e.preventDefault()
        // setDisableVerifyOtpButton(true)
        // const ID = 
        const step = -2;
        registerUser({ ID, step, selectedExperience })
    }

    // experience functionality end


    //OTP functionality start
    const HandleOtpValidation = (e) => {
        e.preventDefault()
        // setDisableVerifyOtpButton(true)
        const role = 'Candidate'
        validateOtp({ mobileNumber: `91${mobileNumber}`, otp, role })
    }
    //OTP functionality end


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    };

    ////////////////////////////////////////////////////////////////////////////////////
    const [uploadedImage, setUploadedImage] = useState('');

    const handleImageChange = async (e) => {
        console.log('handleImageChange fuc triggerd !!!!!');

        const file = e.target.files[0];

        if (file) {
            // Check if the file type is an image
            const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
            if (!validImageTypes.includes(file.type)) {
                alert('Please upload a valid image file (JPEG, PNG, GIF, or WEBP)');
                // Reset the input value to clear the invalid file
                e.target.value = '';

                return;
            }
        }



        console.log('Selected file:', file);
        if (file) {
            setPreviewImage(URL.createObjectURL(file));
            try {
                const imageUrl = await uploadRefImage(file); // Wait for the image URL
                console.log('uploaded image url:', imageUrl);


                console.log('Call handleSetImage with the image URL');
                await handleSetImage(imageUrl); // Call handleSetImage with the image URL
            } catch (error) {
                console.error('Error handling image change:', error);
            }
        }
    };

    const uploadRefImage = async (filePath) => {
        console.log('upload ref fuc triggerd !!!!!');

        setFileUploaded(true);
        const formData = new FormData();
        console.log('FormData:', formData);
        formData.append('inputFile', filePath);
        try {
            const res = await axios.post('https://api.allpos.in/applications/upload/scopehai', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            console.log('getting consoled  !!!!!');
            console.log('uploaded image data : ', res);

            setUploadedImage(res.data.data);
            setFileUploaded(false);
            return res.data.data; // Return the uploaded image URL
        } catch (err) {
            console.error('Error uploading file:', err);
            setFileUploaded(false);
            throw err;
        }
    };

    console.log('uploadedImage state:', uploadedImage);


    /////////////////////////////////////////////////////////////////////////////////


    const handleSetImage = async (imageUrl) => {
        console.log('handel set image fuc triggerd !!!!!');
        console.log('image URL: ', imageUrl);

        const imageUrlObject = {
            image: imageUrl
        };

        await postUserImage(ID, imageUrlObject, candidateAuth);

        setUserInfo(prevUserInfo => ({
            ...prevUserInfo,
            Image: imageUrl // Use the provided image URL
        }));

        setImageSet(true);
        setPreviewImage(null);

        fileInputRef.current.value = '';
    };
    ////////////////////////////////////////////////////////////////////////////////////


    const handleGoBack = () => {
        console.log('handel go back fuc triggerd!!!')
        const updateStep = {
            'stepNo': -1,
            'completeProfileStep': ID,
        }
        updateRegistrationStep(updateStep);

    }

    ////////////////////////////// GET CANDIDATE BY ID useEffect ///////////////////////////////////////////////

    useEffect(() => {
        console.log('useEffect is running for get by id');

        const fetchData = async () => {
            try {
                const data = await GetCandidateByID(ID, candidateAuth);
                console.log("Candidate Data:", data.data);
                setCandidateData(data.data);

                // Check if candidateData is valid before accessing properties
                if (data.data && data.data.FullName) {
                    const [firstName, ...lastNameParts] = data.data.FullName.split(' ');
                    console.log(firstName);
                    setGender(data.data.Gender)
                    setGetUserData((prevState) => ({
                        ...prevState,
                        firstName: firstName || '',
                        lastName: lastNameParts.join(' ') || '',
                        gender: data.data.gender || '',
                        location: data.data.Location || '',
                        image: data.data.Image || '',
                        email: data.data.Email || '',
                        mobileNumber: data.data.mobileNumber || '',
                        role: data.data.role || 'Candidate',
                    }));
                }
            } catch (error) {
                console.error('Error fetching candidate data:', error);
            }
        };

        fetchData();
    }, [ID, candidateAuth]);

    useEffect(() => {
        if (candidateData) {
            setSelectedExperience(candidateData.ExperienceLevel)
        }
    }, [candidateData])

    console.log('firstName:', getUserData.firstName);
    console.log('lastName:', getUserData.lastName);
    console.log('gender:', getUserData.gender);
    console.log('location:', getUserData.location);
    console.log('image:', getUserData.image);
    console.log('email:', getUserData.email);
    console.log('mobileNumber:', getUserData.mobileNumber);
    console.log('role:', getUserData.role);


    /////////////////////////////////////////////////////////////////////////////








    // console.log("newUiCandidateLoginStep:", newUiCandidateLoginStep);
    // console.log("newUiCandidateRegistrationStep:", newUiCandidateRegistrationStep);
    // console.log("IDdddddddddddddddddddddddd:", ID);

    return (
        <>



            {/* <Opportunity /> */}

            {!newUiCandidateExperience && <>

                {!newUiCandiateOtpStep && <>
                    {<>

                        {(newUiCandidateRegistrationStep === true) ?

                            <>
                                {/* candidate step 2/6 page start */}
                                <section>
                                    <div style={{ height: '100vh' }}>
                                        <div className='h-100'>
                                            <Row className='h-100 p-0 m-0' >
                                                <Col md={8} sm={12} className='getstarted-col-div h-100'>
                                                    <div>
                                                        <img src={Logo} style={{ height: '2.5rem' }} ></img>
                                                    </div>
                                                    <div className='d-flex gap-2 mt-2 ms-2'>
                                                        <div style={{ width: '100px', height: '8px', backgroundColor: '#C6E8DF', borderRadius: '5px' }}></div>
                                                        <div style={{ width: '100px', height: '8px', backgroundColor: '#358D77', borderRadius: '5px' }}></div>
                                                        <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                                        <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                                        <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                                        <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                                    </div>
                                                    <span class="ms-2" style={{ fontSize: '14px' }}>2 of 6</span>

                                                    <div className="d-flex flex-column">
                                                        <h2 className='mb-0 ms-2' style={{ marginTop: '3rem' }}>Let’s Get Started!</h2>
                                                        <lable className='ms-2' style={{ color: '#737373', text: 'start' }}>Tell us a bit about yourself to set up your profile.</lable>
                                                    </div>

                                                    <div className="ms-2 gap-3 d-flex d-none" style={{ marginTop: '2rem' }}>
                                                        <button className="btn" style={{ border: '1px solid #E5E5E5', height: '48px', paddingLeft: '8px', paddingRight: '8px', width: '171px' }}><span><img src={Linkedin} style={{ margin: '5px' }}></img></span>Fill using LinkedIn</button>
                                                        <button className="btn" style={{ border: '1px solid #E5E5E5', height: '48px', paddingLeft: '8px', paddingRight: '8px', width: '171px' }}><span><img src={FaceBook} style={{ margin: '5px', width: '24px' }}></img></span>Fill using LinkedIn</button>
                                                    </div>
                                                    <form type="submit" onSubmit={handleUserAdd}>

                                                        <div className="ms-2" style={{ marginTop: '2rem' }}>
                                                            <Row>
                                                                <Col md={6} sm={12}>
                                                                    <div className="">
                                                                        <label style={{ paddingLeft: '2px' }}>First name <span style={{ color: 'red' }}>*</span></label>
                                                                        <Form.Group className="form-group boxed" style={{}} >
                                                                            <Form.Control
                                                                                style={{ border: '1px solid #E5E5E5' }}
                                                                                type="text"
                                                                                placeholder="First name"
                                                                                name="firstName"
                                                                                value={getUserData?.firstName}
                                                                                onChange={updateUserData}
                                                                                required />
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6} sm={12}>
                                                                    <div className="">
                                                                        <label style={{ paddingLeft: '2px' }}>Last name<span style={{ color: 'red' }}>*</span></label>
                                                                        <Form.Group className="form-group boxed" style={{}} >
                                                                            <Form.Control
                                                                                style={{ border: '1px solid #E5E5E5' }}
                                                                                type="text"
                                                                                placeholder="Last name"
                                                                                name="lastName"
                                                                                value={getUserData?.lastName}
                                                                                onChange={updateUserData}
                                                                                required />
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        <div className="ms-2" style={{ marginTop: '1rem' }}>
                                                            <Row>
                                                                <Col md={6} sm={12}>
                                                                    <div className="">
                                                                        <label style={{ paddingLeft: '2px' }}>Gender</label>
                                                                        <div>
                                                                            <ButtonGroup className="gender-radio-group gap-2 px-2">
                                                                                <Button className={`gender-button ${gender === 'Male' ? 'active' : ''}`} variant="" onClick={() => handleGenderChange('Male')}>Male</Button>
                                                                                <Button className={`gender-button ${gender === 'Female' ? 'active' : ''}`} variant="" onClick={() => handleGenderChange('Female')}>Female</Button>
                                                                                <Button className={`gender-button ${gender === 'Other' ? 'active' : ''}`} variant="" onClick={() => handleGenderChange('Other')}>Other</Button>
                                                                            </ButtonGroup>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6} sm={12}>
                                                                    <div className="">
                                                                        <label style={{ paddingLeft: '2px' }}>Your Location<span style={{ color: 'red' }}>*</span></label>
                                                                        <Form.Group className="form-group boxed" style={{}} >
                                                                            <Form.Control
                                                                                style={{ border: '1px solid #E5E5E5' }}
                                                                                type="text"
                                                                                placeholder="Your current location"
                                                                                name="location"
                                                                                value={getUserData?.location}
                                                                                onChange={updateUserData}
                                                                                required />
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        <div className="mt-2 ms-2">
                                                            <label style={{ paddingLeft: '2px', color: 'black' }}>Image <span style={{ color: '#737373', fontSize: '12px' }}>Optional</span></label>
                                                            <div className="mt-1 d-flex">
                                                                <div className="d-flex justify-content-center align-items-center" style={{ height: '6rem', width: '6rem', backgroundColor: '#F5F5F5', borderRadius: '4px' }}>
                                                                    {/* <User className="" style={{ height: '4rem', width: '4rem' }} /> */}
                                                                    <label htmlFor="fileUpload" class="cursor-pointer">
                                                                        {(getUserData?.image || previewImage || userInfo.Image || userPhoto) ?
                                                                         <img style={{ height: '6rem', width: '6rem', backgroundColor: '#F5F5F5', borderRadius: '4px' }}
                                                                            src={getUserData?.image || previewImage || userInfo.Image || userPhoto}

                                                                            alt="avatar"
                                                                            className="image"
                                                                        /> : <User size={50} />}

                                                                        {/* <img style={{ height: '6rem', width: '6rem', backgroundColor: '#F5F5F5', borderRadius: '4px' }}
                                                                            src={getUserData?.image || previewImage || userInfo.Image || userPhoto}

                                                                            alt="avatar"
                                                                            className="image"
                                                                        /> */}
                                                                    </label>
                                                                </div>


                                                                <Row>
                                                                    <div style={{ display: 'inline-block', position: 'relative' }}>
                                                                        <label
                                                                            htmlFor="fileUpload" className="ms-1"
                                                                            style={{
                                                                                padding: '10px 70px 10px 10px', border: '1px solid #E5E5E5', borderRadius: '8px', fontWeight: '500', display: 'inline-block', backgroundColor: 'white', color: 'black', fontSize: '16px', cursor: 'pointer', borderRadius: '8px', textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            Upload File <span style={{ color: 'black', fontWeight: '300' }}>{fileName}</span>
                                                                        </label>
                                                                        <input
                                                                            id="fileUpload"
                                                                            type="file"
                                                                            accept='image/*'
                                                                            custom
                                                                            label='Choose Image'
                                                                            onChange={handleImageChange}
                                                                            style={{
                                                                                width: '15rem',
                                                                                left: '22px',
                                                                                opacity: 0,
                                                                                position: 'absolute',
                                                                                zIndex: -1,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {fileUploaded ? (
                                                                            <span className='text-nowrap' style={{ marginLeft: '10px' }} >uploading...</span>
                                                                        ) : ('')}
                                                                    </div>
                                                                </Row>



                                                            </div>
                                                        </div>

                                                        <div className="ms-2 mt-3 " style={{ marginBottom: '16px' }}>
                                                            <div className="mb-2 d-flex justify-content-between">
                                                                <div className="mb-3">
                                                                    <button type="button" onClick={() => (window.location.href = '/')} className="btn btn-outline-primary" style={{ height: '48px', fontSize: '20px' }}><ArrowLeft className="me-1" style={{ height: '15px', width: '15px' }} /> Back <span className="ms-1"></span></button>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <Button disabled={fileUploaded} type="submit" className="btn" style={{ backgroundColor: '#358D77', color: 'white', height: '48px', fontSize: '20px' }}>Next<span className="ms-1"><ArrowRight style={{ height: '15px', width: '15px' }} /></span></Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>


                                                </Col>
                                                <Col md={4} sm={12} className='graph-analysis-didi1 p-2' >
                                                    <div
                                                        style={{
                                                            height: '100%',
                                                            top: 0,
                                                            left: 0,
                                                            right: 0,
                                                            bottom: 0,
                                                            background: `radial-gradient(47.17% 126.72% at 55.99% 38.29%, rgba(205, 255, 217, 0.2) 0%, rgba(26, 53, 96, 0.2) 100%),linear-gradient(181deg, rgba(205, 255, 217, 0.2) 20%, rgba(30, 124, 109, 0.2) 96%),var(--accent, rgba(198, 232, 223, 1))`,
                                                            borderRadius: '1.5rem',
                                                        }}
                                                    >
                                                        <SideValiDidi />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </section >
                                {/* candidate step 2/6 page end */}
                            </>
                            :
                            <>
                                {/* candidate login page start */}
                                <section style={{ height: '95vh' }}>
                                    <div className='graph-bg-parent h-100 d-flex- justify-content-center p-0'>
                                        <div class="w-100 overflow-hidden position-relative custom-hero-section p-5" style={{backgroundImage: `URL(${DesktopBg})`}}>
                                            <div class="d-flex align-items-center align-items-md-start flex-column justify-content-start justify-content-md-center" style={{height: '53vh'}}>
                                                <h1 class="text-nowrap hero-custom-text text-white mb-1">New-age Talent Needs</h1>
                                                <h1 class="text-nowrap hero-custom-text text-white mb-2">New Age Launchpad</h1>
                                                <p class="text-white text-center text-md-start">If you have skills, we have scope for you. Register now to see what’s the scope.</p>
                                                <a href="#loginCardUi" class="mt-2 btn bg-white text-primary text-nowrap" style={{zIndex: 1, width: '120px'}}>Got Skills?</a>
                                            </div>
                                            <img src={image2} class="custom-image2" />
                                            <img src={image3} class="custom-image3" />
                                            <div class='position-relative parent-snippet-div'>
                                                <img src={image4} class="custom-image4" />
                                                <img src={user1} class="custom-user" style={{position: 'absolute', right: '0'}} />
                                            </div>
                                        </div>
                                        <Row className='p-0 m-0 mt-4'>
                                            <Col md={12} sm={12}>
                                                <h4 class="text-center">Get scope in:</h4>
                                                <div class="scroll-container-left">
                                                    <div class="scroll-wrapper" id="scrollContent">
                                                        <div class="tags">
                                                            <span class="tag blue">Design</span>
                                                            <span class="tag purple">Software Development</span>
                                                            <span class="tag green">Fitness</span>
                                                            <span class="tag black">Digital Marketing</span>
                                                            <span class="tag light-blue">Media</span>
                                                            <span class="tag orange">Content Creation</span>
                                                            <span class="tag red">Start-Ups</span>
                                                            <span class="tag yellow">Video Editing</span>
                                                            <span class="tag light-blue">Media</span>
                                                            <span class="tag orange">Content Creation</span>
                                                            <span class="tag blue">Graphic Design</span>
                                                            <span class="tag purple">Software Development</span>
                                                            <span class="tag green">Fitness</span>
                                                            <span class="tag black">Digital Marketing</span>
                                                        </div>
                                                        {/* <!-- Duplicate for smooth looping --> */}
                                                        <div class="tags">
                                                            <span class="tag blue">Design</span>
                                                            <span class="tag purple">Software Development</span>
                                                            <span class="tag green">Fitness</span>
                                                            <span class="tag black">Digital Marketing</span>
                                                            <span class="tag light-blue">Media</span>
                                                            <span class="tag orange">Content Creation</span>
                                                            <span class="tag red">Start-Ups</span>
                                                            <span class="tag yellow">Video Editing</span>
                                                            <span class="tag light-blue">Media</span>
                                                            <span class="tag orange">Content Creation</span>
                                                            <span class="tag blue">Graphic Design</span>
                                                            <span class="tag purple">Software Development</span>
                                                            <span class="tag green">Fitness</span>
                                                            <span class="tag black">Digital Marketing</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="scroll-container-right">
                                                    <div class="scroll-wrapper" id="scrollContent">
                                                        <div class="tags">
                                                            <span class="tag blue">Design</span>
                                                            <span class="tag purple">Software Development</span>
                                                            <span class="tag green">Fitness</span>
                                                            <span class="tag black">Digital Marketing</span>
                                                            <span class="tag light-blue">Media</span>
                                                            <span class="tag orange">Content Creation</span>
                                                            <span class="tag red">Start-Ups</span>
                                                            <span class="tag yellow">Video Editing</span>
                                                            <span class="tag light-blue">Media</span>
                                                            <span class="tag orange">Content Creation</span>
                                                            <span class="tag blue">Graphic Design</span>
                                                            <span class="tag purple">Software Development</span>
                                                            <span class="tag green">Fitness</span>
                                                            <span class="tag black">Digital Marketing</span>
                                                        </div>
                                                        {/* <!-- Duplicate for smooth looping --> */}
                                                        <div class="tags">
                                                            <span class="tag blue">Design</span>
                                                            <span class="tag purple">Software Development</span>
                                                            <span class="tag green">Fitness</span>
                                                            <span class="tag black">Digital Marketing</span>
                                                            <span class="tag light-blue">Media</span>
                                                            <span class="tag orange">Content Creation</span>
                                                            <span class="tag red">Start-Ups</span>
                                                            <span class="tag yellow">Video Editing</span>
                                                            <span class="tag light-blue">Media</span>
                                                            <span class="tag orange">Content Creation</span>
                                                            <span class="tag blue">Graphic Design</span>
                                                            <span class="tag purple">Software Development</span>
                                                            <span class="tag green">Fitness</span>
                                                            <span class="tag black">Digital Marketing</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-content-start justify-content-md-center align-items-center align-items-md-end flex-column flex-md-row mb-2 mt-2">
                                                    <h2 class="fw-bold">1,00,000+ Candidates&nbsp;</h2>
                                                    <h4 style={{paddingBottom: '2px', paddingBottom: '4px'}}>launched their career with Scopehai</h4>
                                                </div>
                                                <div class="avatar-group mb-5" id="loginCardUi">
                                                    <img src="https://randomuser.me/api/portraits/women/1.jpg" alt="User 1" class="avatar" />
                                                    <img src="https://randomuser.me/api/portraits/men/2.jpg" alt="User 2" class="avatar" />
                                                    <img src="https://randomuser.me/api/portraits/women/3.jpg" alt="User 3" class="avatar" />
                                                    <img src="https://randomuser.me/api/portraits/women/4.jpg" alt="User 4" class="avatar" />
                                                    <img src="https://randomuser.me/api/portraits/men/5.jpg" alt="User 5" class="avatar" />
                                                </div>
                                                <div class="container">
                                                    <div class="card border-0 shadow mb-5">
                                                        <div class="card-body p-0">
                                                            <div className='login-parent-div p-0'>
                                                                <div className="login-sub-div d-flex justify-content-center m-0">
                                                                    {/* <div className='d-flex justify-content-end'>
                                                                        <button className="login-btn btn btn-link" style={{ 'fontSize': '1rem' }}>Login</button>
                                                                    </div> */}
                                                                    <div className='login-logo-div d-flex flex-column align-items-center' >
                                                                        <div className='d-flex flex-column align-items-center'>
                                                                            <img src={Logo} style={{ height: '3rem' }} ></img>
                                                                            <h1 className='sign-class mb-0 text-center'>Create your profile</h1>
                                                                            <label style={{ color: '#737373', fontSize: '14px', textAlign: 'center' }}>Enter your mobile below to create your account</label>
                                                                        </div>
                                                                        <div className='cand-login-input d-flex flex-column align-items-center w-100'>
                                                                            <form className="w-100" onSubmit={handleUserNameValidation}>
                                                                                <Form.Group className="form-group boxed w-auto" controlId="exampleForm.ControlInput1">
                                                                                    <Form.Control
                                                                                        style={{ border: '1px solid #E5E5E5' }}
                                                                                        className="form-control phoneInput mt-1"
                                                                                        international
                                                                                        type="number"
                                                                                        min="0"
                                                                                        placeholder="Mobile Number"
                                                                                        id="name1"
                                                                                        name="mobileNumber"
                                                                                        value={mobileNumber}
                                                                                        onChange={(e) => setMobileNumber(e.target.value)}
                                                                                        required />
                                                                                </Form.Group>
                                                                                <button type="submit" className="btn w-100 mt-1" style={{ 'fontSize': '1rem', backgroundColor: '#358D77', color: 'white' }} >Sign Up with Mobile</button>
                                                                            </form>
                                                                            <label className='mt-2' style={{ textAlign: 'center', color: '#737373' }}>By clicking continue, you agree to our <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>.</label>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
{/* 
                                            <Col md={6} sm={12} className='candidate-login-graph d-flex justify-content-center'>
                                                <div className='graph-icon-div' style={{ backgroundImage: `url(${candidateBg})`, position: 'relative', borderRadius: '1rem', overflow: 'hidden' }}>


                                                    <div className='d-flex flex-column align-items-center'>
                                                        <div>
                                                            <img src={graphAnalysis} className="graph-analysis-didi"></img>
                                                        </div>
                                                        <div className='d-flex flex-column align-items-center' >
                                                            <h2>Let employers find you!</h2>
                                                            <label>Get verified </label>
                                                        </div>
                                                        <div>
                                                            <lable className="chip ms-05 mb-05 m-2 p-2" style={{ 'backgroundColor': '#DBEAFE', 'color': '#2563EB' }} >Internship</lable>
                                                            <lable className="chip ms-05 mb-05 m-2 p-2" style={{ 'backgroundColor': '#F3E8FF', 'color': '#9333EA' }} >Jobs</lable>
                                                            <lable className="chip ms-05 mb-05 m-2 p-2" style={{ 'backgroundColor': '#DCFCE7', 'color': '#059669' }} >Freelance</lable>

                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>

                                            <Col md={6} sm={12} className="d-flex justify-content-center" >
                                                <div className='login-parent-div'>
                                                    <div className="login-sub-div">
                                                        <div className='d-flex justify-content-end'>
                                                            <button className="login-btn btn btn-link" style={{ 'fontSize': '1rem' }}>Login</button>
                                                        </div>
                                                        <div className='login-logo-div d-flex flex-column align-items-center' >
                                                            <div className='d-flex flex-column align-items-center'>
                                                                <img src={Logo} style={{ height: '3rem' }} ></img>
                                                                <h1 className='sign-class mb-0'>Sign up as a candidate</h1>
                                                                <label style={{ color: '#737373', fontSize: '14px', textAlign: 'center' }}>Enter your mobile below to create your account</label>
                                                            </div>
                                                            <div className='cand-login-input d-flex flex-column align-items-center w-100'>
                                                                <form className="w-100" onSubmit={handleUserNameValidation}>
                                                                    <Form.Group className="form-group boxed w-auto" controlId="exampleForm.ControlInput1">
                                                                        <Form.Control
                                                                            style={{ border: '1px solid #E5E5E5' }}
                                                                            className="form-control phoneInput mt-1"
                                                                            international
                                                                            type="number"
                                                                            min="0"
                                                                            autoFocus
                                                                            placeholder="Mobile Number"
                                                                            id="name1"
                                                                            name="mobileNumber"
                                                                            value={mobileNumber}
                                                                            onChange={(e) => setMobileNumber(e.target.value)}
                                                                            required />
                                                                    </Form.Group>
                                                                    <button type="submit" className="btn w-100 mt-1" style={{ 'fontSize': '1rem', backgroundColor: '#358D77', color: 'white' }} >Sign Up with Mobile</button>
                                                                </form>
                                                                <label className='mt-2' style={{ textAlign: 'center', color: '#737373' }}>By clicking continue, you agree to our <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>.</label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Col> */}
                                        </Row>

                                    </div>
                                </section>
                                {/* candidate login page end */}
                            </>

                        }
                    </>
                    }
                </>}
            </>}

            {
                newUiCandidateExperience && <>
                    {/* candidate step 3/6 page start */}
                    <section>
                        <div style={{ height: '100vh' }}>
                            <div className='h-100'>
                                <Row className='h-100 p-0 m-0'>
                                    <Col md={8} sm={12} className='exp-col-div h-100'>
                                        <div>
                                            <img src={Logo} style={{ height: '2.5rem' }} ></img>
                                        </div>
                                        <div className='d-flex gap-2 mt-2'>
                                            <div style={{ width: '100px', height: '8px', backgroundColor: '#C6E8DF', borderRadius: '5px' }}></div>
                                            <div style={{ width: '100px', height: '8px', backgroundColor: '#C6E8DF', borderRadius: '5px' }}></div>
                                            <div style={{ width: '100px', height: '8px', backgroundColor: '#358D77', borderRadius: '5px' }}></div>
                                            <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                            <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                            <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                        </div>
                                        <span class="" style={{ fontSize: '14px' }}>3 of 6</span>
                                        <div className="d-flex flex-column">
                                            <h2 className='mb-0 ' style={{ marginTop: '3rem' }}>Are you a fresher or currently working?</h2>
                                            <lable className='' style={{ color: '#737373', text: 'start' }}>We'll tailor the next steps accordingly.</lable>
                                        </div>



                                        <form onSubmit={handelExperience}>
                                            <div className="d-flex flex-column align-items-center" >
                                                <div
                                                    className="big-box-parent gap-3 d-flex flex-wrap justify-content-center align-items-center"
                                                    style={{ marginTop: '2rem', height: '18rem', width: '26rem', backgroundColor: '#f5f5f5', borderRadius: '14px' }}
                                                >
                                                    {/* Fresher Button */}
                                                    <div
                                                        className={`position-relative fresher-experience-button btn d-flex flex-column ${(selectedExperience === 'Fresher') ? 'active' : ''
                                                            }`}
                                                        onClick={() => handleSelection('Fresher')}
                                                    >
                                                        {selectedExperience === 'Fresher' && (
                                                            <div
                                                                className="position-absolute"
                                                                style={{ paddingBottom: '3rem', right: '1rem', top: '0.8rem' }}
                                                            >
                                                                <Check style={{ color: '#ECFDF5', backgroundColor: '#358D77', borderRadius: '4px' }} />
                                                            </div>
                                                        )}
                                                        <div>
                                                            <GraduationCap style={{ color: '#358D77', height: '3rem', width: '3rem', }} />
                                                        </div>
                                                        <div className="mt-3">
                                                            <label style={{ fontSize: '1.5rem' }}>Fresher</label>
                                                        </div>
                                                    </div>

                                                    {/* Working Button */}
                                                    <div
                                                        className={`position-relative fresher-experience-button btn d-flex flex-column ${(selectedExperience === 'Experienced') ? 'active' : ''
                                                            }`}
                                                        onClick={() => handleSelection('Experienced')}
                                                    >
                                                        {selectedExperience === 'Experienced' && (
                                                            <div
                                                                className="position-absolute"
                                                                style={{ paddingBottom: '3rem', right: '1rem', top: '0.8rem' }}
                                                            >
                                                                <Check style={{ color: '#ECFDF5', backgroundColor: '#358D77', borderRadius: '4px' }} />
                                                            </div>
                                                        )}
                                                        <div>
                                                            <BriefcaseBusiness style={{ color: '#358D77', height: '3rem', width: '3rem' }} />
                                                        </div>
                                                        <div className="mt-2">
                                                            <label style={{ fontSize: '1.5rem' }}>Working</label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="ms-2 mt-3 d-flex justify-content-between">
                                                <div className="mb-3">
                                                    <button type="button" onClick={handleGoBack} className="btn btn-outline-primary" style={{ height: '48px', fontSize: '20px' }}><ArrowLeft className="me-1" style={{ height: '15px', width: '15px' }} /> Back <span className="ms-1"></span></button>
                                                </div>
                                                <div className="mb-3">
                                                    <button
                                                        type="submit"
                                                        className="btn "
                                                        style={{ backgroundColor: '#358D77', color: 'white', height: '48px', fontSize: '20px' }}
                                                    >
                                                        Next
                                                        <span className="ms-1">
                                                            <ArrowRight style={{ height: '15px', width: '15px' }} />
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </Col>
                                    <Col md={4} sm={12} className='new-ui-otp-col-2 p-2 pe-3'>

                                        <SideValiDidi />

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </section >

                    {/* candidate step 3/6 page end */}
                </>
            }
            {
                newUiCandiateOtpStep &&
                <>
                    {
                        <>
                            {/* candidate enter otp page start */}
                            <section>
                                <div className="otp-parent" style={{ height: '100vh' }}>
                                    <div className='h-100'>
                                        <Row className='h-100 p-0 m-0'>
                                            <Col md={8} sm={12} className='enter-verification-parent h-100'>
                                                <div>
                                                    <img src={Logo} style={{ height: '2.5rem' }} ></img>
                                                </div>
                                                <div className='d-flex gap-2 mt-2 ms-2'>
                                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#358D77', borderRadius: '5px' }}></div>
                                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                                </div>
                                                <span class="ms-2" style={{ fontSize: '14px' }}>1 of 6</span>
                                                <div className="d-flex flex-column">
                                                    <h2 className='mb-0 ms-2' style={{ marginTop: '5rem' }}>Enter verification code</h2>
                                                    <lable className='ms-2' style={{ color: '#737373', text: 'start' }}>We’ve sent OTP code to your phone on sent to +91 {mobileNumber}</lable>
                                                </div>
                                                <form onSubmit={HandleOtpValidation}>
                                                    <div className="ms-2" style={{ marginTop: '2rem' }}>
                                                        <OTPInput length={6} value={otp} onChange={setOtp} validateFor={'mobile'} />
                                                        {verificationOtpMessage?.includes('Mobile') &&
                                                            <p class="text-danger" style={{fontSize: '14px'}}>Incorrect Mobile OTP</p>
                                                        }
                                                    </div>

                                                    <div className="ms-2 d-flex justify-content-between" style={{ marginTop: '4rem' }}>
                                                        <div>
                                                            <button type="button" onClick={() => (window.location.href = '/')} className="btn btn-outline-primary" style={{ height: '48px', fontSize: '20px' }}><ArrowLeft className="me-1" style={{ height: '15px', width: '15px' }} /> Back <span className="ms-1"></span></button>
                                                        </div>
                                                        <div>
                                                            <button className="btn" style={{ backgroundColor: '#358D77', fontSize: '16px', color: 'white', height: '48px' }}>Next<span><ArrowRight size={16} className="ms-1" /></span></button>
                                                        </div>
                                                    </div>

                                                </form>
                                            </Col>
                                            <Col md={4} sm={12} className='new-ui-otp-col-2 p-2'>
                                                <div
                                                    style={{
                                                        height: '100%',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        background: `radial-gradient(47.17% 126.72% at 55.99% 38.29%, rgba(205, 255, 217, 0.2) 0%, rgba(26, 53, 96, 0.2) 100%),linear-gradient(181deg, rgba(205, 255, 217, 0.2) 20%, rgba(30, 124, 109, 0.2) 96%),var(--accent, rgba(198, 232, 223, 1))`,
                                                        borderRadius: '1.5rem',
                                                    }}
                                                >
                                                    <SideValiDidi />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </section>
                            {/* candidate enter otp page end */}
                        </>
                    }
                </>
            }

            <CheckStepNumber></CheckStepNumber>




            {/* candidate step 2/6 page start */}
            {/* <section>
                <div style={{ height: '100vh' }}>
                    <div className='h-100'>
                        <Row className='h-100'>
                            <Col md={8} sm={12} className='p-5 h-100'>
                                <div>
                                    <img src={Logo} style={{ height: '2.5rem' }} ></img>
                                </div>
                                <div className='d-flex gap-2 mt-2 ms-2'>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#358D77', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                </div>

                                <div className="d-flex flex-column">
                                    <h2 className='mb-0 ms-2' style={{ marginTop: '3rem' }}>Let’s Get Started!</h2>
                                    <lable className='ms-2' style={{ color: '#737373', text: 'start' }}>Tell us a bit about yourself to set up your profile.</lable>
                                </div>

                                <div className="ms-2 gap-3 d-flex" style={{ marginTop: '2rem' }}>
                                    <button className="btn" style={{ border: '1px solid #E5E5E5', height: '48px', paddingLeft: '8px', paddingRight: '8px', width: '171px' }}><span><img src={Linkedin} style={{ margin: '5px' }}></img></span>Fill using LinkedIn</button>
                                    <button className="btn" style={{ border: '1px solid #E5E5E5', height: '48px', paddingLeft: '8px', paddingRight: '8px', width: '171px' }}><span><img src={Linkedin} style={{ margin: '5px' }}></img></span>Fill using LinkedIn</button>
                                </div>

                                <div className="ms-2" style={{ marginTop: '2rem' }}>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <div className="">
                                                <label style={{ paddingLeft: '2px' }}>First name</label>
                                                <Form.Group className="form-group boxed" style={{}} >
                                                    <Form.Control
                                                        style={{ border: '1px solid #E5E5E5' }}
                                                        type="text"
                                                        placeholder="First name"
                                                        name="empFirstName"
                                                        value=''
                                                        onChange=''
                                                        required />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <div className="">
                                                <label style={{ paddingLeft: '2px' }}>Last name</label>
                                                <Form.Group className="form-group boxed" style={{}} >
                                                    <Form.Control
                                                        style={{ border: '1px solid #E5E5E5' }}
                                                        type="text"
                                                        placeholder="Last name"
                                                        name="empFirstName"
                                                        value=''
                                                        onChange=''
                                                        required />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="ms-2" style={{ marginTop: '1rem' }}>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <div className="">
                                                <label style={{ paddingLeft: '2px' }}>Gender</label>
                                                <div>
                                                    <ButtonGroup className="gender-radio-group gap-2 px-2">
                                                        <Button className="gender-button active" variant="" >Male</Button>
                                                        <Button className="gender-button" variant="" >Female</Button>
                                                        <Button className="gender-button" variant="" >Other</Button>
                                                    </ButtonGroup>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <div className="">
                                                <label style={{ paddingLeft: '2px' }}>Your Location</label>
                                                <Form.Group className="form-group boxed" style={{}} >
                                                    <Form.Control
                                                        style={{ border: '1px solid #E5E5E5' }}
                                                        type="text"
                                                        placeholder="Your current location"
                                                        name="empFirstName"
                                                        value=''
                                                        onChange=''
                                                        required />
                                                </Form.Group>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="mt-2 ms-2">
                                    <label style={{ paddingLeft: '2px', color: 'black' }}>Image <span style={{ color: '#737373' }}>Optional</span></label>
                                    <div className="mt-1 d-flex">
                                        <div className="d-flex justify-content-center align-items-center" style={{ height: '6rem', width: '6rem', backgroundColor: '#F5F5F5', borderRadius: '4px' }}>
                                            <User className="" style={{ height: '4rem', width: '4rem' }} />
                                        </div>
                                        <div style={{ display: 'inline-block', position: 'relative' }}>
                                            <label
                                                htmlFor="fileInput" className="ms-1"
                                                style={{
                                                    padding: '10px 70px 10px 10px', border: '1px solid #E5E5E5', borderRadius: '8px', fontWeight: '500', display: 'inline-block', backgroundColor: 'white', color: 'black', fontSize: '16px', cursor: 'pointer', borderRadius: '8px', textAlign: 'center',
                                                }}
                                            >
                                                Upload File <span style={{ color: 'black', fontWeight: '300' }}>{fileName}</span>
                                            </label>
                                            <input
                                                id="fileInput"
                                                type="file"
                                                style={{
                                                    opacity: 0,
                                                    position: 'absolute',
                                                    zIndex: -1,
                                                }}
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="ms-2 mt-3">
                                    <button className="btn" style={{ backgroundColor: '#358D77', color: 'white', height: '48px', fontSize: '20px' }}>Next<span className="ms-1"><ArrowRight style={{ height: '15px', width: '15px' }} /></span></button>
                                </div>
                            </Col>
                            <Col md={4} sm={12} className='p-2'>
                                <div
                                    style={{
                                        height: '100%',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        background: `radial-gradient(47.17% 126.72% at 55.99% 38.29%, rgba(205, 255, 217, 0.2) 0%, rgba(26, 53, 96, 0.2) 100%),linear-gradient(181deg, rgba(205, 255, 217, 0.2) 20%, rgba(30, 124, 109, 0.2) 96%),var(--accent, rgba(198, 232, 223, 1))`,
                                        borderRadius: '1.5rem',
                                    }}
                                >
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section > */}
            {/* candidate step 2/6 page end */}


            {/* candidate step 3/6 page start */}
            {/* <section>
                <div style={{ height: '100vh' }}>
                    <div className='h-100'>
                        <Row className='h-100'>
                            <Col md={8} sm={12} className='p-5 h-100'>
                                <div>
                                    <img src={Logo} style={{ height: '2.5rem' }} ></img>
                                </div>
                                <div className='d-flex gap-2 mt-2 ms-2'>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#358D77', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                </div>

                                <div className="d-flex flex-column">
                                    <h2 className='mb-0 ms-2' style={{ marginTop: '3rem' }}>Are you a fresher or currently working?</h2>
                                    <lable className='ms-2' style={{ color: '#737373', text: 'start' }}>We'll tailor the next steps accordingly.</lable>
                                </div>

                                <div className="ms-2 gap-3 d-flex flex-wrap justify-content-center align-items-center" style={{ marginTop: '2rem', height: '18rem', width: '26rem', backgroundColor: '#f5f5f5', borderRadius:'14px' }}>

                                    <div className="position-relative fresher-experience-button btn d-flex flex-column">

                                        <div style={{ marginTop: '' }}>
                                            <GraduationCap style={{ color: '#358D77', height: '3rem', width: '3rem' }} />
                                        </div>
                                        <div className="mt-3">
                                            <lable style={{ fontSize: '1.5rem' }}>Fresher</lable>
                                        </div>
                                    </div>
                                    <div className="position-relative fresher-experience-button active btn d-flex flex-column">
                                        <div className="position-absolute" style={{ paddingBottom: '3rem', right: '1rem', top: '0.8rem' }}><Check style={{ color: '#ECFDF5', backgroundColor: '#358D77', borderRadius: '4px' }} /> </div>
                                        <div><BriefcaseBusiness style={{ color: '#358D77', height: '3rem', width: '3rem' }} /></div>
                                        <div className="mt-2">
                                            <lable style={{ fontSize: '1.5rem' }}>Working</lable>
                                        </div>
                                    </div>

                                </div>

                                <div className="ms-2 mt-3">
                                    <button className="btn" style={{ backgroundColor: '#358D77', color: 'white', height: '48px', fontSize: '20px' }}>Next<span className="ms-1"><ArrowRight style={{ height: '15px', width: '15px' }} /></span></button>
                                </div>
                            </Col>
                            <Col md={4} sm={12} className='p-2'>
                                <div
                                    style={{
                                        height: '100%',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        background: `radial-gradient(47.17% 126.72% at 55.99% 38.29%, rgba(205, 255, 217, 0.2) 0%, rgba(26, 53, 96, 0.2) 100%),linear-gradient(181deg, rgba(205, 255, 217, 0.2) 20%, rgba(30, 124, 109, 0.2) 96%),var(--accent, rgba(198, 232, 223, 1))`,
                                        borderRadius: '1.5rem',
                                    }}
                                >
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section > */}
            {/* candidate step 3/6 page end */}



            {/* candidate step 4/6 page start */}
            {/* <section>
                <div style={{ height: '100vh' }}>
                    <div className='h-100'>
                        <Row className='h-100'>
                            <Col md={8} sm={12} className='p-5 h-100'>
                                <div>
                                    <img src={Logo} style={{ height: '2.5rem' }} ></img>
                                </div>
                                <div className='d-flex gap-2 mt-2 ms-2'>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#358D77', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                </div>

                                <div className="d-flex flex-column">
                                    <h2 className='mb-0 ms-2' style={{ marginTop: '3rem' }}>Add Your Education Details</h2>
                                    <lable className='ms-2' style={{ color: '#737373', text: 'start' }}>Add your academic info.</lable>
                                    <lable className='ms-2' style={{ color: '#737373', text: 'start' }}>Showcase your expertise.</lable>
                                </div>

                                <div className="ms-2" style={{ marginTop: '2rem' }}>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <div>
                                                <Form.Group className="boxed mb-2" >
                                                    <Form.Label for="xpLevel1">Current Job Title</Form.Label>
                                                    <InputGroup className="boxed position-relative">
                                                        <Form.Select

                                                            style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                            aria-label="Default select example"
                                                            id="xpLevel1"
                                                            name="empCompanyDomain"
                                                            value=''
                                                            required
                                                            onChange='{updateEmpData}'
                                                        >
                                                            <option value="" selected disabled>Select...</option>
                                                            <option value="IT/software">IT/software</option>
                                                            <option value="Online business">Online business</option>
                                                            <option value="Manufacturing">Manufacturing</option>
                                                            <option value="Finance">Finance</option>
                                                            <option value="Media and Advertisement">Media and Advertisement</option>
                                                            <option value="E-commerce">E-commerce</option>
                                                            <option value="Sales and marketing">Sales and marketing</option>
                                                            <option value="Consultancy services">Consultancy services</option>
                                                            <option value="Logistics">Logistics</option>
                                                            <option value="Real estate">Real estate</option>
                                                            <option value="Legal">Legal</option>
                                                            <option value="Hospitality">Hospitality</option>
                                                            <option value="Other">Other</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <div>
                                                <Form.Group className="boxed mb-2" >
                                                    <Form.Label for="xpLevel1">Years of Experience</Form.Label>
                                                    <InputGroup className="boxed position-relative">
                                                        <Form.Select

                                                            style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                            aria-label="Default select example"
                                                            id="xpLevel1"
                                                            name="empCompanyDomain"
                                                            value=''
                                                            required
                                                            onChange='{updateEmpData}'
                                                        >
                                                            <option value="" selected disabled>Select...</option>
                                                            <option value="IT/software">IT/software</option>
                                                            <option value="Online business">Online business</option>
                                                            <option value="Manufacturing">Manufacturing</option>
                                                            <option value="Finance">Finance</option>
                                                            <option value="Media and Advertisement">Media and Advertisement</option>
                                                            <option value="E-commerce">E-commerce</option>
                                                            <option value="Sales and marketing">Sales and marketing</option>
                                                            <option value="Consultancy services">Consultancy services</option>
                                                            <option value="Logistics">Logistics</option>
                                                            <option value="Real estate">Real estate</option>
                                                            <option value="Legal">Legal</option>
                                                            <option value="Hospitality">Hospitality</option>
                                                            <option value="Other">Other</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <div>
                                                <Form.Group className="boxed mb-2" >
                                                    <Form.Label for="xpLevel1">Current Job Domain</Form.Label>
                                                    <InputGroup className="boxed position-relative">
                                                        <Form.Select

                                                            style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                            aria-label="Default select example"
                                                            id="xpLevel1"
                                                            name="empCompanyDomain"
                                                            value=''
                                                            required
                                                            onChange='{updateEmpData}'
                                                        >
                                                            <option value="" selected disabled>Select...</option>
                                                            <option value="IT/software">IT/software</option>
                                                            <option value="Online business">Online business</option>
                                                            <option value="Manufacturing">Manufacturing</option>
                                                            <option value="Finance">Finance</option>
                                                            <option value="Media and Advertisement">Media and Advertisement</option>
                                                            <option value="E-commerce">E-commerce</option>
                                                            <option value="Sales and marketing">Sales and marketing</option>
                                                            <option value="Consultancy services">Consultancy services</option>
                                                            <option value="Logistics">Logistics</option>
                                                            <option value="Real estate">Real estate</option>
                                                            <option value="Legal">Legal</option>
                                                            <option value="Hospitality">Hospitality</option>
                                                            <option value="Other">Other</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <div>
                                                <Form.Group className="boxed mb-2" >
                                                    <Form.Label for="xpLevel1">Highest Education Completed</Form.Label>
                                                    <InputGroup className="boxed position-relative">
                                                        <Form.Select

                                                            style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                            aria-label="Default select example"
                                                            id="xpLevel1"
                                                            name="empCompanyDomain"
                                                            value=''
                                                            required
                                                            onChange='{updateEmpData}'
                                                        >
                                                            <option value="" selected disabled>Select...</option>
                                                            <option value="IT/software">IT/software</option>
                                                            <option value="Online business">Online business</option>
                                                            <option value="Manufacturing">Manufacturing</option>
                                                            <option value="Finance">Finance</option>
                                                            <option value="Media and Advertisement">Media and Advertisement</option>
                                                            <option value="E-commerce">E-commerce</option>
                                                            <option value="Sales and marketing">Sales and marketing</option>
                                                            <option value="Consultancy services">Consultancy services</option>
                                                            <option value="Logistics">Logistics</option>
                                                            <option value="Real estate">Real estate</option>
                                                            <option value="Legal">Legal</option>
                                                            <option value="Hospitality">Hospitality</option>
                                                            <option value="Other">Other</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <div>
                                                <Form.Group className="boxed mb-2" >
                                                    <Form.Label for="xpLevel1">Field of Study</Form.Label>
                                                    <InputGroup className="boxed position-relative">
                                                        <Form.Select

                                                            style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                            aria-label="Default select example"
                                                            id="xpLevel1"
                                                            name="empCompanyDomain"
                                                            value=''
                                                            required
                                                            onChange='{updateEmpData}'
                                                        >
                                                            <option value="" selected disabled>Select...</option>
                                                            <option value="IT/software">IT/software</option>
                                                            <option value="Online business">Online business</option>
                                                            <option value="Manufacturing">Manufacturing</option>
                                                            <option value="Finance">Finance</option>
                                                            <option value="Media and Advertisement">Media and Advertisement</option>
                                                            <option value="E-commerce">E-commerce</option>
                                                            <option value="Sales and marketing">Sales and marketing</option>
                                                            <option value="Consultancy services">Consultancy services</option>
                                                            <option value="Logistics">Logistics</option>
                                                            <option value="Real estate">Real estate</option>
                                                            <option value="Legal">Legal</option>
                                                            <option value="Hospitality">Hospitality</option>
                                                            <option value="Other">Other</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="ms-2" style={{ marginTop: '1rem' }}>
                                    <Row>
                                        <Col md={12} sm={12}>
                                            <div>
                                                <Form.Group className="boxed mb-2" >
                                                    <Form.Label for="xpLevel1">Domains of Interest</Form.Label>
                                                    <InputGroup className="boxed position-relative">
                                                        <Form.Select

                                                            style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                            aria-label="Default select example"
                                                            id="xpLevel1"
                                                            name="empCompanyDomain"
                                                            value=''
                                                            required
                                                            onChange='{updateEmpData}'
                                                        >
                                                            <option value="" selected disabled>Select...</option>
                                                            <option value="IT/software">IT/software</option>
                                                            <option value="Online business">Online business</option>
                                                            <option value="Manufacturing">Manufacturing</option>
                                                            <option value="Finance">Finance</option>
                                                            <option value="Media and Advertisement">Media and Advertisement</option>
                                                            <option value="E-commerce">E-commerce</option>
                                                            <option value="Sales and marketing">Sales and marketing</option>
                                                            <option value="Consultancy services">Consultancy services</option>
                                                            <option value="Logistics">Logistics</option>
                                                            <option value="Real estate">Real estate</option>
                                                            <option value="Legal">Legal</option>
                                                            <option value="Hospitality">Hospitality</option>
                                                            <option value="Other">Other</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Form.Group>
                                            </div>
                                        </Col>

                                    </Row>
                                </div>

                                <div className="ms-2 mt-3">
                                    <button className="btn" style={{ backgroundColor: '#358D77', color: 'white', height: '48px', fontSize: '20px' }}>Next<span className="ms-1"><ArrowRight style={{ height: '15px', width: '15px' }} /></span></button>
                                </div>
                            </Col>
                            <Col md={4} sm={12} className='p-2'>
                                <div
                                    style={{
                                        height: '100%',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        background: `radial-gradient(47.17% 126.72% at 55.99% 38.29%, rgba(205, 255, 217, 0.2) 0%, rgba(26, 53, 96, 0.2) 100%),linear-gradient(181deg, rgba(205, 255, 217, 0.2) 20%, rgba(30, 124, 109, 0.2) 96%),var(--accent, rgba(198, 232, 223, 1))`,
                                        borderRadius: '1.5rem',
                                    }}
                                >
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section > */}
            {/* candidate step 4/6 page end */}



            {/* candidate step 5/6 page start */}
            {/* <section>
                <div style={{ height: '100vh' }}>
                    <div className='h-100'>
                        <Row className='h-100'>
                            <Col md={8} sm={12} className='p-5 h-100'>
                                <div>
                                    <img src={Logo} style={{ height: '2.5rem' }} ></img>
                                </div>
                                <div className='d-flex gap-2 mt-2 ms-2'>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#358D77', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                </div>

                                <div className="d-flex flex-column">
                                    <h2 className='mb-0 ms-2' style={{ marginTop: '3rem' }}>Are you a fresher or currently working?</h2>
                                    <lable className='ms-2' style={{ color: '#737373', text: 'start' }}>We'll tailor the next steps accordingly.</lable>
                                </div>

                                <div className="ms-2 d-flex flex-column justify-content-center align-items-start p-1" style={{ marginTop: '2rem', backgroundColor: '#f5f5f5', borderRadius: '14px' }}>

                                    <lable style={{ fontWeight: '500', color: 'black' }}>10 of 10 Remaining</lable>
                                    <div className="d-flex flex-wrap gap-1 mt-1">
                                        <lable className='new-chip-ui px-2'>Python<span><X className="new-ui-cross" /></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span><X className="new-ui-cross" /></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span><X className="new-ui-cross" /></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span><X className="new-ui-cross" /></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span><X className="new-ui-cross" /></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span><X className="new-ui-cross" /></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span><X className="new-ui-cross" /></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span><X className="new-ui-cross" /></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span><X className="new-ui-cross" /></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span><X className="new-ui-cross" /></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span><X className="new-ui-cross" /></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span><X className="new-ui-cross" /></span></lable>
                                    </div>

                                    <lable className='mt-2' style={{ fontWeight: '500', color: 'black' }}>Upload certification for the professional skill mentioned (If any)</lable>
                                    <div className="container-fluid p-0 ">
                                        <label
                                            htmlFor="fileInput" className="container-fluid px-2"
                                            style={{ height: '40px', padding: '6px 0px', border: '1px solid #E5E5E5', borderRadius: '8px', fontWeight: '500', display: 'inline-block', backgroundColor: 'white', fontSize: '16px', cursor: 'pointer', borderRadius: '8px', textAlign: 'start' }}
                                        >
                                            Upload File <span style={{ color: 'black', fontWeight: '300' }}>{fileName}</span>
                                        </label>
                                    </div>
                                </div>

                                <div className="container-fluid mt-2" style={{ overflowY: 'scroll', maxHeight: '12rem' }}>
                                    <div>
                                        <lable>Technology & Development</lable>
                                    </div>
                                    <div className="d-flex flex-wrap gap-1 mt-1">
                                        <lable className='new-chip-ui px-2' style={{ backgroundColor: 'white', border: '1px solid #E5E5E5' }}>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                    </div>
                                    <div>
                                        <lable>Technology & Development</lable>
                                    </div>
                                    <div className="d-flex flex-wrap gap-1 mt-1">
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                    </div>
                                    <div>
                                        <lable>Technology & Development</lable>
                                    </div>
                                    <div className="d-flex flex-wrap gap-1 mt-1">
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Python<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Java<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Sql<span></span></lable>
                                        <lable className='new-chip-ui px-2'>Dot Net<span></span></lable>
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <lable className='ms-2'>Upload certification for the professional skill mentioned (If any)</lable>
                                    <div className="container-fluid p-0" style={{ display: 'inline-block', position: 'relative' }}>
                                        <label
                                            htmlFor="fileInput" className="ms-2 w-100"
                                            style={{
                                                padding: '10px 70px 10px 10px', border: '1px solid #E5E5E5', borderRadius: '8px', fontWeight: '500', display: 'inline-block', backgroundColor: 'white', color: 'black', fontSize: '16px', cursor: 'pointer', borderRadius: '8px', textAlign: 'start',
                                            }}
                                        >
                                            Upload File <span style={{ color: 'black', fontWeight: '300' }}>{fileName}</span>
                                        </label>
                                        <input
                                            id="fileInput"
                                            type="file"
                                            style={{
                                                opacity: 0,
                                                position: 'absolute',
                                                zIndex: -1,
                                            }}
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>



                                <div className="ms-2 mt-3">
                                    <button className="btn" style={{ backgroundColor: '#358D77', color: 'white', height: '48px', fontSize: '20px' }}>Next<span className="ms-1"><ArrowRight style={{ height: '15px', width: '15px' }} /></span></button>
                                </div>
                            </Col>
                            <Col md={4} sm={12} className='p-2'>
                                <div
                                    style={{
                                        height: '100%',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        background: `radial-gradient(47.17% 126.72% at 55.99% 38.29%, rgba(205, 255, 217, 0.2) 0%, rgba(26, 53, 96, 0.2) 100%),linear-gradient(181deg, rgba(205, 255, 217, 0.2) 20%, rgba(30, 124, 109, 0.2) 96%),var(--accent, rgba(198, 232, 223, 1))`,
                                        borderRadius: '1.5rem',
                                    }}
                                >
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section > */}
            {/* candidate step 5/6 page end */}



        </>
    )
}

const mapStateToProps = ({ appToast, auth, profileUpdate }) => {
    const {
        isVisible,
        toastMessage,
        background,
        icon,
        position,

    } = appToast

    const { profileUpdateModalVisible } = profileUpdate


    const {
        candidateAuth,
        ID,
        otpRequestSuccess,
        otpRequestError,
        hideSendOtpButton,
        hideSendOtpButton1,
        showEmpVerifyOtpForm,
        showRegistrationForm,
        otpValidateSuccess,
        otpValidateError,
        radioValue,
        registerCandidate,
        newShowEmpRegistrationForm,
        requestOtp,
        showEmpDashboard,
        showRegistrationStep,
        newUiCandidateLoginStep,
        newUiCandiateOtpStep,
        newUiCandidateRegistrationStep,
        newUiCandidateSkillStep,
        newUiCandidateOpportunitiesStep,
        newUiCandidateExperience,
        message,
        verificationOtpMessage
    } = auth

    return {
        candidateAuth,
        ID,
        appToastVisible: isVisible,
        appToastMessage: toastMessage,
        appToastBackground: background,
        appToastIcon: icon,
        appToastPosition: position,
        otpRequestSuccess,
        otpRequestError,
        hideSendOtpButton,
        hideSendOtpButton1,
        showEmpVerifyOtpForm,
        showRegistrationForm,
        otpValidateSuccess,
        otpValidateError,
        registerCandidate,
        newShowEmpRegistrationForm,
        requestOtp,
        showEmpDashboard,
        showRegistrationStep,
        newUiCandidateLoginStep,
        newUiCandiateOtpStep,
        newUiCandidateRegistrationStep,
        newUiCandidateSkillStep,
        newUiCandidateOpportunitiesStep,
        newUiCandidateExperience,
        profileUpdateModalVisible,
        message,
        verificationOtpMessage
    }
}

const mapDispatchToProps = {
    showAppToast,
    hideAppToast,
    requestOtp,
    validateOtp,
    registerUser,
    backToLogin,
    setCourseID,
    setXpLevel,
    addUser,
    updateRegistrationStep,
    showAlert
}
export default connect(mapStateToProps, mapDispatchToProps)(CandidateSignup)