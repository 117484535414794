import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
  Card,
  Button,
  Form,
  InputGroup,
  Modal,
  Image,
  Row,
  Col
} from 'react-bootstrap'
import Select from 'react-select'
import {
  generalSkillOptions,
  professionalSkillOptions
} from '../../features/constants/skillsData'
import { Navigate, useLocation, useNavigate } from 'react-router'
// import userPhoto from '../images/user-profile.webp';
import logoPhoto from '../images/Favicon_large-removebg-preview.png'
import userPhoto from '../images/profile-placeholder.jpeg'
import {
  GetAllApplicationsOfDrive,
  GetCandidateByID,
  UpdateUserProfile,
  deleteCandidateEducationRecord,
  deleteCandidateExperienceRecord,
  fetchAllJobRoles,
  fetchAllJobRolesSkills,
  getRemovedSkills,
  handelOpenToWork,
  postUserImage,
  shortlistApplicant,
  UserJoinStatus,
  updateUserCV,
  postUserCertificates
} from '../../features/constants/apis'
import AddNewEducation from './Modals/AddNewEducation'
import { FaArrowLeft, FaPen, FaPlus } from 'react-icons/fa'
import AddNewExperience from './Modals/AddNewExperience'
import axios from 'axios'
import { RiDeleteBin6Line } from 'react-icons/ri'
import UpdateEducationModal from './Modals/UpdateEducationModal'
import UpdateExperienceModal from './Modals/UpdateExperienceModal'
import { FaUpload } from 'react-icons/fa'
import FileUploadWithProgress from './FileUploadWithProgress'
import tick from '../images/icon/tick-image.png'
import gayTick from '../images/icon/tick-image-gray.png'
import { Link } from 'react-router-dom'
import GetVspModal from './Modals/GetVspModal'
import ProgressBar from 'react-bootstrap/ProgressBar'
import TextAnimation from './MacroComponents/TextAnimation'
import { Alert, colors } from '@mui/material'
import ScopehaiLogo from '../images/scopehai-favicon.png'
import StarIcon from '../images/star.png'
import { HiOutlinePencil } from 'react-icons/hi2'
import { CiMail } from 'react-icons/ci'
import { FiPhone } from 'react-icons/fi'
import { LuCake, LuUpload } from 'react-icons/lu'
import { IoLocationOutline, IoAdd } from 'react-icons/io5'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { showProfileUpdateModal } from '../../features/popup/ProfileUpdateModalSlice'
import { showVerificationModal } from '../../features/popup/VerificationModalSlice'
import { Loader } from 'lucide-react'
import { setProfileGraphCount } from '../../features/data/DataSlice'
import { showAlert } from '../../features/utilities/appToastSlice'
import VerifiedBlueLogoMark from "../images/VerifiedBlueLogoMark.svg"

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
)

const CandidateProfile = ({
  fullName,
  role,
  authToken,
  ID,
  showProfileUpdateModal,
  showVerificationModal,
  setProfileGraphCount,
  profileUpdateModalVisible,
  showAlert
}) => {
  console.log('authToken: ', authToken);

  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const [openToJoin, setOpenToJoin] = useState(Boolean)
  const [openToSwitch, setOpenToSwitch] = useState(Boolean)
  const [expectedCTC, setExpectedCTC] = useState('')
  const [currentCTC, setCurrentCTC] = useState('')
  const [openForJob, setOpenForJob] = useState(null)
  const [userCurrentStatus, setUserCurrentStatus] = useState('')
  const [cvUploaded, setCvUploaded] = useState(false) // State variable to track CV upload
  const [downloadCVUrl, setDownloadCVUrl] = useState('')
  // photo upload
  const [uploadedImage, setUploadedImage] = useState('')
  const [fileUploaded, setFileUploaded] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)
  const [imageSet, setImageSet] = useState(false)
  const [uploadedFile, setUploadedFile] = useState([])
  const fileInputRef = useRef(null)

  const [userInfo, setUserInfo] = useState([])
  const [isEditingProfile, setIsEditingProfile] = useState(false)

  const [userDomain, setUserDomain] = useState('')

  const [userEduction, setUserEducation] = useState([])
  const [userExperience, setUserExperience] = useState([])

  const [jobRoles, setJobRoles] = useState([])

  // VSP  modal
  const [showGetVspModal, setShowGetVspModal] = useState(false)
  const [vspModalShow, setVspModalShow] = useState(false)

  // Add new Education modal
  const [eduModalShow, setEduModalShow] = useState(false)
  const [expModalShow, setExpModalShow] = useState(false)

  // Delete modals
  const [modalShow, setModalShow] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [eduIdToDeleted, setEduIdToDeleted] = useState(null)
  const [expIdToDeleted, setExpIdToDeleted] = useState(null)
  const [modalTitle, setModalTitle] = useState('')
  const [modalMessage, setModalMessage] = useState('')

  // Update Education Modal
  const [updateEduModalShow, setUpdateEduModalShow] = useState(false)
  const [editabelEducation, setEditableEducation] = useState([])

  // Update Experience Modal
  const [updateExpModalShow, setUpdateExpModalShow] = useState(false)
  const [editableExperience, setEditableExperience] = useState([])

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const candidateID = searchParams.get('candidate')
  const candidatID_By_SarchParams = searchParams.get('candidateID')
  const jobID = searchParams.get('job')

  // Edit Bio
  const [editableUserFullName, setEditableUserFullName] = useState('')
  const [editableUserDomain, setEditableUserDomain] = useState('')
  const [editableDescription, setEditableDescription] = useState('')
  const [editableGenSkills, setEditableGenSkills] = useState([])
  const [editableProSkills, setEditableProSkills] = useState([])

  // shortlist candidate index
  const [currentApplicantIndex, setCurrentApplicantIndex] = useState(0)

  // job applicants
  const [jobApplicants, setJobApplicants] = useState([])
  const [perticularApplication, setPerticularApplication] = useState([])

  // removed skills
  const [removedGeneralSkills, setRemovedGeneralSkills] = useState([])
  const [removedProfessinalSkills, setRemovedProfessinalSkills] = useState([])

  // progressbar
  const [profileProgressCount, setProfileProgressCount] = useState(0)

  // skills lis
  const [generalSkillsList, setGeneralSkillsList] = useState([])
  const [proSkillsList, setProSkillsList] = useState([])

  const [profileProgressCountRem, setProfileProgressCountRem] = useState(0)
  const [uploadingLoader, setUploadingLoader] = useState()
  const [uploadingCertificateLoader, setUploadingCertificateLoader] = useState()
  const [matchingJobRoles, setMatchingJobRoles] = useState()
  const [relatedJobRoles, setRelatedJobRoles] = useState([])
  const initialJoiningObj = {
    status: false,
    expectedCTC: false,
    currentCTC: false,
    fullTime: false,
    partTime: false,
    internship: false,
    remote: false,
    onField: false
  }
  const [tempJoiningObj, setTempJoiningObj] = useState(initialJoiningObj)
  const [disableSubmitCTC, setDisableSubmitCTC] = useState(false)

  useEffect(() => {
    const removedSkillsData = async () => {
      const data = await getRemovedSkills(authToken, candidateID)
      setRemovedGeneralSkills(data.generalSkills)
      setRemovedProfessinalSkills(data.professionalSkills)
    }

    removedSkillsData()
  }, [candidateID])

  useEffect(() => {
    if (role === 'hr' || role === 'Counsellor') {
      const UserData = async () => {
        const data = await GetCandidateByID(
          //           candidateID ? candidateID : candidatID_By_SarchParams // BETA
          candidateID ? candidateID : candidatID_By_SarchParams, authToken
        )
        console.log('get by id api : ', data.message, data)
        if (role === 'Candidate' || role === 'hr' || role === 'Counsellor') {
          setUserInfo(data.data)
          setUserEducation(data.data?.Education)
          setEditableEducation(data.data?.Education)
          setUserExperience(data.data?.Experience)
          console.log('data.data')
          console.log(data.data)
          setUserDomain(data.data?.CareerProfile.domain)
          setCurrentCTC(data.data?.CareerProfile.expectedCtc)
          setExpectedCTC(data.data?.CareerProfile.incomeCTC)
          console.log('incoming user data expectedCtc: ', data.data)
          setOpenForJob(data.data?.JoiningStatus == 'true' ? true : false)
          setTempJoiningObj(data.data?.JoiningInfo)
        }
      }
      UserData()
    } else {
      const UserData = async () => {
        const data = await GetCandidateByID(
          candidateID ? candidateID : ID,
          authToken
        )
        console.log('get by id api : ', data.message, data)
        if (role === 'Candidate') {
          console.log(data.data)
          setUserInfo(data.data)
          setUserEducation(data.data?.Education)
          setEditableEducation(data.data?.Education)
          setUserExperience(data.data?.Experience)
          setUserDomain(data.data?.CareerProfile.domain)
          setCurrentCTC(data.data?.CareerProfile.expectedCtc)
          setExpectedCTC(data.data?.CareerProfile.incomeCTC)
          console.log('incoming user data expectedCtc: ', data.data)
          setOpenForJob(data.data?.JoiningStatus == 'true' ? true : false)
          setTempJoiningObj(data.data?.JoiningInfo)
        }
      }
      UserData()
    }
  }, [
    ID,
    authToken,
    role,
    candidateID,
    cvUploaded,
    profileUpdateModalVisible,
    imageSet
  ])

  const profileStrengthData = {
    datasets: [
      {
        label: '# strong',
        data: [profileProgressCount, profileProgressCountRem],
        backgroundColor: ['rgba(5, 150, 105, 1)', 'rgba(229, 229, 229, 1)'],
        borderColor: ['rgba(5, 150, 105, 1)', 'rgba(229, 229, 229, 1)'],
        borderWidth: 1
      }
    ],
    labels: ['Strength']
  }

  const profileStrengthOptions = {
    plugins: {
      legend: {
        display: false,
        position: 'bottom' // Position the legend below the chart
      }
    },
    cutout: '70%',
    maintainAspectRatio: false,
    responsive: true
  }

  // This function will be called when the CV is uploaded successfully
  const handleCVUploadSuccess = () => {
    setCvUploaded(prev => !prev)
  }
  // jobRole

  useEffect(() => {
    const fetchJobRoles = async () => {
      const data = await fetchAllJobRoles(authToken)
      setJobRoles(data.data)
    }
    fetchJobRoles()
  }, [authToken])

  useEffect(() => {
    if (userInfo.CareerProfile && jobRoles.length > 0) {
      const domainValue = userInfo.CareerProfile.domain

      const matchedJobRole = jobRoles.find(
        jobRole => jobRole.ID === domainValue
      )

      if (matchedJobRole) {
        const domain = matchedJobRole.Domain

        const relatedJobRoles = jobRoles
          // .filter(jobRole => jobRole.Domain === domain)
          .map(jobRole => jobRole.Title)

        // console.log(relatedJobRoles)
        setRelatedJobRoles(relatedJobRoles)
      }
    }
  }, [userInfo.CareerProfile, jobRoles])

  // console.log(relatedJobRoles)
  // console.log(jobRoles)

  // skills from job roles
  const [jobRolesSkills, setJobRolesSkills] = useState({
    GeneralSkills: [],
    ProfessionalSkills: []
  })

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAllJobRolesSkills(authToken)
      setJobRolesSkills(data.data)
    }
    fetchData()
  }, [authToken])

  useEffect(() => {
    if (jobRolesSkills) {
      const generalSkillOptions = jobRolesSkills?.GeneralSkills?.map(skill => ({
        value: skill,
        label: skill
      }))

      const professionalSkillOptions = jobRolesSkills?.ProfessionalSkills?.map(
        skill => ({
          value: skill,
          label: skill
        })
      )
      setGeneralSkillsList(generalSkillOptions)
      setProSkillsList(professionalSkillOptions)
    }
  }, [jobRolesSkills])

  const refetchUserData = async () => {
    const data = await GetCandidateByID(ID, authToken)
    setUserEducation(data?.data?.Education)
    setUserExperience(data?.data?.Experience)
  }

  // Edit user profile
  const handleProfileEdit = loadType => {
    setEditableUserFullName(userInfo?.FullName || '')
    setEditableUserDomain(userInfo?.CareerProfile?.domain || '')
    setEditableDescription(userInfo?.Description || '')

    const defaultGenSkills = generalSkillsList?.filter(option =>
      userInfo?.SkillProfile?.GeneralSkills?.includes(option.value)
    )
    setEditableGenSkills(defaultGenSkills)

    const defaultProSkills = proSkillsList?.filter(option =>
      userInfo?.SkillProfile?.ProfessionalSkills?.includes(option.value)
    )
    setEditableProSkills(defaultProSkills)

    if (loadType !== 'firstLoad') {
      if (role === 'Candidate') {
        setIsEditingProfile(true)
      }
    }
  }

  const EditUserProfile = async (userData, actionType) => {
    const updateProfile = await UpdateUserProfile(ID, userData, authToken)
    // console.log('user Data: ', userData)
    if (updateProfile.status == '200' && actionType === 'doLater') {
      navigate('/candidate-dashboard')
    }
    const data = await GetCandidateByID(ID, authToken)
    if (role === 'Candidate') {
      setUserInfo(data.data)
      setUserEducation(data.data?.Education)
      setUserExperience(data.data?.Experience)
    }
  }

  const handleSaveEditedProfile = actionType => {
    let status = ''
    if (openToJoin === true) {
      status = 'join'
      // console.log('status changed to join')
    } else if (openToSwitch === true) {
      status = 'switch'
      // console.log('status changed to switch')
    } else {
      status = 'null'
      // console.log('status changed to null')
    }
    // console.log('final status which is setting in usercurent status: ', status)

    const editedUserInfo = {
      name: editableUserFullName,
      domain: editableUserDomain,
      generalSkills: editableGenSkills.map(skill => skill.value),
      professionalSkills: editableProSkills.map(skill => skill.value),
      description: editableDescription,
      JoiningStatus: status,
      CareerProfile: {
        degree: userInfo?.CareerProfile?.degree,
        // 'domain': userInfo.CareerProfile?.domain,
        domain: editableUserDomain,
        education: userInfo.CareerProfile?.education,
        experience: userInfo.CareerProfile?.experience,
        incomeCTC: currentCTC || userInfo.CareerProfile?.incomeCTC || null,
        expectedCtc: expectedCTC
      },
      ProfileProgress: profileProgressCount
    }
    // console.log('editedUserInfo', editedUserInfo)
    // console.log('userInfo', userInfo)

    if (actionType === 'doLater') {
      EditUserProfile(editedUserInfo, actionType)
    } else {
      EditUserProfile(editedUserInfo)
    }

    setIsEditingProfile(false)
  }

  const handleProfileCancelEdit = () => {
    setEditableUserFullName(userInfo?.FullName)
    setEditableUserDomain(userInfo?.CareerProfile?.domain)
    setEditableDescription(userInfo?.Description)
    setIsEditingProfile(false)
  }

  const uploadRefImage = async filePath => {
    // console.log('upload image fuc triggerd !!!!!')

    setFileUploaded(true)
    const formData = new FormData()
    formData.append('inputFile', filePath)
    try {
      const res = await axios.post(
        'https://api.allpos.in/applications/upload/scopehai',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      setUploadedImage(res.data.data)
      setUploadingLoader(false)
      setFileUploaded(false)
      return res.data.data // Return the uploaded image URL
    } catch (err) {
      console.error('Error uploading file:', err)
      setUploadingLoader(false)
      setFileUploaded(false)
      throw err
    }
  }

  const handleImageChange = async e => {
    const file = e.target.files[0]
    if (file) {
      setPreviewImage(URL.createObjectURL(file))
      try {
        const imageUrl = await uploadRefImage(file) // Wait for the image URL
        await handleSetImage(imageUrl) // Call handleSetImage with the image URL
      } catch (error) {
        console.error('Error handling image change:', error)
      }
    }
  }

  // Deleting Education and Experience
  const handleShowDeleteModal = (item, eduID = null, expID = null) => {
    if (item === 'education') {
      console.log("handel delete modal", eduID, item);
      setModalTitle('Delete Education')
      setModalMessage(
        'Are you sure you want to delete this record? This action can not be undone.'
      )
      setItemToDelete(item)
      setEduIdToDeleted(eduID)
      handleDelete()

    }
    if (item === 'experience') {
      console.log("handel delete modal", expID, item);
      setModalTitle('Delete Experience')
      setModalMessage(
        'Are you sure you want to delete this record? This action can not be undone.'
      )
      setItemToDelete(item)
      setExpIdToDeleted(expID)
      handleDelete()
    }
    setModalShow(true)
  }

  const fetchUserData = async () => {
    const data = await GetCandidateByID(ID, authToken)
    if (role === 'Candidate') {
      setUserInfo(data.data)
      setUserEducation(data.data?.Education)
      setUserExperience(data.data?.Experience)
    }
  }

  const handleDelete = async () => {
    console.log("handel delete func triggerd");

    if (itemToDelete === 'education') {
      await deleteCandidateEducationRecord(authToken, ID, eduIdToDeleted)
    } else if (itemToDelete === 'experience') {
      await deleteCandidateExperienceRecord(authToken, ID, expIdToDeleted)
    }

    setModalShow(false)
    fetchUserData()
  }

  const handleCloseGetVspModal = () => {
    setShowGetVspModal(false)
  }

  // VSP MODAL
  // const handleShowGetVspModal = () => {
  //     setUpdateEduModalShow(true)

  // }

  // Edit Education
  const handleShowEduUpdateModal = education => {
    setUpdateEduModalShow(true)
    setEditableEducation(education)
  }

  // Edit Experience
  const handleShowExpUpdateModal = experience => {
    setUpdateExpModalShow(true)
    setEditableExperience(experience)
  }

  // upload CV
  const [uploadProgress, setUploadProgress] = useState(0)

  const handleFileChange = e => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onprogress = event => {
        if (event.loaded && event.total) {
          const progress = (event.loaded / event.total) * 100
          setUploadProgress(progress)
        }
      }
      reader.onloadend = () => {
        setUploadProgress(100)
        setTimeout(() => setUploadProgress(0), 2000) // Reset after 2 seconds
      }
      reader.readAsDataURL(file)
    }
  }

  // candidates
  useEffect(() => {
    const fetchPDApplications = async () => {
      const data = await GetAllApplicationsOfDrive(authToken)
      setJobApplicants(
        data.filter(application => application?.EmployerID === ID)
      )

      //get perticular application
      const filteredPerticularApplication = data.filter(
        application =>
          application?.JobID === parseInt(jobID) &&
          application?.CandidateID === parseInt(candidateID) &&
          application?.EmployerID === ID
      )

      setPerticularApplication(filteredPerticularApplication)
    }
    fetchPDApplications()
  }, [authToken, ID, candidateID, jobID])

  const navigate = useNavigate()
  const handleLater = event => {
    const form = event.currentTarget
    if (openToSwitch) {
      // console.log('open to switch validation 1')
      if (!expectedCTC) {
        // console.log('open to switch validation 2')
        setValidated(true)
        return
      }
      if (!currentCTC) {
        // console.log('open to switch validation 3')
        setValidated(true)
        return
      } else {
        setValidated(false)
      }
    }
    if (openToJoin || openToSwitch) {
      if (!expectedCTC) {
        setValidated(true)
        return
      }
      if (!expectedCTC) {
        setValidated(true)
        return
      }
    }
    setValidated(false)

    handleSaveEditedProfile('doLater')
  }

  // shortlist and next
  const handlleShortlistAndNext = async () => {
    setCurrentApplicantIndex(prevIndex => {
      const newIndex = prevIndex + 1
      if (newIndex < jobApplicants.length) {
        const nextCandidateID = jobApplicants[newIndex].CandidateID
        const nextJobID = jobApplicants[newIndex].JobID
        navigate(
          `/candidate-profile?candidate=${nextCandidateID}&job=${nextJobID}`
        )
      } else {
        setCurrentApplicantIndex(0)
      }
      return newIndex
    })
    await shortlistApplicant(authToken, jobID, candidateID)
  }
  const viewNextApplicant = async () => {
    setCurrentApplicantIndex(prevIndex => {
      const newIndex = prevIndex + 1
      if (newIndex < jobApplicants.length) {
        const nextCandidateID = jobApplicants[newIndex].CandidateID
        const nextJobID = jobApplicants[newIndex].JobID
        navigate(
          `/candidate-profile?candidate=${nextCandidateID}&job=${nextJobID}`
        )
      } else {
        setCurrentApplicantIndex(0)
      }
      return newIndex
    })
  }

  // const calculateProfileProgress = () => {
  //     let progressCount = 0
  //     console.log('USER INFO', userInfo)
  //     console.log(editableUserFullName, editableUserDomain, editableDescription, editableGenSkills, editableProSkills, userInfo?.CV, userInfo?.Image)
  //     if (userInfo?.FullName && userInfo?.FullName?.trim().length > 3) {
  //         progressCount = progressCount + 1
  //     }
  //     if (userInfo?.CareerProfile?.degree && userInfo?.CareerProfile?.degree?.length > 0) {
  //         progressCount = progressCount + 1
  //     }
  //     if (userInfo?.Description && userInfo?.Description?.length > 0) {
  //         progressCount = progressCount + 1
  //     }
  //     if (userInfo?.Image && userInfo?.Image?.length > 0) {
  //         progressCount = progressCount + 1
  //     }
  //     if (userInfo?.SkillProfile?.ProfessionalSkills?.length > 0) {
  //         progressCount = progressCount + 1
  //     }
  //     if (userInfo?.Email && userInfo?.Email?.length > 0) {
  //         progressCount = progressCount + 1
  //     }
  //     if (userInfo?.Phone && userInfo?.Phone?.length > 0) {
  //         progressCount = progressCount + 1
  //     }
  //     if (userInfo?.Dob && userInfo?.Dob?.length > 0) {
  //         progressCount = progressCount + 1
  //     }
  //     if (userInfo?.Location && userInfo?.Location?.length > 0) {
  //         progressCount = progressCount + 1
  //     }
  //     if (userInfo?.CV && userInfo?.CV?.length > 0) {
  //         progressCount = progressCount + 1
  //     }

  //     const progressPercent = Math.round((progressCount / 10) * 100)
  //     setProfileProgressCount(progressPercent)
  //     setProfileProgressCountRem(100 - parseInt(progressPercent))
  //     setProfileGraphCount({ progressPercentage: progressPercent, progressRemainingPercentage: 100 - parseInt(progressPercent) })
  //     console.log("progress bar count: ", progressPercent);
  // }

  const calculateProfileProgress = () => {
    let progressCount = 0
    let totalWeightage = 0

    // console.log('USER INFO', userInfo)
    // console.log(
    //   editableUserFullName,
    //   editableUserDomain,
    //   editableDescription,
    //   editableGenSkills,
    //   editableProSkills,
    //   userInfo?.CV,
    //   userInfo?.Image
    // )

    // 60% weightage items
    const majorWeightage = 60
    const majorItems = [
      { value: userInfo?.FullName, requiredLength: 3 }, // Check if FullName has at least 3 characters
      { value: userInfo?.CareerProfile?.degree }, // Check if degree exists
      { value: userInfo?.CV }, // Check if CV exists
      { value: userInfo?.Image } // Check if Image exists
    ]

    // Calculate major progress
    let majorProgress = majorItems.reduce((acc, item) => {
      return (
        acc +
        (item.value && item.value.trim?.().length > (item.requiredLength || 0)
          ? 1
          : 0)
      )
    }, 0)

    // Additional check for ProfessionalSkills array
    if (
      Array.isArray(userInfo?.SkillProfile?.ProfessionalSkills) &&
      userInfo?.SkillProfile?.ProfessionalSkills?.length > 0
    ) {
      majorProgress += 1
    }

    // Weight major progress
    progressCount += (majorProgress / (majorItems.length + 1)) * majorWeightage // +1 for ProfessionalSkills
    totalWeightage += majorWeightage

    // 20% weightage for Description
    const descriptionWeightage = 20
    if (userInfo?.Description && userInfo?.Description.trim().length > 0) {
      progressCount += descriptionWeightage
    }
    totalWeightage += descriptionWeightage

    // 20% weightage items (Email, Phone, DOB)
    const minorWeightage = 20
    const minorItems = [userInfo?.Email, userInfo?.Phone, userInfo?.Dob]

    let minorProgress = minorItems.reduce((acc, item) => {
      return acc + (item && item.trim?.().length > 0 ? 1 : 0)
    }, 0)
    progressCount += (minorProgress / minorItems.length) * minorWeightage
    totalWeightage += minorWeightage

    // Ensure total progress doesn't exceed 100
    const progressPercent = Math.min(Math.round(progressCount), 100)

    // Set progress values
    setProfileProgressCount(parseInt(progressPercent))
    setProfileProgressCountRem(100 - parseInt(progressPercent))
    setProfileGraphCount({
      progressPercentage: parseInt(progressPercent),
      progressRemainingPercentage: 100 - parseInt(progressPercent)
    })

    // console.log('Progress bar count: ', progressPercent)
  }

  useEffect(() => {
    calculateProfileProgress()
    setUploadedImage(userInfo?.CV)
  }, [
    editableUserFullName,
    editableUserDomain,
    editableDescription,
    editableGenSkills,
    editableProSkills,
    userInfo.CV,
    userInfo.Image,
    userInfo,
    authToken
  ])

  useEffect(() => {
    if (userInfo) {
      handleProfileEdit('firstLoad')
    }
  }, [userInfo, generalSkillsList, proSkillsList])

  const messages = [
    'Use your full name for a professional profile.',
    'Showcase skills to stand out.',
    'Upload a photo to make a strong impression.',
    'A detailed CV boosts your chances.',
    'Add education to show qualifications.'
  ]

  ///////////////////////////////////////////////////// radio toggle
  const handleOpenForJob = async jobStatus => {
    console.log(jobStatus.target.checked)
    const newValue = jobStatus.target.checked
    setOpenForJob(newValue)
    const joiningData = {
      JoiningStatus: newValue
    }
    UpdateUserProfile(ID, joiningData, authToken)
    const data = await GetCandidateByID(ID, authToken)
    if (role === 'Candidate') {
      setUserInfo(data.data)
      setUserEducation(data.data?.Education)
      setUserExperience(data.data?.Experience)
    }
  }

  const updateJoiningObj = async (type, value) => {
    console.log(type, value)
    let updatedObj = JSON.parse(
      JSON.stringify(tempJoiningObj ? tempJoiningObj : initialJoiningObj)
    )
    // if (type == 'toggleButton') {
    //   updatedObj['status'] = value == true ? 'switch' : 'join'
    // } else {
    //   updatedObj[type] = value
    // }
    let JoiningStatus
    if (type == 'join' || type == 'switch') {
      if (type == 'join') {
        updatedObj['status'] = value == true ? 'join' : null
      } else if (type == 'switch') {
        updatedObj['status'] = value == true ? 'switch' : null
      }
      if (updatedObj['status']) {
        JoiningStatus = true
      } else {
        JoiningStatus = false
      }
    } else {
      updatedObj[type] = value
    }
    console.log(updatedObj)
    setTempJoiningObj(updatedObj)
    const updateProfile = await UpdateUserProfile(
      ID,
      { joiningInfo: updatedObj, JoiningStatus: JoiningStatus },
      authToken
    )
    const data = await GetCandidateByID(ID, authToken)
    if (role === 'Candidate') {
      setUserInfo(data.data)
      setUserEducation(data.data?.Education)
      setUserExperience(data.data?.Experience)
    }
  }

  const updateCTC = (type, value) => {
    let updatedObj = JSON.parse(
      JSON.stringify(tempJoiningObj ? tempJoiningObj : initialJoiningObj)
    )
    updatedObj[type] = value
    setTempJoiningObj(updatedObj)
  }

  const submitCTC = async () => {
    setDisableSubmitCTC(true)
    const updateProfile = await UpdateUserProfile(
      ID,
      { joiningInfo: tempJoiningObj },
      authToken
    )
    const data = await GetCandidateByID(ID, authToken)
    // console.log(updateProfile)
    if (updateProfile.message == 'Success') {
      setDisableSubmitCTC(false)
    }
  }

  // Handlers for input changes
  const handleExpectedCTCChange = e => setExpectedCTC(e.target.value)
  const handleCurrentCTCChange = e => setCurrentCTC(e.target.value)

  // console.log("expected CTC: ", expectedCTC);
  // console.log("current CTC: ", currentCTC);

  // console.log("user data after saving in state: ", userInfo);
  const setUserJoinStatus = () => {
    const newRegisteredData = {
      degree: userInfo?.CareerProfile?.degree,
      domain: userInfo.CareerProfile?.domain,
      education: userInfo.CareerProfile?.education,
      experience: userInfo.CareerProfile?.experience,
      incomeCTC: currentCTC || userInfo.CareerProfile?.incomeCTC || null,
      expectedCtc: expectedCTC
    }
    // console.log("updated data: ", newRegisteredData);
    return newRegisteredData
  }
  let userID = ID

  const [validated, setValidated] = useState(false)

  const handleUserStatus = event => {
    const form = event.currentTarget
    if (openToSwitch) {
      // console.log('open to switch validation 1')
      if (!expectedCTC.trim()) {
        // console.log('open to switch validation 2')
        setValidated(true)
        return
      }
      if (!currentCTC?.trim()) {
        // console.log('open to switch validation 3')
        setValidated(true)
        return
      } else {
        setValidated(false)
      }
    }

    event.preventDefault()
    // console.log(setUserJoinStatus())
    // setSendOtpButton(true)
    UserJoinStatus({ ...setUserJoinStatus(), userID })
  }

  const handleAddEducation = education => {
    // console.log('handleAddEducation running')
    // console.log('education:', education)

    showProfileUpdateModal({
      profileUpdateModalVisible: true,
      profileUpdateType: 'Add Education',
      userInfo: userInfo,
      education: education,
      relatedJobRoles: 'hello'
    })
  }

  const handleAddExperience = experience => {
    console.log('experience: ', experience)
    showProfileUpdateModal({
      profileUpdateModalVisible: true,
      profileUpdateType: 'Add Position',
      userInfo,
      experience: experience
    })
  }
  const handleEditIntro = () => {
    showProfileUpdateModal({
      profileUpdateModalVisible: true,
      profileUpdateType: 'Edit Intro',
      userInfo,
      relatedJobRoles: relatedJobRoles
    })
  }
  const handleEditPersonalInformation = () => {
    // console.log('userInfo: ', userInfo)

    showProfileUpdateModal({
      profileUpdateModalVisible: true,
      profileUpdateType: 'Edit Personal Information',
      userInfo
    })
  }
  const handleEditSkills = () => {
    showProfileUpdateModal({
      profileUpdateModalVisible: true,
      profileUpdateType: 'Edit Skills',
      userInfo
    })
  }
  const handleVerification = () => {
    showVerificationModal({ verificationModalVisible: true })
  }
  // console.log('user info :', userInfo)

  const handleFileUpload = async e => {
    setUploadingLoader(true)
    console.log('handleImageChange fuc triggerd !!!!!')

    const file = e.target.files[0]
    // console.log('Selected file:', file)
    if (file) {
      // setPreviewImage(URL.createObjectURL(file));
      try {
        const imageUrl = await uploadRefImage(file) // Wait for the image URL
        // console.log('imageUrl: ', imageUrl)

        // console.log('Call handleSetImage with the image URL')
        await handleSetImage(imageUrl) // Call handleSetImage with the image URL
      } catch (error) {
        console.error('Error handling image change:', error)
      }
    }
  }

  const handleSetImage = async imageUrl => {
    // console.log('handel set image fuc triggerd !!!!!')
    // console.log('image URL: ', imageUrl)

    const cvObject = {
      cv: imageUrl
    }

    await updateUserCV(ID, cvObject, authToken)

    setUserInfo(prevUserInfo => ({
      ...prevUserInfo,
      CV: imageUrl // Use the provided image URL
    }))

    setImageSet(true)
    setPreviewImage(null)

    fileInputRef.current.value = ''
  }

  const handleOpenDoc = url => {
    window.open(url, '_blank')
  }

  if (!userEduction) {
    // console.log('userEduction is null or undefined')
  } else if (!Array.isArray(userEduction)) {
    // console.log('userEduction is not an array')
  } else if (userEduction.length === 0) {
    // console.log('userEduction array is empty')
  }

  ////////////////////////////////////////////////////////////////////////////////////////
  const ChandleFileUpload = async e => {
    // setUploadingLoader(true)
    setUploadingCertificateLoader(true)
    console.log('handle ImageChange for certificates  fuc triggerd !!!!!')

    const file = e.target.files[0]
    console.log('Selected file:', file)
    if (file) {
      // setPreviewImage(URL.createObjectURL(file));
      try {
        const imageUrl = await CuploadRefImage(file) // Wait for the image URL
        console.log('imageUrl: ', imageUrl)

        console.log('Call handleSetImage with the image URL')
        await ChandleSetImage(uploadedFile) // Call handleSetImage with the image URL
      } catch (error) {
        console.error('Error handling image change:', error)
      }
    }
  }

  const CuploadRefImage = async filePath => {
    console.log(filePath)
    console.log('upload ref fuc for certificates triggerd !!!!!')

    setFileUploaded(true)
    const formData = new FormData()
    console.log('FormData:', formData)
    formData.append('inputFile', filePath)
    try {
      const res = await axios.post(
        'https://api.allpos.in/applications/upload/test',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      console.log('getting consoled  !!!!!')
      console.log('uploaded image data : ', res.data.data)

      let certificates = uploadedFile
      console.log(certificates)
      certificates.push(res.data.data)
      console.log(certificates)
      setUploadedFile(certificates)
      setFileUploaded(false)
      setUploadingLoader(false)
      setUploadingCertificateLoader(false)
      return res.data.data // Return the uploaded image URL
    } catch (err) {
      console.error('Error uploading file:', err)
      setFileUploaded(false)
      setUploadingLoader(false)
      setUploadingCertificateLoader(false)
      throw err
    }
  }

  const ChandleSetImage = async imageUrl => {
    console.log('handel set image for certificate fuc triggerd !!!!!')
    console.log('image URL: ', imageUrl)

    const imageUrlObject = {
      image: imageUrl
    }
    console.log('before triggering the image upload to DB: ', imageUrlObject)

    await postUserCertificates(ID, imageUrlObject, authToken)

    setUserInfo(prevUserInfo => ({
      ...prevUserInfo,
      Image: imageUrl // Use the provided image URL
    }))

    setImageSet(true)
    setPreviewImage(null)

    fileInputRef.current.value = ''
  }

  // console.log('userEduction: ', userEduction)

  // console.log(userInfo?.Image, previewImage, userPhoto)
  return (
    <>
      <div id="appCapsule" style={{ backgroundColor: (userInfo?.HrComments) ? '#C6E8DF' : '#f5f5f5' }}>
        <div class="container-fluid newUI-container-fluid m-auto">
          <div class="row mt-3">
            <div class="col-12 col-md-12 d-flex align-items-center">
              <Link to="/" className="m-0 text-black fs-6 fw-bold">
                <span>
                  <ion-icon name="arrow-back-outline" class="me-2"></ion-icon>
                  &nbsp;
                </span>
                Back to Dashboard
              </Link>
              {alertMessage && (
                <Alert
                  variant="danger"
                  onClose={() => setAlertMessage(null)}
                  dismissible
                >
                  {alertMessage}
                </Alert>
              )}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-md-8">
              <div class="card shadow-none mb-2">
                <div class="card-body">
                  <div class="mb-2">
                    <div class="row">
                      <div class="col-12 col-md-6 mb-1 d-flex">
                        {userInfo?.Image && previewImage && (
                          <img
                            src={previewImage}
                            width="42"
                            height="42"
                            class="rounded-circle me-2"
                            style={{ objectFit: 'cover' }}
                          />
                        )}
                        {userInfo?.Image && !previewImage && (
                          <img
                            src={userInfo?.Image}
                            width="42"
                            height="42"
                            class="rounded-circle me-2"
                            style={{ objectFit: 'cover' }}
                          />
                        )}
                        {!previewImage && !userInfo.Image && (
                          <img
                            src={userPhoto}
                            width="42"
                            height="42"
                            class="rounded-circle me-2"
                            style={{ objectFit: 'cover' }}
                          />
                        )}
                        <div class="d-flex flex-column">
                          <h4 class="mb-0 fw-bold d-flex align-items-center">
                            <span>{userInfo?.FullName}</span>&nbsp;
                            {userInfo?.HrComments &&
                              <img src={VerifiedBlueLogoMark} />
                            }
                          </h4>
                          <h6
                            class="mb-0 text-secondary"
                            style={{ marginTop: '3px' }}
                          >
                            {userInfo?.ExperienceLevel === 'Experienced'
                              ? userInfo?.CareerProfile?.degree
                              : userInfo?.CareerProfile?.degree}
                          </h6>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end align-items-center">
                        {!userInfo.HrComments &&
                          <button
                            class="btn me-2"
                            onClick={() => {
                              handleVerification()
                            }}
                            style={{ background: '#f5f5f5' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 24 24"
                              fill="#2563EB"
                              stroke="#f5f5f5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="lucide lucide-badge-check"
                            >
                              <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
                              <path d="m9 12 2 2 4-4" />
                            </svg>
                            <span style={{ marginLeft: '3px' }}>
                              Get Verified
                            </span>
                          </button>
                        }
                        <button
                          class="btn"
                          onClick={() => {
                            handleEditIntro()
                          }}
                          style={{ background: '#f5f5f5' }}
                        >
                          <HiOutlinePencil size={16} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <p class="text-muted mb-1" style={{ lineHeight: '1.3em' }}>
                    {userInfo?.Description}
                  </p>
                </div>
              </div>
              <div class="card shadow-none mb-2">
                <div class="card-body">
                  <div class="d-flex mb-2">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <div>
                        <h4 class="mb-0 fw-bold d-flex align-items-center">
                          <span>Skills</span>
                        </h4>
                      </div>
                      <div class="d-flex justify-content-end align-items-center">
                        <button
                          class="btn"
                          onClick={() => {
                            handleEditSkills()
                          }}
                          style={{ background: '#f5f5f5' }}
                        >
                          <HiOutlinePencil size={16} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <h6 style={{ marginBottom: '3px' }}>Professional Skills</h6>
                    <div>
                      <ul
                        class="p-0 d-flex flex-wrap"
                        style={{ listStyleType: 'none' }}
                      >
                        {userInfo?.SkillProfile?.ProfessionalSkills ? (
                          userInfo?.SkillProfile?.ProfessionalSkills?.map(
                            (skill, index) => (
                              <li key={index} class="me-1 mb-1">
                                <span class="badge border text-black">
                                  {skill}
                                </span>
                              </li>
                            )
                          )
                        ) : (
                          <li class="me-1 mb-1">
                            <span class="badge border text-black">N/A</span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  {console.log(
                    'length: ',
                    userInfo?.SkillProfile?.GeneralSkills?.length
                  )}
                  {userInfo?.SkillProfile?.GeneralSkills?.length != 0 && (
                    <div class="mb-2">
                      <h6 style={{ marginBottom: '3px' }}>General Skills</h6>
                      <div>
                        <ul
                          class="p-0 d-flex flex-wrap"
                          style={{ listStyleType: 'none' }}
                        >
                          {userInfo?.SkillProfile?.GeneralSkills?.length > 0 ? (
                            userInfo?.SkillProfile?.GeneralSkills?.map(
                              (skill, index) => (
                                <li key={index} class="me-1 mb-1">
                                  <span class="badge border text-black">
                                    {skill}
                                  </span>
                                </li>
                              )
                            )
                          ) : (
                            <li class="me-1 mb-1">
                              <span class="badge border text-black">N/A</span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div class="card shadow-none mb-2">
                <div class="card-body">
                  <div class="d-flex mb-2">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <div>
                        <h4 class="mb-0 fw-bold d-flex align-items-center">
                          <span>Personal Information</span>
                        </h4>
                      </div>
                      <div class="d-flex justify-content-end align-items-center">
                        <button
                          class="btn"
                          onClick={() => {
                            handleEditPersonalInformation()
                          }}
                          style={{ background: '#f5f5f5' }}
                        >
                          <HiOutlinePencil size={16} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6 d-flex mb-2">
                      <div
                        class="d-flex align-items-center justify-content-center p-1 me-1"
                        style={{
                          borderRadius: '7px',
                          background: '#f5f5f5',
                          width: '38px'
                        }}
                      >
                        <CiMail />
                      </div>
                      <div>
                        <h6 class="mb-0 fw-bold" style={{ fontSize: '13px' }}>
                          {userInfo?.Email ? userInfo.Email : 'N/A'}
                        </h6>
                        <h6
                          class="mb-0 text-secondary"
                          style={{ fontSize: '13px' }}
                        >
                          Mail Address
                        </h6>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex mb-2">
                      <div
                        class="d-flex align-items-center justify-content-center p-1 me-1"
                        style={{
                          borderRadius: '7px',
                          background: '#f5f5f5',
                          width: '38px'
                        }}
                      >
                        <FiPhone />
                      </div>
                      <div>
                        <h6 class="mb-0 fw-bold" style={{ fontSize: '13px' }}>
                          +{userInfo?.Phone ? userInfo.Phone : 'N/A'}
                        </h6>
                        <h6
                          class="mb-0 text-secondary"
                          style={{ fontSize: '13px' }}
                        >
                          Phone Number
                        </h6>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex mb-2">
                      <div
                        class="d-flex align-items-center justify-content-center p-1 me-1"
                        style={{
                          borderRadius: '7px',
                          background: '#f5f5f5',
                          width: '38px'
                        }}
                      >
                        <LuCake />
                      </div>
                      <div>
                        <h6 class="mb-0 fw-bold" style={{ fontSize: '13px' }}>
                          {userInfo?.Phone ? userInfo?.Dob : 'N/A'}
                        </h6>
                        <h6
                          class="mb-0 text-secondary"
                          style={{ fontSize: '13px' }}
                        >
                          Date of Birth
                        </h6>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex mb-2">
                      <div
                        class="d-flex align-items-center justify-content-center p-1 me-1"
                        style={{
                          borderRadius: '7px',
                          background: '#f5f5f5',
                          width: '38px'
                        }}
                      >
                        <IoLocationOutline />
                      </div>
                      <div>
                        <h6 class="mb-0 fw-bold" style={{ fontSize: '13px' }}>
                          {userInfo?.Phone ? userInfo?.Location : 'N/A'}
                        </h6>
                        <h6
                          class="mb-0 text-secondary"
                          style={{ fontSize: '13px' }}
                        >
                          Current Location
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card shadow-none mb-2">
                <div class="card-body">
                  <div class="d-flex flex-column">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <div>
                        <h4 class="mb-0 fw-bold d-flex align-items-center">
                          <span>Resume</span>
                        </h4>
                      </div>
                      <div class="d-flex justify-content-end align-items-center flex-column">
                        {!uploadingLoader && (
                          <>
                            {userInfo?.CV ? (
                              <label
                                class="btn text-primary"
                                htmlFor="fileInput"
                                style={{ background: '#f5f5f5' }}
                              >
                                <LuUpload />
                                &nbsp;Update
                              </label>
                            ) : (
                              <label
                                class="btn text-primary"
                                htmlFor="fileInput"
                                style={{ background: '#f5f5f5' }}
                              >
                                <LuUpload />
                                &nbsp;Upload
                              </label>
                            )}
                          </>
                        )}
                        <input
                          id="fileInput"
                          type="file"
                          accept="application/pdf"
                          style={{ display: 'none' }}
                          onChange={handleFileUpload}
                        />
                        {uploadingLoader && (
                          <button
                            class="btn text-primary"
                            style={{ background: '#f5f5f5' }}
                            disabled
                          >
                            <Loader size={14} />
                            &nbsp;Uploading...
                          </button>
                        )}
                      </div>
                    </div>
                    <div>
                      {uploadedImage?.includes('.pdf') && (
                        <div
                          class="d-flex justify-content-center align-items-center border cursor-pointer"
                          onClick={() => {
                            handleOpenDoc(uploadedImage)
                          }}
                          style={{
                            height: '80px',
                            width: '110px',
                            borderRadius: '6px'
                          }}
                        >
                          {/* {uploadedImage} */}
                          <h2 class="mb-0 text-secondary">.pdf</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="card shadow-none mb-2">
                <div class="card-body">
                  <div class="d-flex flex-column">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <div>
                        <h4 class="mb-0 fw-bold d-flex align-items-center">
                          <span>Certificate</span>
                        </h4>
                      </div>
                      <div class="d-flex justify-content-end align-items-center flex-column">
                        {!uploadingCertificateLoader && (
                          <>
                            {userInfo?.Certificates?.length > 0 ? (
                              <label
                                class="btn text-primary"
                                htmlFor="fileInput1"
                                style={{ background: '#f5f5f5' }}
                              >
                                <LuUpload />
                                &nbsp;Update
                              </label>
                            ) : (
                              <label
                                class="btn text-primary"
                                htmlFor="fileInput1"
                                style={{ background: '#f5f5f5' }}
                              >
                                <LuUpload />
                                &nbsp;Upload
                              </label>
                            )}
                          </>
                        )}
                        <input
                          id="fileInput1"
                          type="file"
                          accept="application/pdf"
                          style={{ display: 'none' }}
                          onChange={ChandleFileUpload}
                        />
                        {uploadingCertificateLoader && (
                          <button
                            class="btn text-primary"
                            style={{ background: '#f5f5f5' }}
                            disabled
                          >
                            <Loader size={14} />
                            &nbsp;Uploading...
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="d-flex gap-1">
                      {userInfo?.Certificates?.some(cert =>
                        cert.includes('.pdf')
                      ) &&
                        userInfo?.Certificates?.map(
                          (cert, index) =>
                            cert.includes('.pdf') && (
                              <div
                                key={index}
                                className="d-flex justify-content-center align-items-center border cursor-pointer"
                                onClick={() => handleOpenDoc(cert)}
                                style={{
                                  height: '80px',
                                  width: '110px',
                                  borderRadius: '6px'
                                }}
                              >
                                <h2 className="mb-0 text-secondary">.pdf</h2>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {userInfo?.ExperienceLevel == 'Experienced' && (
                <div class="card shadow-none mb-2">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div>
                          <h4 class="mb-0 fw-bold d-flex align-items-center">
                            <span>Experience</span>
                          </h4>
                        </div>
                        <div class="d-flex justify-content-end align-items-center">
                          <button
                            class="btn text-primary"
                            onClick={() => {
                              handleAddExperience()
                            }}
                            style={{ background: '#f5f5f5' }}
                          >
                            <IoAdd size={18} />
                            &nbsp;Add Experience
                          </button>
                        </div>
                      </div>
                    </div>
                    {userExperience && userExperience.length > 0 ? (
                      userExperience &&
                      userExperience
                        .slice()
                        .sort((a, b) => {
                          if (b.working && !a.working) return -1
                          if (!a.working && b.working) return 1

                          const endDateA = a.working
                            ? new Date()
                            : new Date(a.endYear)
                          const endDateB = b.working
                            ? new Date()
                            : new Date(b.endYear)
                          if (endDateA > endDateB) return -1
                          if (endDateA < endDateB) return 1
                          return 0
                        })
                        .map((experience, idx) => {
                          const startDate = new Date(experience.startYear)
                          if (isNaN(startDate)) {
                            console.error(
                              `Invalid startYear: ${experience.startYear}`
                            )
                            return null
                          }
                          const startMonth = new Intl.DateTimeFormat('en', {
                            month: 'long'
                          }).format(startDate)
                          const startYear = startDate.getFullYear()

                          let endDate = new Date()
                          if (experience.endYear) {
                            endDate = new Date(experience.endYear)
                          }
                          if (isNaN(endDate)) {
                            console.error(
                              `Invalid endYear: ${experience.endYear}`
                            )
                            return null
                          }
                          const yearsDiffrence =
                            endDate.getFullYear() - startDate.getFullYear()
                          const monthsDiffrence =
                            endDate.getMonth() - startDate.getMonth()
                          const totalMonths =
                            yearsDiffrence * 12 + monthsDiffrence

                          const years = Math.floor(totalMonths / 12)
                          const months = totalMonths % 12
                          const duration = `(${years} Year${years !== 1 ? 's' : ''
                            } ${months} Month${months !== 1 ? 's' : ''})`
                          // console.log(experience)
                          return (
                            <div
                              className="mb-2 d-flex justify-content-between"
                              id={idx}
                            >
                              <div>
                                <p
                                  className="text-muted mb-0"
                                  style={{ lineHeight: '1.3em' }}
                                >
                                  {experience?.title}, {experience?.companyName}
                                </p>
                                <p className="text-muted mb-0 text-secondary">
                                  {experience?.startYear}{' '}
                                  <span style={{ textTransform: 'capitalize' }}>
                                    {experience?.startMonth}
                                  </span>{' '}
                                  -
                                  {experience?.working === true && (
                                    <>&nbsp;Present</>
                                  )}
                                  {!experience?.working && (
                                    <>
                                      &nbsp;{experience?.endYear}&nbsp;
                                      <span
                                        style={{ textTransform: 'capitalize' }}
                                      >
                                        {experience?.endMonth}
                                      </span>
                                    </>
                                  )}
                                </p>
                              </div>
                              <div className="d-flex">
                                <button
                                  key={idx}
                                  onClick={() =>
                                    handleAddExperience(experience)
                                  }
                                  className="btn"
                                >
                                  <HiOutlinePencil size={14} />
                                </button>
                                <Button
                                  key={idx}
                                  variant="transparent"
                                  onClick={() =>
                                    handleShowDeleteModal('experience', null, experience.expID)
                                  }
                                  className="btn"
                                >
                                  <RiDeleteBin6Line
                                    size={14}
                                    style={{ color: 'black' }}
                                  />
                                </Button>
                              </div>
                            </div>
                          )
                        })
                    ) : (
                      <div className="mb-2 d-flex justify-content-between">
                        <div>
                          <p
                            className="text-muted mb-0"
                            style={{ lineHeight: '1.3em' }}
                          >
                            N/A
                          </p>
                          <p className="text-muted mb-0 text-secondary">
                            N/A - N/A
                          </p>
                        </div>
                        <div>
                          <button className="btn">
                            <HiOutlinePencil size={14} />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div class="card shadow-none mb-2">
                <div class="card-body">
                  <div class="d-flex mb-2">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <div>
                        <h4 class="mb-0 fw-bold d-flex align-items-center">
                          <span>Education</span>
                        </h4>
                      </div>
                      <div class="d-flex justify-content-end align-items-center">
                        <div class="d-flex justify-content-end align-items-center">
                          <button
                            class="btn text-primary"
                            onClick={() => {
                              handleAddEducation()
                            }}
                            style={{ background: '#f5f5f5' }}
                          >
                            <IoAdd size={18} />
                            &nbsp;Add Education
                          </button>
                        </div>
                        {/* <button class="btn" style={{ background: '#f5f5f5' }}><HiOutlinePencil size={16} /></button> */}
                      </div>
                    </div>
                  </div>
                  {userEduction && userEduction.length > 0 ? (
                    userEduction &&
                    userEduction
                      .slice()
                      .sort((a, b) => b.endYear - a.endYear)
                      .map((education, idx) => {
                        let startYear = parseInt(education?.startYear, 10)
                        let endYear = parseInt(education?.endYear, 10)

                        if (isNaN(endYear)) {
                          endYear = new Date().getFullYear()
                        }
                        const yearsDifference = endYear - startYear
                        const duration = `(${yearsDifference} Years)`

                        return (
                          <div
                            className="mb-2 d-flex justify-content-between"
                            id={idx}
                          >
                            <div>
                              <p
                                className="text-muted mb-0"
                                style={{ lineHeight: '1.3em' }}
                              >
                                {education?.degree}, {education?.school}
                              </p>
                              <p className="text-muted mb-0 text-secondary">
                                {education?.startYear} -{' '}
                                {education?.pursuing === true
                                  ? 'pursuing'
                                  : education?.endYear}
                              </p>
                            </div>
                            <div className="d-flex">
                              <button
                                className="btn"
                                key={idx}
                                onClick={() => handleAddEducation(education)}
                              >
                                <HiOutlinePencil size={14} />
                              </button>
                              <Button
                                key={idx}
                                variant="transparent"
                                onClick={() =>
                                  handleShowDeleteModal(
                                    'education',
                                    education?.eduID
                                  )
                                }
                                className="btn"
                              >
                                <RiDeleteBin6Line
                                  size={14}
                                  style={{ color: 'black' }}
                                />
                              </Button>
                            </div>
                          </div>
                        )
                      })
                  ) : (
                    <div className="mb-2 d-flex justify-content-between">
                      <div>
                        <p
                          className="text-muted mb-0"
                          style={{ lineHeight: '1.3em' }}
                        >
                          N/A
                        </p>
                        <p className="text-muted mb-0 text-secondary">
                          N/A - N/A
                        </p>
                      </div>
                      <div>
                        <button className="btn">
                          <HiOutlinePencil size={14} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="card shadow-none mb-2">
                <div class="card-body">
                  {/* <div class="d-flex mb-1">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <div>
                        <h4 class="mb-0 fw-bold d-flex align-items-center">
                          <span>Open for Job</span>
                        </h4>
                      </div>
                      <div class="d-flex justify-content-end align-items-center">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input form-switch-toggle"
                            type="checkbox"
                            id="openForJob"
                            checked={openForJob}
                            onChange={handleOpenForJob}
                          />
                          <label
                            class="form-check-label"
                            for="openForJob"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div class="mb-1">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <div className="text-nowrap">
                        <h4 style={{ marginBottom: '3px' }}>Open to Join</h4>
                      </div>
                      <div class="d-flex justify-content-end align-items-center">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input form-switch-toggle"
                            type="checkbox"
                            id="jobStatusJoin"
                            checked={
                              tempJoiningObj?.status == 'join' ? true : false
                            }
                            // onChange={handleOpenToJoin}
                            onChange={e => {
                              updateJoiningObj('join', e.target.checked)
                            }}
                          />
                          <label
                            class="form-check-label"
                            for="jobStatusJoin"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <div className="text-nowrap">
                        <h4 style={{ marginBottom: '3px' }}>Open to Switch</h4>
                      </div>
                      <div class="d-flex justify-content-end align-items-center">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input form-switch-toggle"
                            type="checkbox"
                            id="jobStatusSwitch"
                            checked={
                              tempJoiningObj?.status == 'switch' ? true : false
                            }
                            // onChange={handleOpenToJoin}
                            onChange={e => {
                              updateJoiningObj('switch', e.target.checked)
                            }}
                          />
                          <label
                            class="form-check-label"
                            for="jobStatusSwitch"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {tempJoiningObj?.status == 'switch' && (
                    <div class="mb-1">
                      <h6 style={{ marginBottom: '3px' }}>Current CTC</h6>
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm"
                          type="number"
                          min="0"
                          step="0.01"
                          placeholder="in LPA"
                          value={tempJoiningObj?.currentCTC}
                          onChange={e => {
                            updateCTC('currentCTC', e.target.value)
                          }}
                          aria-label="lpa"
                          aria-describedby="basic-addon1"
                        />
                        <InputGroup.Text
                          id="basic-addon1"
                          class="border text-secondary p-1 d-flex align-items-center"
                          style={{ fontSize: '14px' }}
                        >
                          LPA
                        </InputGroup.Text>
                        {!disableSubmitCTC && (
                          <button
                            id="basic-addon1"
                            class="btn btn-primary border p-0 ms-1 d-flex align-items-center justify-content-center"
                            onClick={submitCTC}
                            style={{ fontSize: '14px', width: '18%' }}
                          >
                            <ion-icon
                              name="checkmark-outline"
                              class="fs-2 me-0"
                            ></ion-icon>
                          </button>
                        )}
                        {disableSubmitCTC && (
                          <button
                            id="basic-addon1"
                            class="btn btn-primary border p-0 ms-1 d-flex align-items-center justify-content-center"
                            disabled
                            style={{ fontSize: '14px', width: '18%' }}
                          >
                            <div
                              class="spinner-border text-white"
                              role="status"
                              style={{ height: '20px', width: '20px' }}
                            ></div>
                          </button>
                        )}
                      </InputGroup>
                    </div>
                  )}
                  {(tempJoiningObj?.status == 'switch' ||
                    tempJoiningObj?.status == 'join') && (
                      <div class="mb-1">
                        <h6 style={{ marginBottom: '3px' }}>Expected CTC</h6>
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm"
                            type="number"
                            min="0"
                            step="0.01"
                            placeholder="in LPA"
                            value={tempJoiningObj?.expectedCTC}
                            onChange={e => {
                              updateCTC('expectedCTC', e.target.value)
                            }}
                            aria-label="lpa"
                            aria-describedby="basic-addon1"
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            class="border text-secondary p-1 d-flex align-items-center"
                            style={{ fontSize: '14px' }}
                          >
                            LPA
                          </InputGroup.Text>
                          {!disableSubmitCTC && (
                            <button
                              id="basic-addon1"
                              class="btn btn-primary border p-0 ms-1 d-flex align-items-center justify-content-center"
                              onClick={submitCTC}
                              style={{ fontSize: '14px', width: '18%' }}
                            >
                              <ion-icon
                                name="checkmark-outline"
                                class="fs-2 me-0"
                              ></ion-icon>
                            </button>
                          )}
                          {disableSubmitCTC && (
                            <button
                              id="basic-addon1"
                              class="btn btn-primary border p-0 ms-1 d-flex align-items-center justify-content-center"
                              disabled
                              style={{ fontSize: '14px', width: '18%' }}
                            >
                              <div
                                class="spinner-border text-white"
                                role="status"
                                style={{ height: '20px', width: '20px' }}
                              ></div>
                            </button>
                          )}
                        </InputGroup>
                      </div>
                    )}
                  <div class="d-flex flex-wrap mb-0">
                    <div class="form-check m-0 me-2 mb-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={tempJoiningObj?.fullTime}
                        onChange={e => {
                          updateJoiningObj('fullTime', e.target.checked)
                        }}
                        id="fullTime"
                      />
                      <label
                        class="form-checkbox-custom form-check-label ps-3"
                        for="fullTime"
                      >
                        <h6 class="mb-0 text-nowrap">Full Time</h6>
                      </label>
                    </div>
                    <div class="form-check m-0 me-2 mb-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={tempJoiningObj?.partTime}
                        onChange={e => {
                          updateJoiningObj('partTime', e.target.checked)
                        }}
                        id="partTime"
                      />
                      <label
                        class="form-checkbox-custom form-check-label ps-3"
                        for="partTime"
                      >
                        <h6 class="mb-0 text-nowrap">Part Time</h6>
                      </label>
                    </div>
                    <div class="form-check m-0 me-2 mb-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={tempJoiningObj?.internship}
                        onChange={e => {
                          updateJoiningObj('internship', e.target.checked)
                        }}
                        id="internship"
                      />
                      <label
                        class="form-checkbox-custom form-check-label ps-3"
                        for="internship"
                      >
                        <h6 class="mb-0 text-nowrap">Internship</h6>
                      </label>
                    </div>
                    <div class="form-check m-0 me-2 mb-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={tempJoiningObj?.remote}
                        onChange={e => {
                          updateJoiningObj('remote', e.target.checked)
                        }}
                        id="remote"
                      />
                      <label
                        class="form-checkbox-custom form-check-label ps-3"
                        for="remote"
                      >
                        <h6 class="mb-0 text-nowrap">Remote</h6>
                      </label>
                    </div>
                    <div class="form-check m-0 me-2 mb-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={tempJoiningObj?.onField}
                        onChange={e => {
                          updateJoiningObj('onField', e.target.checked)
                        }}
                        id="onField"
                      />
                      <label
                        class="form-checkbox-custom form-check-label ps-3"
                        for="onField"
                      >
                        <h6 class="mb-0 text-nowrap">On Field</h6>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="card shadow-none mb-2"
                style={{ background: '#fafafa' }}
              >
                <div class="card-body">
                  <div class="d-flex mb-0">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <div>
                        <h4 class="mb-0 fw-bold d-flex align-items-center">
                          <span>Open for Freelancing</span>
                        </h4>
                      </div>
                      <div class="d-flex justify-content-center align-items-center flex-column">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input form-switch-toggle"
                            type="checkbox"
                            id="freelancing"
                            disabled
                          />
                          <label
                            class="form-check-label"
                            for="freelancing"
                          ></label>
                        </div>
                        <h6 class="mb-0 text-danger">Coming Soon</h6>
                      </div>
                    </div>
                  </div>
                  {/* <div class="mb-2">
                                        <h6 style={{ marginBottom: '3px' }}>Expected Rate</h6>
                                        <Form.Select aria-label="Default select example" className="border" style={{ fontSize: '14px' }} disabled>
                                            <option>Select Rate</option>
                                            <option value="500">Rs 500</option>
                                            <option value="1000">Rs 1000</option>
                                            <option value="1500">Rs 1500</option>
                                            <option value="2000">Rs 2000</option>
                                        </Form.Select>
                                    </div>
                                    <div class="mb-2">
                                        <h6 style={{ marginBottom: '3px' }}>Hours/Week Available</h6>
                                        <Form.Select aria-label="Default select example" className="border" style={{ fontSize: '14px' }} disabled>
                                            <option>Select Cost</option>
                                            <option value="20">20h/week</option>
                                            <option value="40">40h/week</option>
                                            <option value="60">60h/week</option>
                                            <option value="80">80h/week</option>
                                        </Form.Select>
                                    </div> */}
                </div>
              </div>
              <div class="card shadow-none mb-2">
                <div class="card-body">
                  <div class="d-flex mb-2">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <div>
                        <h4 class="mb-0 fw-bold d-flex align-items-center">
                          <span>Profile Strength</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <div
                      class="h-auto d-flex align-items-center justify-content-center"
                      style={{ position: 'relative' }}
                    >
                      <div
                        class="d-flex align-items-center justify-content-center flex-column text-center"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)'
                        }}
                      >
                        <h2 class="mb-0">{profileProgressCount}%</h2>
                      </div>
                      <Doughnut
                        data={profileStrengthData}
                        options={profileStrengthOptions}
                        width={150}
                        height={150}
                      />
                    </div>
                  </div>
                  <div>
                    {!userInfo?.Email ||
                      !userInfo?.Phone ||
                      !userInfo?.Dob ||
                      (!userInfo?.Location && (
                        <button
                          class="btn btn-block border d-flex justify-content-between align-items-center pe-0 mb-1"
                          onClick={() => {
                            handleEditPersonalInformation()
                          }}
                        >
                          <span
                            class="text-nowrap"
                            style={{ fontSize: '12px' }}
                          >
                            Add Primary Info
                          </span>
                          <span class="d-flex align-items-center text-secondary">
                            <ion-icon
                              name="chevron-forward-outline"
                              class="ms-1"
                              style={{ fontSize: '14px' }}
                            ></ion-icon>
                          </span>
                          {/* <span class="d-flex align-items-center text-secondary" style={{ fontSize: '12px' }}>2 of 4 added <ion-icon name="chevron-forward-outline" class="ms-1" style={{ fontSize: '14px' }}></ion-icon></span> */}
                        </button>
                      ))}
                    {!userInfo?.CV && (
                      <label
                        class="btn btn-block border d-flex justify-content-between align-items-center pe-0 mb-1"
                        htmlFor="fileInput"
                      >
                        <span class="text-nowrap" style={{ fontSize: '12px' }}>
                          Add Resume
                        </span>
                        <span class="d-flex align-items-center text-secondary">
                          <ion-icon
                            name="chevron-forward-outline"
                            class="ms-1"
                            style={{ fontSize: '14px' }}
                          ></ion-icon>
                        </span>
                      </label>
                    )}
                    {userInfo?.ExperienceLevel == 'Experienced' &&
                      (!userInfo?.Experience ||
                        userInfo?.Experience?.length == 0) && (
                        <button
                          class="btn btn-block border d-flex justify-content-between align-items-center pe-0 mb-1"
                          onClick={() => {
                            handleAddExperience()
                          }}
                        >
                          <span
                            class="text-nowrap"
                            style={{ fontSize: '12px' }}
                          >
                            Add Experience
                          </span>
                          <span class="d-flex align-items-center text-secondary">
                            <ion-icon
                              name="chevron-forward-outline"
                              class="ms-1"
                              style={{ fontSize: '14px' }}
                            ></ion-icon>
                          </span>
                        </button>
                      )}
                    {(!userInfo?.Education ||
                      userInfo?.Education?.length == 0) && (
                        <button
                          class="btn btn-block border d-flex justify-content-between align-items-center pe-0 mb-1"
                          onClick={() => {
                            handleAddEducation()
                          }}
                        >
                          <span class="text-nowrap" style={{ fontSize: '12px' }}>
                            Add Education
                          </span>
                          <span class="d-flex align-items-center text-secondary">
                            <ion-icon
                              name="chevron-forward-outline"
                              class="ms-1"
                              style={{ fontSize: '14px' }}
                            ></ion-icon>
                          </span>
                        </button>
                      )}
                    {!userInfo?.Description && (
                      <button
                        class="btn btn-block border d-flex justify-content-between align-items-center pe-0 mb-1"
                        onClick={() => {
                          handleEditIntro()
                        }}
                      >
                        <span class="text-nowrap" style={{ fontSize: '12px' }}>
                          Add Headline
                        </span>
                        <span class="d-flex align-items-center text-secondary">
                          <ion-icon
                            name="chevron-forward-outline"
                            class="ms-1"
                            style={{ fontSize: '14px' }}
                          ></ion-icon>
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* /////////////////////////////////////////////////////////////////////////////////////////// */}
    </>
  )
}

const mapStateToProps = ({ auth, profileUpdate }) => {
  const { userData, allCities, role, fullName, ID, authToken } = auth
  const { profileUpdateModalVisible } = profileUpdate
  return {
    userData,
    allCities,
    role,
    fullName,
    ID,
    authToken,
    profileUpdateModalVisible
  }
}

const mapDispatchToProps = {
  showProfileUpdateModal,
  showVerificationModal,
  setProfileGraphCount,
  showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfile)

// Delete modal component
function DeleteEducation(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.titleMessage}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.confirmDeleteMessage}</h4>
        {/* <h4>Are you sure you want to delete this Placement Drive?</h4> */}
        {/* <p>
            This action cannot be undone. Once you confirm, the job will be permanently deleted from the system.
        </p> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.handleDelete}>
          Confirm Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
