import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  GetCandidateByID,
  UpdateUserProfile,
  deleteCandidateEducationRecord,
  deleteCandidateExperienceRecord,
  fetchAllJobRoles,
  fetchAllJobRolesSkills,
  getRemovedSkills,
  handelOpenToWork,
  postUserImage,
  shortlistApplicant,
  UserJoinStatus
} from '../../../../features/constants/apis'
import { hideProfileUpdateModal } from '../../../../features/popup/ProfileUpdateModalSlice'

const EditPersonalInfo = ({ userInfo, authToken, hideProfileUpdateModal }) => {
  console.log('inside edit form: ', userInfo)
  const [warning, setWarning] = useState('')
  const [popupLoder, setPopUpLoder] = useState(false)

  // Split FullName into fName and lName
  const [userData, setUserData] = useState({
    email: userInfo?.Email,
    AlternateContact: userInfo?.AlternateContact,
    location: userInfo?.Location || '',
    state: userInfo?.State || '',
    description: userInfo?.Description || '',
    dob: userInfo?.Dob || '',
    website: userInfo?.Website || ''
  })

  // Handle input changes
  const handleChange = e => {
    const { id, value } = e.target
    setUserData(prevData => ({
      ...prevData,
      [id]: value // Update field based on input ID
    }))
  }

  // Effect to handle phone validation
  // useEffect(() => {
  //     if (userData?.phone) {
  //         // Remove non-numeric characters to ensure it's just a number
  //         const numericPhone = userData.phone.replace(/\D/g, '');

  //         if (numericPhone.length !== 10) {
  //             setWarning('Phone number must be exactly 10 digits.');
  //         } else {
  //             setWarning('');
  //         }
  //     }
  // }, [userData?.phone]); // Run the effect whenever the phone number changes

  const EditUserProfile = async e => {
    setPopUpLoder(true)
    e.preventDefault()
    console.log('editprofile fuc triggerd!!!')
    // const combinedName = `${userData.fName} ${userData.lName}`.trim();
    const updateProfile = await UpdateUserProfile(
      userInfo?.ID,
      userData,
      authToken
    )
    console.log('user Data: ', updateProfile)
    hideProfileUpdateModal()
    // const data = await GetCandidateByID(ID, authToken)
    // if (role === 'Candidate') {
    //     setUserInfo(data.data);
    //     setUserEducation(data.data?.Education);
    //     setUserExperience(data.data?.Experience);
    // }
    setPopUpLoder(false)
  }

  console.log('userData: ', userData)

  return (
    <div>
      <div class="row">
        <form onSubmit={EditUserProfile}>
          <div class="col-12 col-md-12">
            <div class="form-group boxed">
              <div class="input-wrapper">
                <label
                  class="form-label fw-bold"
                  for="emailAddress"
                  style={{ fontSize: '14px', marginBottom: '3px' }}
                >
                  Phone
                </label>
                <div class="d-flex">
                  <input
                    type="tel"
                    pattern="^[0-9]{10}$"
                    class="form-control form-control-sm border"
                    placeholder="Ex.0984756374"
                    id="AlternateContact"
                    value={userData?.AlternateContact}
                    onChange={handleChange}
                    autocomplete="off"
                  />
                  {/* <button class="btn btn-link text-primary">Edit</button> */}
                </div>
              </div>
            </div>
            <div class="form-group boxed">
              <div class="input-wrapper">
                <label
                  class="form-label fw-bold"
                  for="emailAddress"
                  style={{ fontSize: '14px', marginBottom: '3px' }}
                >
                  Email Address
                </label>
                <div class="d-flex">
                  <input
                    type="email"
                    class="form-control form-control-sm border"
                    placeholder="aditi.sharma@gmail.com"
                    id="email"
                    value={userData?.email}
                    onChange={handleChange}
                    autocomplete="off"
                  />
                  {/* <button class="btn btn-link text-primary">Edit</button> */}
                </div>
              </div>
            </div>
            {/* <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="phone" style={{ fontSize: '14px', marginBottom: '3px' }}>Phone</label>
                                <div class="d-flex">
                                    <input type="tel" class="form-control form-control-sm border" onChange={handleChange} value={userData?.phone} id="phone" placeholder="+91 9329 234 2343" autocomplete="off" />
                                    <button class="btn btn-link text-primary">Edit</button>
                                </div>
                                {warning && <small style={{ color: 'red' }}>{warning}</small>}

                            </div>
                        </div> */}
            <div class="col-12 col-md-12 p-0">
              <div class="form-group boxed">
                <div className='d-flex'>
                  <div class="input-wrapper">
                    <label
                      class="form-label fw-bold"
                      for="currentLocation"
                      style={{ fontSize: '14px', marginBottom: '3px' }}
                    >
                      State
                    </label>
                    <div class="d-flex">
                      <select
                        value={setUserData?.state}
                        id="state"
                        onChange={handleChange}
                        class="form-control form-select-sm form-select border me-2"
                      >
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                      </select>
                    </div>
                  </div>
                  <div class="input-wrapper">
                    <label
                      class="form-label fw-bold"
                      for="currentLocation"
                      style={{ fontSize: '14px', marginBottom: '3px' }}
                    >
                      City
                    </label>
                    <div class="d-flex">
                      <select
                        value={setUserData?.location}
                        id="location"
                        onChange={handleChange}
                        class="form-control form-select-sm form-select border me-2"
                      >
                        <option value="Mumbai">Mumbai</option>
                        <option value="Pune">Pune</option>
                        <option value="Nagpur">Nagpur</option>
                        <option value="Nashik">Nashik</option>
                        <option value="Aurangabad">Aurangabad</option>
                        <option value="Thane">Thane</option>
                        <option value="Solapur">Solapur</option>
                        <option value="Amravati">Amravati</option>
                        <option value="Kolhapur">Kolhapur</option>
                        <option value="Sangli">Sangli</option>
                        <option value="Jalgaon">Jalgaon</option>
                        <option value="Akola">Akola</option>
                        <option value="Latur">Latur</option>
                        <option value="Dhule">Dhule</option>
                        <option value="Ahmednagar">Ahmednagar</option>
                        <option value="Chandrapur">Chandrapur</option>
                        <option value="Parbhani">Parbhani</option>
                        <option value="Nanded">Nanded</option>
                        <option value="Ratnagiri">Ratnagiri</option>
                        <option value="Panvel">Panvel</option>
                        <option value="Kalyan">Kalyan</option>
                        <option value="Ulhasnagar">Ulhasnagar</option>
                        <option value="Bhiwandi">Bhiwandi</option>
                        <option value="Vasai-Virar">Vasai-Virar</option>
                        <option value="Miraj">Miraj</option>
                        <option value="Malegaon">Malegaon</option>
                        <option value="Yavatmal">Yavatmal</option>
                        <option value="Beed">Beed</option>
                        <option value="Osmanabad">Osmanabad</option>
                        <option value="Wardha">Wardha</option>
                        <option value="Gondia">Gondia</option>
                        <option value="Satara">Satara</option>
                        <option value="Baramati">Baramati</option>
                        <option value="Sindhudurg">Sindhudurg</option>
                        <option value="Buldhana">Buldhana</option>
                        <option value="Palghar">Palghar</option>
                        <option value="Hingoli">Hingoli</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group boxed">
              <div class="input-wrapper">
                <label
                  class="form-label fw-bold"
                  for="headline"
                  style={{ fontSize: '14px', marginBottom: '3px' }}
                >
                  Headline
                </label>
                <textarea
                  id="description"
                  value={userData?.description}
                  onChange={handleChange}
                  rows="5"
                  class="form-control form-control-sm border"
                  placeholder="Ex. Microsoft"
                ></textarea>
                <i class="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
            </div>
            <div class="form-group boxed">
              <div class="input-wrapper">
                <label
                  class="form-label fw-bold"
                  for="dob"
                  style={{ fontSize: '14px', marginBottom: '3px' }}
                >
                  Date of Birth
                </label>
                <input
                  type="date"
                  class="form-control form-control-sm border pe-1"
                  onChange={handleChange}
                  value={userData?.dob}
                  id="dob"
                  autocomplete="off"
                />
                <i class="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
            </div>
            <div class="form-group boxed">
              <div class="input-wrapper">
                <label
                  class="form-label fw-bold"
                  for="link"
                  style={{ fontSize: '14px', marginBottom: '3px' }}
                >
                  Profile / Portfolio
                </label>
                <input
                  type="text"
                  value={userData?.website}
                  onChange={handleChange}
                  class="form-control form-control-sm border pe-1"
                  id="website"
                  autocomplete="off"
                />
                <i class="clear-input">
                  <ion-icon name="close-circle"></ion-icon>
                </i>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 text-end mt-1">
            <button disabled={popupLoder} type="submit" class="btn btn-primary">
              {popupLoder ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = ({ profileUpdate }) => {
  const { profileUpdateModalVisible, profileUpdateType, userInfo } =
    profileUpdate
  return {
    profileUpdateModalVisible,
    profileUpdateType,
    userInfo
  }
}
const mapDispatchToProps = {
  hideProfileUpdateModal
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPersonalInfo)
