import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AnimatedLoader.css';
import { connect } from 'react-redux';

const AnimatedLoader = ({ fullName }) => {
  const [currentText, setCurrentText] = useState('Welcome to ScopeHai.com');
  const [currentIndex, setCurrentIndex] = useState(1);
  const [percentage, setPercentage] = useState(0);

  const textArray = [
    'Welcome to ScopeHai.com',
    'Redirecting...',
  ];

  const totalDuration = 800; // Total animation duration
  const individualDuration = totalDuration / (textArray.length - 1);

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < textArray.length) {
        setCurrentText(textArray[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    }, individualDuration);

    const redirectTimeout = setTimeout(() => {
      navigate('/dashboard');
    }, totalDuration + individualDuration - 800);

    return () => {
      clearInterval(interval);
      clearTimeout(redirectTimeout);
    };
  }, [currentIndex, navigate, textArray, individualDuration]);

  // Update percentage smoothly
  useEffect(() => {
    let startTime = performance.now();

    function updatePercentage(timestamp) {
      let elapsed = timestamp - startTime;
      let progress = Math.min(elapsed / totalDuration, 1);
      let newPercentage = Math.round(progress * 100);
      setPercentage(newPercentage);

      if (progress < 1) {
        requestAnimationFrame(updatePercentage);
      }
    }

    requestAnimationFrame(updatePercentage);
  }, []);

  return (
    <div className="animated-loader d-flex justify-content-center flex-column align-items-center">
      <div className="welcome-loader mb-5">
        <span className="percentage-text">{percentage}%</span>
      </div>
      <p key={currentIndex} className="fade-in-out-text">{currentText}</p>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { fullName } = auth;

  return {
    fullName,
  };
}

export default connect(mapStateToProps)(AnimatedLoader);
