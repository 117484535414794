import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    searchHistoryOffcanvasVisible: false,
    searchData: null
}

export const offcanvasSlice = createSlice({
  name: 'offcanvas',
  initialState,
  reducers: {
    showSearchHistoryOffcanvas: (state, action) => {
      const {
        searchHistoryOffcanvasVisible,
        searchData
      } = action.payload
      state.searchHistoryOffcanvasVisible=searchHistoryOffcanvasVisible
      state.searchData=searchData
    },
    hideSearchHistoryOffcanvas: (state, action) => {
        state.searchHistoryOffcanvasVisible=false
        state.searchData=null
    }
  }
})

export const { showSearchHistoryOffcanvas, hideSearchHistoryOffcanvas } = offcanvasSlice.actions;


export default offcanvasSlice.reducer
