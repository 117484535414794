import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    progressPercentage: 0,
    progressRemainingPercent: 0,
    candidateList: null
}
export const DataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setProfileGraphCount: (state, action) => {
      const {
        progressPercentage,
        progressRemainingPercent
      } = action.payload
      console.log('action. payload: ',action.payload)
      state.progressPercentage = progressPercentage
      state.progressRemainingPercent = progressRemainingPercent
    },
    setCandidateList: (state, action) => {
      const {
        candidateList,
      } = action.payload
      console.log('action. payload: ',action.payload)
      state.candidateList = candidateList
    }
  }
})

export const { setProfileGraphCount,setCandidateList } = DataSlice.actions;


export default DataSlice.reducer
