import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SampleDriveImg from '../images/candidate-login/sample.jpg';

const PlacementDrive = () => {
    let navigate = useNavigate()
    const showDetail = () => {
        navigate('/drive-detail')
    }
    return (
        <div id="appCapsule">
            <div class="container">
                <div class="d-flex align-items-center mt-2 mb-2">
                    <h1 className="title mb-0">Placement Drive</h1>
                </div>
                <div class="row">
                    {[1,2,3,4,5].map((v, k) => {
                        return (
                            <div class="col-12 col-md-4 mb-2">
                                <div class="card shadow" style={{borderRadius: '12px'}}>
                                    <div class="card-body p-0">
                                        <div style={{backgroundImage: `url(${SampleDriveImg})`, height: '180px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', backgroundPosition: 'center', backgroundSize: 'cover'}}>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <div class="d-flex justify-content-between gap-2">
                                                <div class="d-flex align-items-center justify-content-center text-nowrap text-secondary" style={{marginTop: '-20px', backgroundColor: '#f1fcf5', fontSize: '12px', borderRadius: '5px', flexGrow: 1, boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.04)', height: 'fit-content', padding: '3px'}}>
                                                    <span class="d-flex" style={{color: '#25BF5B'}}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-receipt-indian-rupee"><path d="M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1Z"/><path d="M8 7h8"/><path d="M12 17.5 8 15h1a4 4 0 0 0 0-8"/><path d="M8 11h8"/></svg>
                                                        &nbsp;10.5LPA
                                                    </span>&nbsp;Avg Package
                                                </div>
                                                <div class="d-flex align-items-center justify-content-center text-nowrap text-secondary" style={{marginTop: '-20px', backgroundColor: '#f1fcf5', fontSize: '12px', borderRadius: '5px', flexGrow: 1, boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.04)', height: 'fit-content', padding: '3px'}}>
                                                    <span class="d-flex" style={{color: '#4B7BFF'}}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-building-2"><path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z"/><path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2"/><path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2"/><path d="M10 6h4"/><path d="M10 10h4"/><path d="M10 14h4"/><path d="M10 18h4"/></svg>
                                                        &nbsp;10k
                                                    </span>
                                                    &nbsp;placements
                                                </div>
                                            </div>
                                        </div>
                                        <div class="m-2">
                                            <ul class="p-0 d-flex gap-2 mt-1 mb-2" style={{listStyleType: 'none', overflow: 'hidden'}}>
                                                <li class="text-secondary text-nowrap" style={{display: 'flex', alignItems: 'center', fontSize: '12px'}}>Eligibility:</li>
                                                <li>
                                                    <span class="d-flex align-items-center text-nowrap" style={{backgroundColor: '#f5f5f5', fontSize: '12px', borderRadius: '50px', height: '25px', padding: '5px'}}>Figma</span>
                                                </li>
                                                <li>
                                                    <span class="d-flex align-items-center text-nowrap" style={{backgroundColor: '#f5f5f5', fontSize: '12px', borderRadius: '50px', height: '25px', padding: '5px'}}>Miro</span>
                                                </li>
                                                <li>
                                                    <span class="d-flex align-items-center text-nowrap" style={{backgroundColor: '#f5f5f5', fontSize: '12px', borderRadius: '50px', height: '25px', padding: '5px'}}>Illustrator</span>
                                                </li>
                                                <li>
                                                    <span class="d-flex align-items-center text-nowrap" style={{backgroundColor: '#f5f5f5', fontSize: '12px', borderRadius: '50px', height: '25px', padding: '5px', textOverflow: 'ellipsis'}}>Photoshop</span>
                                                </li>
                                            </ul>
                                            <div class="mt-1">
                                                <h3 class="fw-bold mb-0" style={{fontSize: '22px'}}>Graphic Design Masterclass</h3>
                                                <p class="text-secondary" style={{fontSize: '13px'}}>Amet minim mollit non deserunt ullamco est si .</p>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div class="d-flex align-items-center fw-bold" style={{color: '#358D77', fontSize: '15px'}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-users"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"/><circle cx="9" cy="7" r="4"/><path d="M22 21v-2a4 4 0 0 0-3-3.87"/><path d="M16 3.13a4 4 0 0 1 0 7.75"/></svg>&nbsp;
                                                    12,220&nbsp;<span class="text-secondary fw-normal">taken test</span>
                                                </div>
                                                <div>
                                                    <div class="btn btn-sm bg-primary text-white text-nowrap cursor-pointer" onClick={() => {showDetail()}} style={{borderRadius: '6px'}}>Apply Now</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = () => {

  return {
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PlacementDrive);